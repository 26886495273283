// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  Count,
  CountFromJSON,
  CountToJSON,
  CreateActionResult,
  CreateActionResultFromJSON,
  CreateActionResultToJSON,
  CustomField,
  CustomFieldFromJSON,
  CustomFieldToJSON,
  CustomFieldPayload,
  CustomFieldPayloadFromJSON,
  CustomFieldPayloadToJSON,
  CustomFieldsSortOrderPayload,
  CustomFieldsSortOrderPayloadFromJSON,
  CustomFieldsSortOrderPayloadToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON
} from "../models";

export interface CreateCustomFieldRequest {
  customFieldPayload?: CustomFieldPayload;
}

export interface DeleteCustomFieldRequest {
  id: number;
}

export interface GetCustomFieldRequest {
  id: number;
}

export interface GetCustomFieldCountRequest {
  company: number;
}

export interface GetCustomFieldsRequest {
  company: number;
  limit?: number;
  start?: number;
  sort?: string;
  filter?: string;
}

export interface SetCustomFieldsSortOrderRequest {
  customFieldsSortOrderPayload?: CustomFieldsSortOrderPayload;
}

export interface UpdateCustomFieldRequest {
  id: number;
  customFieldPayload?: CustomFieldPayload;
}

/**
 * no description
 */
export class CustomFieldsApi extends runtime.BaseAPI {
  /**
   * Custom Field to add
   * Create a new custom field
   */
  async createCustomFieldRaw(
    requestParameters: CreateCustomFieldRequest
  ): Promise<runtime.ApiResponse<CustomField>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/customfields`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CustomFieldPayloadToJSON(requestParameters.customFieldPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CustomFieldFromJSON(jsonValue)
    );
  }

  /**
   * Custom Field to add
   * Create a new custom field
   */
  async createCustomField(
    requestParameters: CreateCustomFieldRequest
  ): Promise<CustomField> {
    const response = await this.createCustomFieldRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes specific custom field
   * Deletes given custom field
   */
  async deleteCustomFieldRaw(
    requestParameters: DeleteCustomFieldRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteCustomField."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/customfields/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deletes specific custom field
   * Deletes given custom field
   */
  async deleteCustomField(
    requestParameters: DeleteCustomFieldRequest
  ): Promise<any> {
    const response = await this.deleteCustomFieldRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get custom fields
   * Get custom fields
   */
  async getCustomFieldRaw(
    requestParameters: GetCustomFieldRequest
  ): Promise<runtime.ApiResponse<CustomField>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getCustomField."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/customfields/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CustomFieldFromJSON(jsonValue)
    );
  }

  /**
   * Get custom fields
   * Get custom fields
   */
  async getCustomField(
    requestParameters: GetCustomFieldRequest
  ): Promise<CustomField> {
    const response = await this.getCustomFieldRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get custom fields count
   * Get custom fields count
   */
  async getCustomFieldCountRaw(
    requestParameters: GetCustomFieldCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getCustomFieldCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/customfields/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get custom fields count
   * Get custom fields count
   */
  async getCustomFieldCount(
    requestParameters: GetCustomFieldCountRequest
  ): Promise<Count> {
    const response = await this.getCustomFieldCountRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get company specific list of custom fields
   * Get list of custom fields
   */
  async getCustomFieldsRaw(
    requestParameters: GetCustomFieldsRequest
  ): Promise<runtime.ApiResponse<Array<CustomField>>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getCustomFields."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/customfields`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(CustomFieldFromJSON)
    );
  }

  /**
   * Get company specific list of custom fields
   * Get list of custom fields
   */
  async getCustomFields(
    requestParameters: GetCustomFieldsRequest
  ): Promise<Array<CustomField>> {
    const response = await this.getCustomFieldsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Set sort order for custom fields
   * Set sort order for custom fields
   */
  async setCustomFieldsSortOrderRaw(
    requestParameters: SetCustomFieldsSortOrderRequest
  ): Promise<runtime.ApiResponse<CreateActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/customfields/setSortOrder`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CustomFieldsSortOrderPayloadToJSON(
        requestParameters.customFieldsSortOrderPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CreateActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Set sort order for custom fields
   * Set sort order for custom fields
   */
  async setCustomFieldsSortOrder(
    requestParameters: SetCustomFieldsSortOrderRequest
  ): Promise<CreateActionResult> {
    const response = await this.setCustomFieldsSortOrderRaw(requestParameters);
    return await response.value();
  }

  /**
   * Custom Field to update
   * Updates a custom field
   */
  async updateCustomFieldRaw(
    requestParameters: UpdateCustomFieldRequest
  ): Promise<runtime.ApiResponse<CustomField>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateCustomField."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/customfields/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: CustomFieldPayloadToJSON(requestParameters.customFieldPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CustomFieldFromJSON(jsonValue)
    );
  }

  /**
   * Custom Field to update
   * Updates a custom field
   */
  async updateCustomField(
    requestParameters: UpdateCustomFieldRequest
  ): Promise<CustomField> {
    const response = await this.updateCustomFieldRaw(requestParameters);
    return await response.value();
  }
}
