// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  Count,
  CountFromJSON,
  CountToJSON,
  Defect,
  DefectFromJSON,
  DefectToJSON,
  DefectPayload,
  DefectPayloadFromJSON,
  DefectPayloadToJSON,
  LinkDefectPayload,
  LinkDefectPayloadFromJSON,
  LinkDefectPayloadToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON
} from "../models";

export interface CreateDefectRequest {
  defectPayload?: DefectPayload;
}

export interface DeleteDefectRequest {
  id: number;
}

export interface GetDefectRequest {
  id: number;
  project: number;
}

export interface GetDefectCountRequest {
  project: number;
  filter?: string;
}

export interface GetDefectsRequest {
  project: number;
  limit?: number;
  start?: number;
  sort?: string;
  filter?: string;
  testplan?: number;
}

export interface LinkDefectRequest {
  linkDefectPayload?: LinkDefectPayload;
}

export interface UpdateDefectRequest {
  id: number;
  defectPayload?: DefectPayload;
}

/**
 * no description
 */
export class DefectsApi extends runtime.BaseAPI {
  /**
   * Create a new defect
   * Create a defect
   */
  async createDefectRaw(
    requestParameters: CreateDefectRequest
  ): Promise<runtime.ApiResponse<Defect>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/defects`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: DefectPayloadToJSON(requestParameters.defectPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      DefectFromJSON(jsonValue)
    );
  }

  /**
   * Create a new defect
   * Create a defect
   */
  async createDefect(requestParameters: CreateDefectRequest): Promise<Defect> {
    const response = await this.createDefectRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes a specific defect
   * Deletes a defect
   */
  async deleteDefectRaw(
    requestParameters: DeleteDefectRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteDefect."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/defects/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deletes a specific defect
   * Deletes a defect
   */
  async deleteDefect(requestParameters: DeleteDefectRequest): Promise<any> {
    const response = await this.deleteDefectRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get details of a reported defect
   * Get defect
   */
  async getDefectRaw(
    requestParameters: GetDefectRequest
  ): Promise<runtime.ApiResponse<Defect>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getDefect."
      );
    }

    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getDefect."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/defects/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      DefectFromJSON(jsonValue)
    );
  }

  /**
   * Get details of a reported defect
   * Get defect
   */
  async getDefect(requestParameters: GetDefectRequest): Promise<Defect> {
    const response = await this.getDefectRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get defects' count
   * Get count of defects
   */
  async getDefectCountRaw(
    requestParameters: GetDefectCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getDefectCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/defects/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get defects' count
   * Get count of defects
   */
  async getDefectCount(
    requestParameters: GetDefectCountRequest
  ): Promise<Count> {
    const response = await this.getDefectCountRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get list of defects
   * Get list of defects
   */
  async getDefectsRaw(
    requestParameters: GetDefectsRequest
  ): Promise<runtime.ApiResponse<Array<Defect>>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getDefects."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    if (requestParameters.testplan !== undefined) {
      queryParameters["testplan"] = requestParameters.testplan;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/defects`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(DefectFromJSON)
    );
  }

  /**
   * Get list of defects
   * Get list of defects
   */
  async getDefects(
    requestParameters: GetDefectsRequest
  ): Promise<Array<Defect>> {
    const response = await this.getDefectsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Link Defect
   * link an existing defect with test case while execution
   */
  async linkDefectRaw(
    requestParameters: LinkDefectRequest
  ): Promise<runtime.ApiResponse<Defect>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/defects/link`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: LinkDefectPayloadToJSON(requestParameters.linkDefectPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      DefectFromJSON(jsonValue)
    );
  }

  /**
   * Link Defect
   * link an existing defect with test case while execution
   */
  async linkDefect(requestParameters: LinkDefectRequest): Promise<Defect> {
    const response = await this.linkDefectRaw(requestParameters);
    return await response.value();
  }

  /**
   * Defect to update
   * Updates a defect
   */
  async updateDefectRaw(
    requestParameters: UpdateDefectRequest
  ): Promise<runtime.ApiResponse<Defect>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateDefect."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/defects/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: DefectPayloadToJSON(requestParameters.defectPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      DefectFromJSON(jsonValue)
    );
  }

  /**
   * Defect to update
   * Updates a defect
   */
  async updateDefect(requestParameters: UpdateDefectRequest): Promise<Defect> {
    const response = await this.updateDefectRaw(requestParameters);
    return await response.value();
  }
}
