// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  DefaultResponseError,
  DefaultResponseErrorFromJSON,
  DefaultResponseErrorToJSON,
  ForbiddenError,
  ForbiddenErrorFromJSON,
  ForbiddenErrorToJSON,
  TestPlanAssignmentAllPayload,
  TestPlanAssignmentAllPayloadFromJSON,
  TestPlanAssignmentAllPayloadToJSON,
  TestPlanAssignmentPayload,
  TestPlanAssignmentPayloadFromJSON,
  TestPlanAssignmentPayloadToJSON
} from "../models";

export interface AssignAllRequest {
  testPlanAssignmentAllPayload?: TestPlanAssignmentAllPayload;
}

export interface AssignTestPlanRequest {
  testplan: number;
  project: number;
  testPlanAssignmentPayload?: TestPlanAssignmentPayload;
}

/**
 * no description
 */
export class TestPlansAssignmentApi extends runtime.BaseAPI {
  /**
   * Assign all test cases of test plan to user
   */
  async assignAllRaw(
    requestParameters: AssignAllRequest
  ): Promise<runtime.ApiResponse<any>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplans/assignAll`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TestPlanAssignmentAllPayloadToJSON(
        requestParameters.testPlanAssignmentAllPayload
      )
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Assign all test cases of test plan to user
   */
  async assignAll(requestParameters: AssignAllRequest): Promise<any> {
    const response = await this.assignAllRaw(requestParameters);
    return await response.value();
  }

  /**
   * Assign test plan to users
   */
  async assignTestPlanRaw(
    requestParameters: AssignTestPlanRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (
      requestParameters.testplan === null ||
      requestParameters.testplan === undefined
    ) {
      throw new runtime.RequiredError(
        "testplan",
        "Required parameter requestParameters.testplan was null or undefined when calling assignTestPlan."
      );
    }

    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling assignTestPlan."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.testplan !== undefined) {
      queryParameters["testplan"] = requestParameters.testplan;
    }

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplans/assign`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TestPlanAssignmentPayloadToJSON(
        requestParameters.testPlanAssignmentPayload
      )
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Assign test plan to users
   */
  async assignTestPlan(requestParameters: AssignTestPlanRequest): Promise<any> {
    const response = await this.assignTestPlanRaw(requestParameters);
    return await response.value();
  }
}
