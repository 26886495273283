import React, { ReactElement } from "react";
import {
  ProjectUser,
  User,
  Project,
  Projectsetting,
  CustomFieldExtraProps
} from "./services/generated-sdk";
import { CompanyPlan } from "./services/generated-sdk/models/CompanyPlan";
/*
const FadeEffect = ({ component: Component, ...props }) => (
  <Reveal  effect="fadeInUp" key={window.document.location.hash}>
  <Component {...props} />
  </Reveal>
)*/

export interface MapWithRequired extends FieldMap {
  required?: boolean;
}

export interface ProjectSettingsByKey {
  [key: string]: Projectsetting;
}
export interface FieldMap {
  tcField: string;
  datasourceField: string | null;
  title: string;
  type?: string;
  extra?: CustomFieldExtraProps;
}

export interface AppContextInterface {
  projectId: number;
  pageTitle?: string | undefined;
  pageTitleHTML?: ReactElement | undefined;
  projectMembers: Array<any>;
  userIndex: Object;
  deletedUserIndex: Object;
  projectTitle: string | undefined;
  projectArchived: boolean;
  loggedInUser: any;
  projects: Project[];
  loggedIn: boolean;
  planDetails?: CompanyPlan;
  online: boolean;
  company: any;
  projectUsers?: ProjectUser[];
  companyUsers?: User[];
  defectsEnabled: boolean;
  requirementsEnabled: boolean;
  projectSettingsByKey: ProjectSettingsByKey;
  setPageTitle: (title: string | ReactElement, removeProject?: boolean) => void;
  setPageTitleHTML: (html: ReactElement) => void;
  setDefectEnabled: (isEnabled: boolean) => void;
  setRequirementEnabled: (isEnabled: boolean) => void;
  updateUsageDetails?: (company?: number) => void;
  updateToken?: (token?: string) => void;
  resetProject?: () => void;
  showUpgradeModal: (
    payload: {
      title: string;
      content: ReactElement | null;
      visible: boolean;
      feature?: string | null;
    }
  ) => void;
  enabledFeatures: string[];
  copilotUser: User | null;
  setProjectMembers: (members) => void;
  setProjectUsers: (users) => void;
  projectTags?: any[];
  setProjectTags: (tags) => void;
}

const InitAppContext: AppContextInterface = {
  pageTitle: "",
  projectId: 0,
  projectTitle: "",
  projectMembers: [],
  projects: [],
  loggedInUser: false,
  loggedIn: false,
  projectArchived: false,
  online: true,
  userIndex: {},
  deletedUserIndex: {},
  projectSettingsByKey: {},
  projectUsers: [],
  companyUsers: [],
  company: {},
  defectsEnabled: false,
  requirementsEnabled: false,
  setPageTitle: title => {},
  setPageTitleHTML: html => {},
  setDefectEnabled: isEnabled => {},
  setRequirementEnabled: isEnabled => {},
  showUpgradeModal: payload => {},
  resetProject: () => {},
  updateToken: token => {},
  enabledFeatures: [],
  copilotUser: null,
  setProjectMembers: members => {},
  setProjectUsers: users => {},
  setProjectTags: tags => {},
  projectTags: []
};

export const AppContext = React.createContext(InitAppContext);
