// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  Count,
  CountFromJSON,
  CountToJSON,
  ForbiddenError,
  ForbiddenErrorFromJSON,
  ForbiddenErrorToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON,
  Upload,
  UploadFromJSON,
  UploadToJSON,
  UploadPayload,
  UploadPayloadFromJSON,
  UploadPayloadToJSON
} from "../models";

export interface CreateUploadRequest {
  files: Blob;
  company: number;
}

export interface DeleteUploadRequest {
  id: number;
}

export interface GetAttachmentRequest {
  company: number;
  id: number;
  authToken?: string;
}

export interface GetUploadRequest {
  id: number;
}

export interface GetUploadCountRequest {
  project: number;
}

export interface GetUploadsRequest {
  company: number;
  limit?: number;
  sort?: string;
  start?: number;
  filter?: string;
}

export interface UpdateUploadRequest {
  id: number;
  uploadPayload?: UploadPayload;
}

/**
 * no description
 */
export class UploadsApi extends runtime.BaseAPI {
  /**
   * Creates a new upload
   * Create an upload
   */
  async createUploadRaw(
    requestParameters: CreateUploadRequest
  ): Promise<runtime.ApiResponse<Array<Upload>>> {
    if (
      requestParameters.files === null ||
      requestParameters.files === undefined
    ) {
      throw new runtime.RequiredError(
        "files",
        "Required parameter requestParameters.files was null or undefined when calling createUpload."
      );
    }

    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling createUpload."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const formData = new FormData();
    if (requestParameters.files !== undefined) {
      formData.append("files", requestParameters.files as any);
    }

    if (requestParameters.company !== undefined) {
      formData.append("company", requestParameters.company as any);
    }

    const response = await this.request({
      path: `/upload`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: formData
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(UploadFromJSON)
    );
  }

  /**
   * Creates a new upload
   * Create an upload
   */
  async createUpload(
    requestParameters: CreateUploadRequest
  ): Promise<Array<Upload>> {
    const response = await this.createUploadRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes a specific uploaded file
   * Delete an upload
   */
  async deleteUploadRaw(
    requestParameters: DeleteUploadRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteUpload."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/upload/files/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deletes a specific uploaded file
   * Delete an upload
   */
  async deleteUpload(requestParameters: DeleteUploadRequest): Promise<any> {
    const response = await this.deleteUploadRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get details of a specific file uploaded as attachment for a company
   * Get specific uploaded data
   */
  async getAttachmentRaw(
    requestParameters: GetAttachmentRequest
  ): Promise<runtime.ApiResponse<Upload>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getAttachment."
      );
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getAttachment."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    if (requestParameters.id !== undefined) {
      queryParameters["id"] = requestParameters.id;
    }

    if (requestParameters.authToken !== undefined) {
      queryParameters["authToken"] = requestParameters.authToken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/companies/getAttachment`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      UploadFromJSON(jsonValue)
    );
  }

  /**
   * Get details of a specific file uploaded as attachment for a company
   * Get specific uploaded data
   */
  async getAttachment(
    requestParameters: GetAttachmentRequest
  ): Promise<Upload> {
    const response = await this.getAttachmentRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get details of an uploaded file
   * Get upload
   */
  async getUploadRaw(
    requestParameters: GetUploadRequest
  ): Promise<runtime.ApiResponse<Upload>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getUpload."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/upload/files/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      UploadFromJSON(jsonValue)
    );
  }

  /**
   * Get details of an uploaded file
   * Get upload
   */
  async getUpload(requestParameters: GetUploadRequest): Promise<Upload> {
    const response = await this.getUploadRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get uploads' count for a specific project
   * Get uploads' count
   */
  async getUploadCountRaw(
    requestParameters: GetUploadCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getUploadCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/upload/files/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get uploads' count for a specific project
   * Get uploads' count
   */
  async getUploadCount(
    requestParameters: GetUploadCountRequest
  ): Promise<Count> {
    const response = await this.getUploadCountRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get list of files uploaded for a company
   * Get list of uploads
   */
  async getUploadsRaw(
    requestParameters: GetUploadsRequest
  ): Promise<runtime.ApiResponse<Array<Upload>>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getUploads."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/upload/files`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(UploadFromJSON)
    );
  }

  /**
   * Get list of files uploaded for a company
   * Get list of uploads
   */
  async getUploads(
    requestParameters: GetUploadsRequest
  ): Promise<Array<Upload>> {
    const response = await this.getUploadsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Updates an upload
   * Update an upload
   */
  async updateUploadRaw(
    requestParameters: UpdateUploadRequest
  ): Promise<runtime.ApiResponse<Upload>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateUpload."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/upload/files/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: UploadPayloadToJSON(requestParameters.uploadPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      UploadFromJSON(jsonValue)
    );
  }

  /**
   * Updates an upload
   * Update an upload
   */
  async updateUpload(requestParameters: UpdateUploadRequest): Promise<Upload> {
    const response = await this.updateUploadRaw(requestParameters);
    return await response.value();
  }
}
