// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  Activity,
  ActivityFromJSON,
  ActivityToJSON,
  DefaultResponseError,
  DefaultResponseErrorFromJSON,
  DefaultResponseErrorToJSON,
  DefectsTimeline,
  DefectsTimelineFromJSON,
  DefectsTimelineToJSON,
  ForbiddenError,
  ForbiddenErrorFromJSON,
  ForbiddenErrorToJSON,
  ProjectMetrics,
  ProjectMetricsFromJSON,
  ProjectMetricsToJSON,
  ReportPayload,
  ReportPayloadFromJSON,
  ReportPayloadToJSON,
  ReportResult,
  ReportResultFromJSON,
  ReportResultToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON,
  TestCaseFailureDistribution,
  TestCaseFailureDistributionFromJSON,
  TestCaseFailureDistributionToJSON,
  TestCaseLastRunStatus,
  TestCaseLastRunStatusFromJSON,
  TestCaseLastRunStatusToJSON,
  TestCasePassedBySuite,
  TestCasePassedBySuiteFromJSON,
  TestCasePassedBySuiteToJSON,
  TestCasesProneToError,
  TestCasesProneToErrorFromJSON,
  TestCasesProneToErrorToJSON,
  TestPlanBurnDownData,
  TestPlanBurnDownDataFromJSON,
  TestPlanBurnDownDataToJSON,
  TimeSpentPerCase,
  TimeSpentPerCaseFromJSON,
  TimeSpentPerCaseToJSON
} from "../models";

export interface GetActivitiesRequest {
  company: string;
  user?: number;
  project?: number;
  entity?: string;
  entityId?: number;
  limit?: number;
  start?: number;
  sort?: string;
  filter?: string;
}

export interface GetDefectTimelineReportRequest {
  reportPayload?: ReportPayload;
}

export interface GetDefectsTimelineRequest {
  project: number;
  startDate?: Date;
  endDate?: Date;
}

export interface GetProjectMetricsRequest {
  project: number;
  startDate?: Date;
  endDate?: Date;
}

export interface GetReportDataRequest {
  reportPayload?: ReportPayload;
}

export interface GetTCFailureDistributionRequest {
  project: number;
  startDate?: Date;
  endDate?: Date;
}

export interface GetTestCaseLastRunStatusRequest {
  project: number;
  startDate?: Date;
  endDate?: Date;
}

export interface GetTestCasePassedPercentRequest {
  project: number;
  startDate?: Date;
  endDate?: Date;
}

export interface GetTestCasesProneToErrorsRequest {
  project: number;
  failThreshold?: number;
  startDate?: Date;
  endDate?: Date;
  limit?: number;
  start?: number;
  sort?: string;
  filter?: string;
}

export interface GetTestPlanBurnDownDataRequest {
  project: number;
  testPlan: number;
}

export interface GetTestPlanBurnDownDataOldRequest {
  project: number;
  testPlan: number;
}

export interface GetTimeSpentPerCaseRequest {
  project: number;
  timeSeries?: GetTimeSpentPerCaseTimeSeriesEnum;
  startDate?: Date;
  endDate?: Date;
}

/**
 * no description
 */
export class ReportsApi extends runtime.BaseAPI {
  /**
   * Fetches the recent activities for a Project/Test Plan/User
   * Fetch Recent Activities
   */
  async getActivitiesRaw(
    requestParameters: GetActivitiesRequest
  ): Promise<runtime.ApiResponse<Array<Activity>>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getActivities."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    if (requestParameters.user !== undefined) {
      queryParameters["user"] = requestParameters.user;
    }

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.entity !== undefined) {
      queryParameters["entity"] = requestParameters.entity;
    }

    if (requestParameters.entityId !== undefined) {
      queryParameters["entity_id"] = requestParameters.entityId;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/activities`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(ActivityFromJSON)
    );
  }

  /**
   * Fetches the recent activities for a Project/Test Plan/User
   * Fetch Recent Activities
   */
  async getActivities(
    requestParameters: GetActivitiesRequest
  ): Promise<Array<Activity>> {
    const response = await this.getActivitiesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get defects timeline for report purpose
   * Get defect timeline
   */
  async getDefectTimelineReportRaw(
    requestParameters: GetDefectTimelineReportRequest
  ): Promise<runtime.ApiResponse<ReportResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/defects/report`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ReportPayloadToJSON(requestParameters.reportPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ReportResultFromJSON(jsonValue)
    );
  }

  /**
   * Get defects timeline for report purpose
   * Get defect timeline
   */
  async getDefectTimelineReport(
    requestParameters: GetDefectTimelineReportRequest
  ): Promise<ReportResult> {
    const response = await this.getDefectTimelineReportRaw(requestParameters);
    return await response.value();
  }

  /**
   * Defects timeline data , helps in plotting Defects Opened graph
   * Defects Reported
   */
  async getDefectsTimelineRaw(
    requestParameters: GetDefectsTimelineRequest
  ): Promise<runtime.ApiResponse<DefectsTimeline>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getDefectsTimeline."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.startDate !== undefined) {
      queryParameters[
        "start_date"
      ] = (requestParameters.startDate as any).toISOString();
    }

    if (requestParameters.endDate !== undefined) {
      queryParameters[
        "end_date"
      ] = (requestParameters.endDate as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/project/tcm_reporting/tcm_reports/get_data/defectTimeline`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      DefectsTimelineFromJSON(jsonValue)
    );
  }

  /**
   * Defects timeline data , helps in plotting Defects Opened graph
   * Defects Reported
   */
  async getDefectsTimeline(
    requestParameters: GetDefectsTimelineRequest
  ): Promise<DefectsTimeline> {
    const response = await this.getDefectsTimelineRaw(requestParameters);
    return await response.value();
  }

  /**
   * Fetches the project specific metrics
   * Test Metrics
   */
  async getProjectMetricsRaw(
    requestParameters: GetProjectMetricsRequest
  ): Promise<runtime.ApiResponse<ProjectMetrics>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getProjectMetrics."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.startDate !== undefined) {
      queryParameters[
        "start_date"
      ] = (requestParameters.startDate as any).toISOString();
    }

    if (requestParameters.endDate !== undefined) {
      queryParameters[
        "end_date"
      ] = (requestParameters.endDate as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/projects/projectMetrics`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ProjectMetricsFromJSON(jsonValue)
    );
  }

  /**
   * Fetches the project specific metrics
   * Test Metrics
   */
  async getProjectMetrics(
    requestParameters: GetProjectMetricsRequest
  ): Promise<ProjectMetrics> {
    const response = await this.getProjectMetricsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get report data
   * Get timeline or any other report data
   */
  async getReportDataRaw(
    requestParameters: GetReportDataRequest
  ): Promise<runtime.ApiResponse<ReportResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/reports`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ReportPayloadToJSON(requestParameters.reportPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ReportResultFromJSON(jsonValue)
    );
  }

  /**
   * Get report data
   * Get timeline or any other report data
   */
  async getReportData(
    requestParameters: GetReportDataRequest
  ): Promise<ReportResult> {
    const response = await this.getReportDataRaw(requestParameters);
    return await response.value();
  }

  /**
   * Fetches the data on test case failure distribution based on the threshold selected
   * Test Cases Failure Distribution
   */
  async getTCFailureDistributionRaw(
    requestParameters: GetTCFailureDistributionRequest
  ): Promise<runtime.ApiResponse<TestCaseFailureDistribution>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTCFailureDistribution."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.startDate !== undefined) {
      queryParameters[
        "start_date"
      ] = (requestParameters.startDate as any).toISOString();
    }

    if (requestParameters.endDate !== undefined) {
      queryParameters[
        "end_date"
      ] = (requestParameters.endDate as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/project/tcm_reporting/tcm_reports/get_data/testFailureDistribution`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestCaseFailureDistributionFromJSON(jsonValue)
    );
  }

  /**
   * Fetches the data on test case failure distribution based on the threshold selected
   * Test Cases Failure Distribution
   */
  async getTCFailureDistribution(
    requestParameters: GetTCFailureDistributionRequest
  ): Promise<TestCaseFailureDistribution> {
    const response = await this.getTCFailureDistributionRaw(requestParameters);
    return await response.value();
  }

  /**
   * Fetches summary of last run status for test cases within project
   * Test Cases Last Run Status
   */
  async getTestCaseLastRunStatusRaw(
    requestParameters: GetTestCaseLastRunStatusRequest
  ): Promise<runtime.ApiResponse<TestCaseLastRunStatus>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestCaseLastRunStatus."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.startDate !== undefined) {
      queryParameters[
        "start_date"
      ] = (requestParameters.startDate as any).toISOString();
    }

    if (requestParameters.endDate !== undefined) {
      queryParameters[
        "end_date"
      ] = (requestParameters.endDate as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/project/tcm_reporting/tcm_reports/get_data/testCaseLastStatuses`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestCaseLastRunStatusFromJSON(jsonValue)
    );
  }

  /**
   * Fetches summary of last run status for test cases within project
   * Test Cases Last Run Status
   */
  async getTestCaseLastRunStatus(
    requestParameters: GetTestCaseLastRunStatusRequest
  ): Promise<TestCaseLastRunStatus> {
    const response = await this.getTestCaseLastRunStatusRaw(requestParameters);
    return await response.value();
  }

  /**
   * Fetches test cases passed percetage by suites to form the heatmap
   * Test Cases Passed % by Suite
   */
  async getTestCasePassedPercentRaw(
    requestParameters: GetTestCasePassedPercentRequest
  ): Promise<runtime.ApiResponse<Array<TestCasePassedBySuite>>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestCasePassedPercent."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.startDate !== undefined) {
      queryParameters[
        "start_date"
      ] = (requestParameters.startDate as any).toISOString();
    }

    if (requestParameters.endDate !== undefined) {
      queryParameters[
        "end_date"
      ] = (requestParameters.endDate as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/project/tcm_reporting/tcm_reports/get_data/testCasePassedPercentBySuite`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(TestCasePassedBySuiteFromJSON)
    );
  }

  /**
   * Fetches test cases passed percetage by suites to form the heatmap
   * Test Cases Passed % by Suite
   */
  async getTestCasePassedPercent(
    requestParameters: GetTestCasePassedPercentRequest
  ): Promise<Array<TestCasePassedBySuite>> {
    const response = await this.getTestCasePassedPercentRaw(requestParameters);
    return await response.value();
  }

  /**
   * Fetches the data for the top 5 (max) test cases that are prone to errors based on failure threshold selected
   * Cases Prone to Errors
   */
  async getTestCasesProneToErrorsRaw(
    requestParameters: GetTestCasesProneToErrorsRequest
  ): Promise<runtime.ApiResponse<TestCasesProneToError>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestCasesProneToErrors."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.failThreshold !== undefined) {
      queryParameters["fail_threshold"] = requestParameters.failThreshold;
    }

    if (requestParameters.startDate !== undefined) {
      queryParameters[
        "start_date"
      ] = (requestParameters.startDate as any).toISOString();
    }

    if (requestParameters.endDate !== undefined) {
      queryParameters[
        "end_date"
      ] = (requestParameters.endDate as any).toISOString();
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/project/tcm_reporting/tcm_reports/get_data/testCaseProneToErrors`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestCasesProneToErrorFromJSON(jsonValue)
    );
  }

  /**
   * Fetches the data for the top 5 (max) test cases that are prone to errors based on failure threshold selected
   * Cases Prone to Errors
   */
  async getTestCasesProneToErrors(
    requestParameters: GetTestCasesProneToErrorsRequest
  ): Promise<TestCasesProneToError> {
    const response = await this.getTestCasesProneToErrorsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Fetches test plan burn down chart data
   * Test Plan burn down chart data
   */
  async getTestPlanBurnDownDataRaw(
    requestParameters: GetTestPlanBurnDownDataRequest
  ): Promise<runtime.ApiResponse<ReportResult>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestPlanBurnDownData."
      );
    }

    if (
      requestParameters.testPlan === null ||
      requestParameters.testPlan === undefined
    ) {
      throw new runtime.RequiredError(
        "testPlan",
        "Required parameter requestParameters.testPlan was null or undefined when calling getTestPlanBurnDownData."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.testPlan !== undefined) {
      queryParameters["testPlan"] = requestParameters.testPlan;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/reports/testPlanBurnDownData`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ReportResultFromJSON(jsonValue)
    );
  }

  /**
   * Fetches test plan burn down chart data
   * Test Plan burn down chart data
   */
  async getTestPlanBurnDownData(
    requestParameters: GetTestPlanBurnDownDataRequest
  ): Promise<ReportResult> {
    const response = await this.getTestPlanBurnDownDataRaw(requestParameters);
    return await response.value();
  }

  /**
   * Fetches test plan burn down chart data
   * Test Plan burn down chart data
   */
  async getTestPlanBurnDownDataOldRaw(
    requestParameters: GetTestPlanBurnDownDataOldRequest
  ): Promise<runtime.ApiResponse<TestPlanBurnDownData>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestPlanBurnDownDataOld."
      );
    }

    if (
      requestParameters.testPlan === null ||
      requestParameters.testPlan === undefined
    ) {
      throw new runtime.RequiredError(
        "testPlan",
        "Required parameter requestParameters.testPlan was null or undefined when calling getTestPlanBurnDownDataOld."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.testPlan !== undefined) {
      queryParameters["testPlan"] = requestParameters.testPlan;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/project/tcm_reporting/tcm_reports/get_data/testPlanBurnDownData`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestPlanBurnDownDataFromJSON(jsonValue)
    );
  }

  /**
   * Fetches test plan burn down chart data
   * Test Plan burn down chart data
   */
  async getTestPlanBurnDownDataOld(
    requestParameters: GetTestPlanBurnDownDataOldRequest
  ): Promise<TestPlanBurnDownData> {
    const response = await this.getTestPlanBurnDownDataOldRaw(
      requestParameters
    );
    return await response.value();
  }

  /**
   * Time spent on executing each test case
   * Time Spent on Each Case
   */
  async getTimeSpentPerCaseRaw(
    requestParameters: GetTimeSpentPerCaseRequest
  ): Promise<runtime.ApiResponse<TimeSpentPerCase>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTimeSpentPerCase."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.timeSeries !== undefined) {
      queryParameters["timeSeries"] = requestParameters.timeSeries;
    }

    if (requestParameters.startDate !== undefined) {
      queryParameters[
        "start_date"
      ] = (requestParameters.startDate as any).toISOString();
    }

    if (requestParameters.endDate !== undefined) {
      queryParameters[
        "end_date"
      ] = (requestParameters.endDate as any).toISOString();
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/project/tcm_reporting/tcm_reports/get_data/timeSpentPerCase`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TimeSpentPerCaseFromJSON(jsonValue)
    );
  }

  /**
   * Time spent on executing each test case
   * Time Spent on Each Case
   */
  async getTimeSpentPerCase(
    requestParameters: GetTimeSpentPerCaseRequest
  ): Promise<TimeSpentPerCase> {
    const response = await this.getTimeSpentPerCaseRaw(requestParameters);
    return await response.value();
  }
}

/**
 * @export
 * @enum {string}
 */
export enum GetTimeSpentPerCaseTimeSeriesEnum {
  Sum = "sum",
  Average = "average",
  Min = "min",
  Max = "max"
}
