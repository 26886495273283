// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  Count,
  CountFromJSON,
  CountToJSON,
  DefaultResponseError,
  DefaultResponseErrorFromJSON,
  DefaultResponseErrorToJSON,
  ForbiddenError,
  ForbiddenErrorFromJSON,
  ForbiddenErrorToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON,
  TestCaseRevision,
  TestCaseRevisionFromJSON,
  TestCaseRevisionToJSON,
  TestCaseRevisionPayload,
  TestCaseRevisionPayloadFromJSON,
  TestCaseRevisionPayloadToJSON
} from "../models";

export interface CreateTestCaseRevisionRequest {
  testCaseRevisionPayload?: TestCaseRevisionPayload;
}

export interface DeleteTestCaseRevisionRequest {
  id: number;
}

export interface GetTestCaseLatestRevisionRequest {
  project: number;
  testcase: number;
  filter?: string;
}

export interface GetTestCaseRevisionRequest {
  id: number;
}

export interface GetTestCaseRevisionCountRequest {
  project: number;
  filter?: string;
}

export interface GetTestCaseRevisionsRequest {
  testcase: number;
  project?: number;
  limit?: number;
  start?: number;
  sort?: string;
  filter?: string;
}

export interface UpdateTestCaseRevisionRequest {
  id: number;
  testCaseRevisionPayload?: TestCaseRevisionPayload;
}

/**
 * no description
 */
export class TestCaseRevisionsApi extends runtime.BaseAPI {
  /**
   * Test Case Revision to add
   * Create a new test case revision
   */
  async createTestCaseRevisionRaw(
    requestParameters: CreateTestCaseRevisionRequest
  ): Promise<runtime.ApiResponse<TestCaseRevision>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcaserevisions`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TestCaseRevisionPayloadToJSON(
        requestParameters.testCaseRevisionPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestCaseRevisionFromJSON(jsonValue)
    );
  }

  /**
   * Test Case Revision to add
   * Create a new test case revision
   */
  async createTestCaseRevision(
    requestParameters: CreateTestCaseRevisionRequest
  ): Promise<TestCaseRevision> {
    const response = await this.createTestCaseRevisionRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes a specific test case revision
   * Delete test case revision
   */
  async deleteTestCaseRevisionRaw(
    requestParameters: DeleteTestCaseRevisionRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteTestCaseRevision."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcaserevisions/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deletes a specific test case revision
   * Delete test case revision
   */
  async deleteTestCaseRevision(
    requestParameters: DeleteTestCaseRevisionRequest
  ): Promise<any> {
    const response = await this.deleteTestCaseRevisionRaw(requestParameters);
    return await response.value();
  }

  /**
   * Gets the latest revision for a specific test case
   * Get latest test case revision
   */
  async getTestCaseLatestRevisionRaw(
    requestParameters: GetTestCaseLatestRevisionRequest
  ): Promise<runtime.ApiResponse<TestCaseRevision>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestCaseLatestRevision."
      );
    }

    if (
      requestParameters.testcase === null ||
      requestParameters.testcase === undefined
    ) {
      throw new runtime.RequiredError(
        "testcase",
        "Required parameter requestParameters.testcase was null or undefined when calling getTestCaseLatestRevision."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.testcase !== undefined) {
      queryParameters["testcase"] = requestParameters.testcase;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcaserevisions/getLatest`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestCaseRevisionFromJSON(jsonValue)
    );
  }

  /**
   * Gets the latest revision for a specific test case
   * Get latest test case revision
   */
  async getTestCaseLatestRevision(
    requestParameters: GetTestCaseLatestRevisionRequest
  ): Promise<TestCaseRevision> {
    const response = await this.getTestCaseLatestRevisionRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get details of a specific test case revision
   * Get test case revision
   */
  async getTestCaseRevisionRaw(
    requestParameters: GetTestCaseRevisionRequest
  ): Promise<runtime.ApiResponse<TestCaseRevision>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getTestCaseRevision."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcaserevisions/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestCaseRevisionFromJSON(jsonValue)
    );
  }

  /**
   * Get details of a specific test case revision
   * Get test case revision
   */
  async getTestCaseRevision(
    requestParameters: GetTestCaseRevisionRequest
  ): Promise<TestCaseRevision> {
    const response = await this.getTestCaseRevisionRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get count of test case revisions according to the filter criteria
   * Get test case revisions' count
   */
  async getTestCaseRevisionCountRaw(
    requestParameters: GetTestCaseRevisionCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestCaseRevisionCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcaserevisions/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get count of test case revisions according to the filter criteria
   * Get test case revisions' count
   */
  async getTestCaseRevisionCount(
    requestParameters: GetTestCaseRevisionCountRequest
  ): Promise<Count> {
    const response = await this.getTestCaseRevisionCountRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get list of revisions a test case has been through
   * Get test case revisions
   */
  async getTestCaseRevisionsRaw(
    requestParameters: GetTestCaseRevisionsRequest
  ): Promise<runtime.ApiResponse<Array<TestCaseRevision>>> {
    if (
      requestParameters.testcase === null ||
      requestParameters.testcase === undefined
    ) {
      throw new runtime.RequiredError(
        "testcase",
        "Required parameter requestParameters.testcase was null or undefined when calling getTestCaseRevisions."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.testcase !== undefined) {
      queryParameters["testcase"] = requestParameters.testcase;
    }

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcaserevisions`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(TestCaseRevisionFromJSON)
    );
  }

  /**
   * Get list of revisions a test case has been through
   * Get test case revisions
   */
  async getTestCaseRevisions(
    requestParameters: GetTestCaseRevisionsRequest
  ): Promise<Array<TestCaseRevision>> {
    const response = await this.getTestCaseRevisionsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Test Case Revision to update
   * Updates a test case revision
   */
  async updateTestCaseRevisionRaw(
    requestParameters: UpdateTestCaseRevisionRequest
  ): Promise<runtime.ApiResponse<TestCaseRevision>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateTestCaseRevision."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcaserevisions/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: TestCaseRevisionPayloadToJSON(
        requestParameters.testCaseRevisionPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestCaseRevisionFromJSON(jsonValue)
    );
  }

  /**
   * Test Case Revision to update
   * Updates a test case revision
   */
  async updateTestCaseRevision(
    requestParameters: UpdateTestCaseRevisionRequest
  ): Promise<TestCaseRevision> {
    const response = await this.updateTestCaseRevisionRaw(requestParameters);
    return await response.value();
  }
}
