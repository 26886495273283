// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  AppManagerLinkPayload,
  AppManagerLinkPayloadFromJSON,
  AppManagerLinkPayloadToJSON,
  AppManagerProfile,
  AppManagerProfileFromJSON,
  AppManagerProfileToJSON,
  AppManagerProfilePayload,
  AppManagerProfilePayloadFromJSON,
  AppManagerProfilePayloadToJSON,
  AppManagerTestPayload,
  AppManagerTestPayloadFromJSON,
  AppManagerTestPayloadToJSON,
  Count,
  CountFromJSON,
  CountToJSON,
  GenericActionResult,
  GenericActionResultFromJSON,
  GenericActionResultToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON
} from "../models";

export interface CreateAppManagerProfileRequest {
  appManagerProfilePayload?: AppManagerProfilePayload;
}

export interface DeleteAppManagerProfileRequest {
  id: number;
}

export interface GetAppManagerProfileRequest {
  id: number;
}

export interface GetAppManagerProfileCountRequest {
  company: number;
  filter?: string;
}

export interface GetAppManagerProfilesRequest {
  company?: number;
  limit?: number;
  sort?: string;
  start?: number;
  filter?: string;
}

export interface LinkAppManagerRequest {
  appManagerLinkPayload?: AppManagerLinkPayload;
}

export interface TestAppManagerRequest {
  appManagerTestPayload?: AppManagerTestPayload;
}

export interface UpdateAppManagerProfileRequest {
  id: number;
  appManagerProfilePayload?: AppManagerProfilePayload;
}

/**
 * no description
 */
export class AppManagerProfilesApi extends runtime.BaseAPI {
  /**
   * App Manager Profile to add
   * Create a new app manager profile
   */
  async createAppManagerProfileRaw(
    requestParameters: CreateAppManagerProfileRequest
  ): Promise<runtime.ApiResponse<AppManagerProfile>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/appmanagerprofiles`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: AppManagerProfilePayloadToJSON(
        requestParameters.appManagerProfilePayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AppManagerProfileFromJSON(jsonValue)
    );
  }

  /**
   * App Manager Profile to add
   * Create a new app manager profile
   */
  async createAppManagerProfile(
    requestParameters: CreateAppManagerProfileRequest
  ): Promise<AppManagerProfile> {
    const response = await this.createAppManagerProfileRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes specific app manager profile
   * Delete app manager profile
   */
  async deleteAppManagerProfileRaw(
    requestParameters: DeleteAppManagerProfileRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteAppManagerProfile."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/appmanagerprofiles/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deletes specific app manager profile
   * Delete app manager profile
   */
  async deleteAppManagerProfile(
    requestParameters: DeleteAppManagerProfileRequest
  ): Promise<any> {
    const response = await this.deleteAppManagerProfileRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get specific app manager profile
   * Get app manager profile
   */
  async getAppManagerProfileRaw(
    requestParameters: GetAppManagerProfileRequest
  ): Promise<runtime.ApiResponse<AppManagerProfile>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getAppManagerProfile."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/appmanagerprofiles/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AppManagerProfileFromJSON(jsonValue)
    );
  }

  /**
   * Get specific app manager profile
   * Get app manager profile
   */
  async getAppManagerProfile(
    requestParameters: GetAppManagerProfileRequest
  ): Promise<AppManagerProfile> {
    const response = await this.getAppManagerProfileRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get count of app manager profiles
   * Get count of app manager profiles
   */
  async getAppManagerProfileCountRaw(
    requestParameters: GetAppManagerProfileCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getAppManagerProfileCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/appmanagerprofiles/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get count of app manager profiles
   * Get count of app manager profiles
   */
  async getAppManagerProfileCount(
    requestParameters: GetAppManagerProfileCountRequest
  ): Promise<Count> {
    const response = await this.getAppManagerProfileCountRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get list of app manager profiles
   * Get list of app manager profiles
   */
  async getAppManagerProfilesRaw(
    requestParameters: GetAppManagerProfilesRequest
  ): Promise<runtime.ApiResponse<Array<AppManagerProfile>>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/appmanagerprofiles`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(AppManagerProfileFromJSON)
    );
  }

  /**
   * Get list of app manager profiles
   * Get list of app manager profiles
   */
  async getAppManagerProfiles(
    requestParameters: GetAppManagerProfilesRequest
  ): Promise<Array<AppManagerProfile>> {
    const response = await this.getAppManagerProfilesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Link App Manager
   * Link app manager
   */
  async linkAppManagerRaw(
    requestParameters: LinkAppManagerRequest
  ): Promise<runtime.ApiResponse<GenericActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/appmanagerprofiles/link`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: AppManagerLinkPayloadToJSON(requestParameters.appManagerLinkPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      GenericActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Link App Manager
   * Link app manager
   */
  async linkAppManager(
    requestParameters: LinkAppManagerRequest
  ): Promise<GenericActionResult> {
    const response = await this.linkAppManagerRaw(requestParameters);
    return await response.value();
  }

  /**
   * Test linked App Manager by sending a test message
   * Test linked app manager
   */
  async testAppManagerRaw(
    requestParameters: TestAppManagerRequest
  ): Promise<runtime.ApiResponse<GenericActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/appmanagerprofiles/testAppConnection`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: AppManagerTestPayloadToJSON(requestParameters.appManagerTestPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      GenericActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Test linked App Manager by sending a test message
   * Test linked app manager
   */
  async testAppManager(
    requestParameters: TestAppManagerRequest
  ): Promise<GenericActionResult> {
    const response = await this.testAppManagerRaw(requestParameters);
    return await response.value();
  }

  /**
   * App Manager Profile to update
   * Updates a app manager profile
   */
  async updateAppManagerProfileRaw(
    requestParameters: UpdateAppManagerProfileRequest
  ): Promise<runtime.ApiResponse<AppManagerProfile>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateAppManagerProfile."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/appmanagerprofiles/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: AppManagerProfilePayloadToJSON(
        requestParameters.appManagerProfilePayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      AppManagerProfileFromJSON(jsonValue)
    );
  }

  /**
   * App Manager Profile to update
   * Updates a app manager profile
   */
  async updateAppManagerProfile(
    requestParameters: UpdateAppManagerProfileRequest
  ): Promise<AppManagerProfile> {
    const response = await this.updateAppManagerProfileRaw(requestParameters);
    return await response.value();
  }
}
