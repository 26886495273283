export const locateUser = async email => {
  // check if user exists in US server or EU
  //let api = new UsersApi();
  let US_URL = process.env.API_BASE_URL;
  let EU_URL = process.env.API_BASE_URL_EU;

  // first call /locateUser on US server post
  let locateUser = await fetch(`${US_URL}/locateUser`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ user: email })
  });

  let locateUserJson = await locateUser.json();
  // { exists: true }

  // check if user exists
  if (locateUserJson.exists) {
    console.log("user exists in US server");
    // store region in local storage
    localStorage.setItem("region", "US");
    return;
  }

  // user not found in us, check in EU
  let locateUserEU = await fetch(`${EU_URL}/locateUser`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ user: email })
  });

  let locateUserJsonEU = await locateUserEU.json();

  if (locateUserJsonEU.exists) {
    console.log("user exists in EU server");
    // store region in local storage
    localStorage.setItem("region", "EU");
    return;
  }
};

export const getLocationForCurrentSession = () => {
  //localStorage.getItem('region')
  // check if region is stored in local storage
  const region = localStorage.getItem("region");

  // if null, then fallback to US
  if (region == null) {
    return "US";
  }
  return region;
};

export const onLogout = () => {
  // clear local storage
  localStorage.removeItem("region");
};

export const fetchFeatureStatus = async featureName => {
  try {
    let statusResult = await fetch(
      `${process.env.API_BASE_URL}/getFeatureStatus?feature=${
        featureName ? featureName : "qa_copilot"
      }`,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );
    // console.log({ statusResult });
    if (statusResult.json) {
      statusResult = await statusResult.json();
    }
    // console.log({ featureName, statusResult });
    if (statusResult && statusResult.status) {
      return true;
    }
  } catch (e) {
    console.log({ fetchFeatureStatus_error: e });
  }

  // if (statusResult && statusResult.active) {
  //   let result = false;
  //   if (statusResult.strategies && statusResult.strategies.length) {
  //     statusResult.strategies.forEach((stgItm, indx) => {
  //       if (stgItm && stgItm.scopes && stgItm.scopes.length) {
  //         stgItm.scopes.forEach((scpItm, indx2) => {
  //           if (
  //             scpItm &&
  //             scpItm.environment_scope &&
  //             scpItm.environment_scope == process.env.NODE_ENV
  //           ) {
  //             result = true;
  //           }
  //         });
  //       }
  //     });
  //   }
  //   return result;
  // }
  return false;
};
