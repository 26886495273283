// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  ClearAllNotificationPayload,
  ClearAllNotificationPayloadFromJSON,
  ClearAllNotificationPayloadToJSON,
  Count,
  CountFromJSON,
  CountToJSON,
  MarkAllNotificationPayload,
  MarkAllNotificationPayloadFromJSON,
  MarkAllNotificationPayloadToJSON,
  MarkNotificationPayload,
  MarkNotificationPayloadFromJSON,
  MarkNotificationPayloadToJSON,
  Notification,
  NotificationFromJSON,
  NotificationToJSON,
  NotificationGroups,
  NotificationGroupsFromJSON,
  NotificationGroupsToJSON,
  NotificationPayloadCollection,
  NotificationPayloadCollectionFromJSON,
  NotificationPayloadCollectionToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON
} from "../models";

export interface ClearAllRequest {
  clearAllNotificationPayload?: ClearAllNotificationPayload;
}

export interface DeleteNotificationRequest {
  id: number;
  company: number;
}

export interface GetAllNotificationsRequest {
  company: number;
}

export interface GetMyNotificationsRequest {
  company: string;
  limit?: number;
  sort?: string;
  start?: number;
  filter?: string;
}

export interface GetNotificationsCountRequest {
  company: number;
  filter?: string;
}

export interface MarkAsReadRequest {
  markAllNotificationPayload?: MarkAllNotificationPayload;
}

export interface MarkOneReadRequest {
  markNotificationPayload?: MarkNotificationPayload;
}

export interface UpdateNotificationsStatusRequest {
  notificationPayloadCollection?: NotificationPayloadCollection;
}

/**
 * no description
 */
export class NotificationsApi extends runtime.BaseAPI {
  /**
   * Clear all notifications
   */
  async clearAllRaw(
    requestParameters: ClearAllRequest
  ): Promise<runtime.ApiResponse<any>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/notifications/clearAll`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ClearAllNotificationPayloadToJSON(
        requestParameters.clearAllNotificationPayload
      )
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Clear all notifications
   */
  async clearAll(requestParameters: ClearAllRequest): Promise<any> {
    const response = await this.clearAllRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes given notification
   * Deletes given notification
   */
  async deleteNotificationRaw(
    requestParameters: DeleteNotificationRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteNotification."
      );
    }

    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling deleteNotification."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/notifications/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deletes given notification
   * Deletes given notification
   */
  async deleteNotification(
    requestParameters: DeleteNotificationRequest
  ): Promise<any> {
    const response = await this.deleteNotificationRaw(requestParameters);
    return await response.value();
  }

  /**
   * Gets all notifications available in system
   */
  async getAllNotificationsRaw(
    requestParameters: GetAllNotificationsRequest
  ): Promise<runtime.ApiResponse<NotificationGroups>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getAllNotifications."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/notifications`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      NotificationGroupsFromJSON(jsonValue)
    );
  }

  /**
   * Gets all notifications available in system
   */
  async getAllNotifications(
    requestParameters: GetAllNotificationsRequest
  ): Promise<NotificationGroups> {
    const response = await this.getAllNotificationsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get users notifications
   * Get user's notifications
   */
  async getMyNotificationsRaw(
    requestParameters: GetMyNotificationsRequest
  ): Promise<runtime.ApiResponse<Array<Notification>>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getMyNotifications."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/notifications/myNotifications`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(NotificationFromJSON)
    );
  }

  /**
   * Get users notifications
   * Get user's notifications
   */
  async getMyNotifications(
    requestParameters: GetMyNotificationsRequest
  ): Promise<Array<Notification>> {
    const response = await this.getMyNotificationsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get notifications' count
   * Get count of notifications
   */
  async getNotificationsCountRaw(
    requestParameters: GetNotificationsCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getNotificationsCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/notifications/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get notifications' count
   * Get count of notifications
   */
  async getNotificationsCount(
    requestParameters: GetNotificationsCountRequest
  ): Promise<Count> {
    const response = await this.getNotificationsCountRaw(requestParameters);
    return await response.value();
  }

  /**
   * Mark notifications as read
   */
  async markAsReadRaw(
    requestParameters: MarkAsReadRequest
  ): Promise<runtime.ApiResponse<any>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/notifications/markAsRead`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: MarkAllNotificationPayloadToJSON(
        requestParameters.markAllNotificationPayload
      )
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Mark notifications as read
   */
  async markAsRead(requestParameters: MarkAsReadRequest): Promise<any> {
    const response = await this.markAsReadRaw(requestParameters);
    return await response.value();
  }

  /**
   * Mark notifications as read
   */
  async markOneReadRaw(
    requestParameters: MarkOneReadRequest
  ): Promise<runtime.ApiResponse<any>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/notifications/markOneRead`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: MarkNotificationPayloadToJSON(
        requestParameters.markNotificationPayload
      )
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Mark notifications as read
   */
  async markOneRead(requestParameters: MarkOneReadRequest): Promise<any> {
    const response = await this.markOneReadRaw(requestParameters);
    return await response.value();
  }

  /**
   * Enables/Disabled given notifications for current user
   */
  async updateNotificationsStatusRaw(
    requestParameters: UpdateNotificationsStatusRequest
  ): Promise<runtime.ApiResponse<any>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/notifications/updateStatus`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: NotificationPayloadCollectionToJSON(
        requestParameters.notificationPayloadCollection
      )
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Enables/Disabled given notifications for current user
   */
  async updateNotificationsStatus(
    requestParameters: UpdateNotificationsStatusRequest
  ): Promise<any> {
    const response = await this.updateNotificationsStatusRaw(requestParameters);
    return await response.value();
  }
}
