import { Configuration } from "./services/generated-sdk";

export const getDevConfig = () => {
  const config = new Configuration({
    basePath: "http://localhost:8080/legacy/index.php/",
    apiKey: "49468cb06e4bd5184d2f19b92fc9ca8c17d24aea"
  });
  return config;
};

export const getAPIConfig = (jwt = "") => {
  //console.log("getAPIConfig");
  let _jwt = "";
  if (jwt != "") {
    _jwt = jwt;
  } else {
    _jwt = window.JWT != undefined ? window.JWT : "";
  }

  // check if this is not a browser
  if (typeof window === "undefined") {
    /*
    console.log("getAPIConfig: not a browser");
    console.log("todo: handle this scenario");*/
    const realApi = new Configuration({
      apiKey: "Bearer " + _jwt
    });
    return realApi;

    //return false;
  }

  // its a browser!

  // check if region is stored in local storage
  // edge cases: what if local storage is not enabled?
  if (localStorage.getItem("region")) {
    region = localStorage.getItem("region");
  } else {
    console.log("checking for region in url...");
    // check if there's a GET param 'region'
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("region")) {
      region = urlParams.get("region");
      localStorage.setItem("region", region);
    } else {
      // assume US region
      region = "US";
    }
  }

  // check if region is EU
  if (region == "EU") {
    //let url;

    if (process.env && process.env.API_BASE_URL_EU) {
      //url = process.env["API_BASE_URL_EU"];
      const EU_API = new Configuration({
        apiKey: "Bearer " + _jwt,
        basePath: process.env.API_BASE_URL_EU
      });
      return EU_API;
    } else {
      console.error("EU API server not configured! ");
      return false;
    }
  }

  const realApi = new Configuration({
    apiKey: "Bearer " + _jwt
  });
  return realApi;
};

// export const getMockConfig = () => {
//   const config = new Configuration({
//     basePath: "https://virtserver.swaggerhub.com/gigapro/test/2.0.0",
//     apiKey: "49468cb06e4bd5184d2f19b92fc9ca8c17d24aea"
//   });
//   return config;
// };
