// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  DefaultResponseError,
  DefaultResponseErrorFromJSON,
  DefaultResponseErrorToJSON,
  ForbiddenError,
  ForbiddenErrorFromJSON,
  ForbiddenErrorToJSON,
  IssueManagerConfigField,
  IssueManagerConfigFieldFromJSON,
  IssueManagerConfigFieldToJSON,
  IssueManagerFieldMapping,
  IssueManagerFieldMappingFromJSON,
  IssueManagerFieldMappingToJSON
} from "../models";

export interface GetIssueManagerConfigFieldsRequest {
  project: number;
  limit?: number;
  sort?: string;
  start?: number;
  filter?: string;
}

export interface GetIssueManagerFieldMappingRequest {
  project: number;
  limit?: number;
  sort?: string;
  start?: number;
  filter?: string;
}

export interface IssueManagerFieldMappingRequest {
  issueManagerFieldMapping?: Array<IssueManagerFieldMapping>;
}

/**
 * no description
 */
export class IssuemanagerfieldmappingApi extends runtime.BaseAPI {
  /**
   * Fetch the list of fields included while issue manager configuration for the given project
   * Issue manager configuration fields
   */
  async getIssueManagerConfigFieldsRaw(
    requestParameters: GetIssueManagerConfigFieldsRequest
  ): Promise<runtime.ApiResponse<Array<IssueManagerConfigField>>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getIssueManagerConfigFields."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/issuemanagerfieldmapping/issuemanagerconfigfields`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(IssueManagerConfigFieldFromJSON)
    );
  }

  /**
   * Fetch the list of fields included while issue manager configuration for the given project
   * Issue manager configuration fields
   */
  async getIssueManagerConfigFields(
    requestParameters: GetIssueManagerConfigFieldsRequest
  ): Promise<Array<IssueManagerConfigField>> {
    const response = await this.getIssueManagerConfigFieldsRaw(
      requestParameters
    );
    return await response.value();
  }

  /**
   * Fetches the existing mapping data for the given project
   * Existing mapping
   */
  async getIssueManagerFieldMappingRaw(
    requestParameters: GetIssueManagerFieldMappingRequest
  ): Promise<runtime.ApiResponse<Array<IssueManagerFieldMapping>>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getIssueManagerFieldMapping."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/issuemanagerfieldmapping/mapping`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(IssueManagerFieldMappingFromJSON)
    );
  }

  /**
   * Fetches the existing mapping data for the given project
   * Existing mapping
   */
  async getIssueManagerFieldMapping(
    requestParameters: GetIssueManagerFieldMappingRequest
  ): Promise<Array<IssueManagerFieldMapping>> {
    const response = await this.getIssueManagerFieldMappingRaw(
      requestParameters
    );
    return await response.value();
  }

  /**
   * Posts the mapping data for the given project
   * Posts mapping
   */
  async issueManagerFieldMappingRaw(
    requestParameters: IssueManagerFieldMappingRequest
  ): Promise<runtime.ApiResponse<Array<IssueManagerFieldMapping>>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/issuemanagerfieldmapping/mapping`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: requestParameters.issueManagerFieldMapping.map(
        IssueManagerFieldMappingToJSON
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(IssueManagerFieldMappingFromJSON)
    );
  }

  /**
   * Posts the mapping data for the given project
   * Posts mapping
   */
  async issueManagerFieldMapping(
    requestParameters: IssueManagerFieldMappingRequest
  ): Promise<Array<IssueManagerFieldMapping>> {
    const response = await this.issueManagerFieldMappingRaw(requestParameters);
    return await response.value();
  }
}
