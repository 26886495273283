// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  Company,
  CompanyFromJSON,
  CompanyToJSON,
  CompanyCreatePayload,
  CompanyCreatePayloadFromJSON,
  CompanyCreatePayloadToJSON,
  CompanyOtherInfoPayload,
  CompanyOtherInfoPayloadFromJSON,
  CompanyOtherInfoPayloadToJSON,
  CompanyPlan,
  CompanyPlanFromJSON,
  CompanyPlanToJSON,
  CompanyQATokenBalance,
  CompanyQATokenBalanceFromJSON,
  CompanyQATokenBalanceToJSON,
  CompanyTaxInfo,
  CompanyTaxInfoFromJSON,
  CompanyTaxInfoToJSON,
  Count,
  CountFromJSON,
  CountToJSON,
  ExtendTrialPayload,
  ExtendTrialPayloadFromJSON,
  ExtendTrialPayloadToJSON,
  ForbiddenError,
  ForbiddenErrorFromJSON,
  ForbiddenErrorToJSON,
  GenericActionResult,
  GenericActionResultFromJSON,
  GenericActionResultToJSON,
  InlineResponse200,
  InlineResponse200FromJSON,
  InlineResponse200ToJSON,
  SaasInstanceBillingPayload,
  SaasInstanceBillingPayloadFromJSON,
  SaasInstanceBillingPayloadToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON
} from "../models";

export interface CreateCompanyRequest {
  companyCreatePayload: CompanyCreatePayload;
}

export interface DeleteCompanyRequest {
  id: number;
}

export interface ExportDataRequest {
  company: number;
  _return?: number;
}

export interface ExtendTrialRequest {
  extendTrialPayload?: ExtendTrialPayload;
}

export interface GetCompaniesRequest {
  limit?: number;
  sort?: string;
  start?: number;
  filter?: string;
}

export interface GetCompanyRequest {
  id: number;
  includeStats?: boolean;
}

export interface GetCompanyPlanRequest {
  id: number;
}

export interface GetCompanyTaxInfoRequest {
  company: number;
}

export interface GetQATokenBalanceRequest {
  company: number;
}

export interface UpdateCompanyBillingDetailsRequest {
  id: number;
  saasInstanceBillingPayload?: SaasInstanceBillingPayload;
}

export interface UpdateCompanyOtherInfoRequest {
  companyOtherInfoPayload?: CompanyOtherInfoPayload;
}

/**
 * no description
 */
export class CompaniesApi extends runtime.BaseAPI {
  /**
   * Creates a new company
   * Creates a company
   */
  async createCompanyRaw(
    requestParameters: CreateCompanyRequest
  ): Promise<runtime.ApiResponse<Company>> {
    if (
      requestParameters.companyCreatePayload === null ||
      requestParameters.companyCreatePayload === undefined
    ) {
      throw new runtime.RequiredError(
        "companyCreatePayload",
        "Required parameter requestParameters.companyCreatePayload was null or undefined when calling createCompany."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/companies`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CompanyCreatePayloadToJSON(requestParameters.companyCreatePayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CompanyFromJSON(jsonValue)
    );
  }

  /**
   * Creates a new company
   * Creates a company
   */
  async createCompany(
    requestParameters: CreateCompanyRequest
  ): Promise<Company> {
    const response = await this.createCompanyRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes a specific company
   * Delete company
   */
  async deleteCompanyRaw(
    requestParameters: DeleteCompanyRequest
  ): Promise<runtime.ApiResponse<GenericActionResult>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteCompany."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/companies/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      GenericActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Deletes a specific company
   * Delete company
   */
  async deleteCompany(
    requestParameters: DeleteCompanyRequest
  ): Promise<GenericActionResult> {
    const response = await this.deleteCompanyRaw(requestParameters);
    return await response.value();
  }

  /**
   * Export company data as SQL
   */
  async exportDataRaw(
    requestParameters: ExportDataRequest
  ): Promise<runtime.ApiResponse<InlineResponse200>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling exportData."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    if (requestParameters._return !== undefined) {
      queryParameters["return"] = requestParameters._return;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/companies/exportData`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      InlineResponse200FromJSON(jsonValue)
    );
  }

  /**
   * Export company data as SQL
   */
  async exportData(
    requestParameters: ExportDataRequest
  ): Promise<InlineResponse200> {
    const response = await this.exportDataRaw(requestParameters);
    return await response.value();
  }

  /**
   * Company to extend trial for
   * Extend trial for a company
   */
  async extendTrialRaw(
    requestParameters: ExtendTrialRequest
  ): Promise<runtime.ApiResponse<GenericActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/companies/extendTrial`,
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: ExtendTrialPayloadToJSON(requestParameters.extendTrialPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      GenericActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Company to extend trial for
   * Extend trial for a company
   */
  async extendTrial(
    requestParameters: ExtendTrialRequest
  ): Promise<GenericActionResult> {
    const response = await this.extendTrialRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get list of companies
   * Get list of companies
   */
  async getCompaniesRaw(
    requestParameters: GetCompaniesRequest
  ): Promise<runtime.ApiResponse<Array<Company>>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/companies`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(CompanyFromJSON)
    );
  }

  /**
   * Get list of companies
   * Get list of companies
   */
  async getCompanies(
    requestParameters: GetCompaniesRequest
  ): Promise<Array<Company>> {
    const response = await this.getCompaniesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get details of a specific company
   * Get company
   */
  async getCompanyRaw(
    requestParameters: GetCompanyRequest
  ): Promise<runtime.ApiResponse<Company>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getCompany."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.includeStats !== undefined) {
      queryParameters["includeStats"] = requestParameters.includeStats;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/companies/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CompanyFromJSON(jsonValue)
    );
  }

  /**
   * Get details of a specific company
   * Get company
   */
  async getCompany(requestParameters: GetCompanyRequest): Promise<Company> {
    const response = await this.getCompanyRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get count of companies
   * Get count of companies
   */
  async getCompanyCountRaw(): Promise<runtime.ApiResponse<Count>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/companies/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get count of companies
   * Get count of companies
   */
  async getCompanyCount(): Promise<Count> {
    const response = await this.getCompanyCountRaw();
    return await response.value();
  }

  /**
   * Get details of plan opted by a company
   * Get company's plan
   */
  async getCompanyPlanRaw(
    requestParameters: GetCompanyPlanRequest
  ): Promise<runtime.ApiResponse<CompanyPlan>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getCompanyPlan."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/companies/getCompanyPlan/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CompanyPlanFromJSON(jsonValue)
    );
  }

  /**
   * Get details of plan opted by a company
   * Get company's plan
   */
  async getCompanyPlan(
    requestParameters: GetCompanyPlanRequest
  ): Promise<CompanyPlan> {
    const response = await this.getCompanyPlanRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get company specific tax exempt information
   * Get company specific tax exempt information
   */
  async getCompanyTaxInfoRaw(
    requestParameters: GetCompanyTaxInfoRequest
  ): Promise<runtime.ApiResponse<CompanyTaxInfo>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getCompanyTaxInfo."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/companies/getCompanyTaxInfo`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CompanyTaxInfoFromJSON(jsonValue)
    );
  }

  /**
   * Get company specific tax exempt information
   * Get company specific tax exempt information
   */
  async getCompanyTaxInfo(
    requestParameters: GetCompanyTaxInfoRequest
  ): Promise<CompanyTaxInfo> {
    const response = await this.getCompanyTaxInfoRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get company QA token balance
   * Get company QA token balance
   */
  async getQATokenBalanceRaw(
    requestParameters: GetQATokenBalanceRequest
  ): Promise<runtime.ApiResponse<CompanyQATokenBalance>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getQATokenBalance."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/companies/getQATokenBalance`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CompanyQATokenBalanceFromJSON(jsonValue)
    );
  }

  /**
   * Get company QA token balance
   * Get company QA token balance
   */
  async getQATokenBalance(
    requestParameters: GetQATokenBalanceRequest
  ): Promise<CompanyQATokenBalance> {
    const response = await this.getQATokenBalanceRaw(requestParameters);
    return await response.value();
  }

  /**
   * Updates details of a specific company
   * Update a company
   */
  async updateCompanyBillingDetailsRaw(
    requestParameters: UpdateCompanyBillingDetailsRequest
  ): Promise<runtime.ApiResponse<Company>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateCompanyBillingDetails."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/companies/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: SaasInstanceBillingPayloadToJSON(
        requestParameters.saasInstanceBillingPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CompanyFromJSON(jsonValue)
    );
  }

  /**
   * Updates details of a specific company
   * Update a company
   */
  async updateCompanyBillingDetails(
    requestParameters: UpdateCompanyBillingDetailsRequest
  ): Promise<Company> {
    const response = await this.updateCompanyBillingDetailsRaw(
      requestParameters
    );
    return await response.value();
  }

  /**
   * Other information taken at the time of company setup
   * Update company other information
   */
  async updateCompanyOtherInfoRaw(
    requestParameters: UpdateCompanyOtherInfoRequest
  ): Promise<runtime.ApiResponse<Company>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/companies/updateInfo`,
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: CompanyOtherInfoPayloadToJSON(
        requestParameters.companyOtherInfoPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CompanyFromJSON(jsonValue)
    );
  }

  /**
   * Other information taken at the time of company setup
   * Update company other information
   */
  async updateCompanyOtherInfo(
    requestParameters: UpdateCompanyOtherInfoRequest
  ): Promise<Company> {
    const response = await this.updateCompanyOtherInfoRaw(requestParameters);
    return await response.value();
  }
}
