// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from "../runtime";
import {
  LoggedInUserUser,
  LoggedInUserUserFromJSON,
  LoggedInUserUserToJSON
} from "./";

/**
 *
 * @export
 * @interface LoggedInUser
 */
export interface LoggedInUser {
  /**
   *
   * @type {string}
   * @memberof LoggedInUser
   */
  jwt: string;
  /**
   *
   * @type {string}
   * @memberof LoggedInUser
   */
  mfacode?: string;
  /**
   *
   * @type {LoggedInUserUser}
   * @memberof LoggedInUser
   */
  user: LoggedInUserUser;
}

export function LoggedInUserFromJSON(json: any): LoggedInUser {
  return {
    jwt: json["jwt"],
    mfacode: !exists(json, "mfacode") ? undefined : json["mfacode"],
    user: LoggedInUserUserFromJSON(json["user"])
  };
}

export function LoggedInUserToJSON(value?: LoggedInUser): any {
  if (value === undefined) {
    return undefined;
  }
  return {
    jwt: value.jwt,
    mfacode: value.mfacode,
    user: LoggedInUserUserToJSON(value.user)
  };
}
