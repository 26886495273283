// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  Count,
  CountFromJSON,
  CountToJSON,
  DefaultResponseError,
  DefaultResponseErrorFromJSON,
  DefaultResponseErrorToJSON,
  ExecutionComment,
  ExecutionCommentFromJSON,
  ExecutionCommentToJSON,
  ExecutionCommentPayload,
  ExecutionCommentPayloadFromJSON,
  ExecutionCommentPayloadToJSON,
  ForbiddenError,
  ForbiddenErrorFromJSON,
  ForbiddenErrorToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON
} from "../models";

export interface AddExecutionCommentRequest {
  executionCommentPayload?: ExecutionCommentPayload;
}

export interface DeleteExecutionCommentRequest {
  id: number;
  project: number;
}

export interface GetExecutionCommentRequest {
  id: number;
  project: number;
}

export interface GetExecutionCommentsRequest {
  executedTestCase: number;
  project: number;
  limit?: number;
  start?: number;
  sort?: string;
  filter?: string;
}

export interface GetExecutionCommentsCountRequest {
  project: number;
  executedTestCase: number;
}

export interface UpdateExecutionCommentRequest {
  id: number;
  executionCommentPayload?: ExecutionCommentPayload;
}

/**
 * no description
 */
export class ExecutionCommentsApi extends runtime.BaseAPI {
  /**
   * Adds a new execution comment
   * Adds a new execution comment
   */
  async addExecutionCommentRaw(
    requestParameters: AddExecutionCommentRequest
  ): Promise<runtime.ApiResponse<ExecutionComment>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/executioncomments`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ExecutionCommentPayloadToJSON(
        requestParameters.executionCommentPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ExecutionCommentFromJSON(jsonValue)
    );
  }

  /**
   * Adds a new execution comment
   * Adds a new execution comment
   */
  async addExecutionComment(
    requestParameters: AddExecutionCommentRequest
  ): Promise<ExecutionComment> {
    const response = await this.addExecutionCommentRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes a specific execution comment
   * Deletes given execution comment
   */
  async deleteExecutionCommentRaw(
    requestParameters: DeleteExecutionCommentRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteExecutionComment."
      );
    }

    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling deleteExecutionComment."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/executioncomments/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deletes a specific execution comment
   * Deletes given execution comment
   */
  async deleteExecutionComment(
    requestParameters: DeleteExecutionCommentRequest
  ): Promise<any> {
    const response = await this.deleteExecutionCommentRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get execution comment
   * Get execution comment
   */
  async getExecutionCommentRaw(
    requestParameters: GetExecutionCommentRequest
  ): Promise<runtime.ApiResponse<ExecutionComment>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getExecutionComment."
      );
    }

    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getExecutionComment."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/executioncomments/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ExecutionCommentFromJSON(jsonValue)
    );
  }

  /**
   * Get execution comment
   * Get execution comment
   */
  async getExecutionComment(
    requestParameters: GetExecutionCommentRequest
  ): Promise<ExecutionComment> {
    const response = await this.getExecutionCommentRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get Execution comments
   * Get Execution comments
   */
  async getExecutionCommentsRaw(
    requestParameters: GetExecutionCommentsRequest
  ): Promise<runtime.ApiResponse<Array<ExecutionComment>>> {
    if (
      requestParameters.executedTestCase === null ||
      requestParameters.executedTestCase === undefined
    ) {
      throw new runtime.RequiredError(
        "executedTestCase",
        "Required parameter requestParameters.executedTestCase was null or undefined when calling getExecutionComments."
      );
    }

    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getExecutionComments."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.executedTestCase !== undefined) {
      queryParameters["executed_test_case"] =
        requestParameters.executedTestCase;
    }

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/executioncomments`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(ExecutionCommentFromJSON)
    );
  }

  /**
   * Get Execution comments
   * Get Execution comments
   */
  async getExecutionComments(
    requestParameters: GetExecutionCommentsRequest
  ): Promise<Array<ExecutionComment>> {
    const response = await this.getExecutionCommentsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get execution comments' count
   * Get count of execution comments
   */
  async getExecutionCommentsCountRaw(
    requestParameters: GetExecutionCommentsCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getExecutionCommentsCount."
      );
    }

    if (
      requestParameters.executedTestCase === null ||
      requestParameters.executedTestCase === undefined
    ) {
      throw new runtime.RequiredError(
        "executedTestCase",
        "Required parameter requestParameters.executedTestCase was null or undefined when calling getExecutionCommentsCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.executedTestCase !== undefined) {
      queryParameters["executed_test_case"] =
        requestParameters.executedTestCase;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/executioncomments/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get execution comments' count
   * Get count of execution comments
   */
  async getExecutionCommentsCount(
    requestParameters: GetExecutionCommentsCountRequest
  ): Promise<Count> {
    const response = await this.getExecutionCommentsCountRaw(requestParameters);
    return await response.value();
  }

  /**
   * Execution to update
   * Updates a execution comment
   */
  async updateExecutionCommentRaw(
    requestParameters: UpdateExecutionCommentRequest
  ): Promise<runtime.ApiResponse<ExecutionComment>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateExecutionComment."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/executioncomments/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: ExecutionCommentPayloadToJSON(
        requestParameters.executionCommentPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ExecutionCommentFromJSON(jsonValue)
    );
  }

  /**
   * Execution to update
   * Updates a execution comment
   */
  async updateExecutionComment(
    requestParameters: UpdateExecutionCommentRequest
  ): Promise<ExecutionComment> {
    const response = await this.updateExecutionCommentRaw(requestParameters);
    return await response.value();
  }
}
