// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  Count,
  CountFromJSON,
  CountToJSON,
  GenericActionResult,
  GenericActionResultFromJSON,
  GenericActionResultToJSON,
  SavedFilter,
  SavedFilterFromJSON,
  SavedFilterToJSON,
  SavedFilterPayload,
  SavedFilterPayloadFromJSON,
  SavedFilterPayloadToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON
} from "../models";

export interface CreateSavedFilterRequest {
  savedFilterPayload?: SavedFilterPayload;
}

export interface DeleteSavedFilterRequest {
  id: number;
}

export interface GetSavedFilterRequest {
  id: number;
}

export interface GetSavedFilterCountRequest {
  project: number;
}

export interface GetSavedFiltersRequest {
  project: number;
  limit?: number;
  sort?: string;
  start?: number;
  filter?: string;
}

export interface UpdateSavedFilterRequest {
  id: number;
  savedFilterPayload?: SavedFilterPayload;
}

/**
 * no description
 */
export class SavedFiltersApi extends runtime.BaseAPI {
  /**
   * Saved Filter to add
   * Create a new saved filter
   */
  async createSavedFilterRaw(
    requestParameters: CreateSavedFilterRequest
  ): Promise<runtime.ApiResponse<SavedFilter>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/savedfilters`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: SavedFilterPayloadToJSON(requestParameters.savedFilterPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      SavedFilterFromJSON(jsonValue)
    );
  }

  /**
   * Saved Filter to add
   * Create a new saved filter
   */
  async createSavedFilter(
    requestParameters: CreateSavedFilterRequest
  ): Promise<SavedFilter> {
    const response = await this.createSavedFilterRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes a specific saved filter
   * Delete saved filter
   */
  async deleteSavedFilterRaw(
    requestParameters: DeleteSavedFilterRequest
  ): Promise<runtime.ApiResponse<GenericActionResult>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteSavedFilter."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/savedfilters/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      GenericActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Deletes a specific saved filter
   * Delete saved filter
   */
  async deleteSavedFilter(
    requestParameters: DeleteSavedFilterRequest
  ): Promise<GenericActionResult> {
    const response = await this.deleteSavedFilterRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get saved filter
   * Get saved filter
   */
  async getSavedFilterRaw(
    requestParameters: GetSavedFilterRequest
  ): Promise<runtime.ApiResponse<SavedFilter>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getSavedFilter."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/savedfilters/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      SavedFilterFromJSON(jsonValue)
    );
  }

  /**
   * Get saved filter
   * Get saved filter
   */
  async getSavedFilter(
    requestParameters: GetSavedFilterRequest
  ): Promise<SavedFilter> {
    const response = await this.getSavedFilterRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get saved filters' count for a specific project
   * Get saved filters' count
   */
  async getSavedFilterCountRaw(
    requestParameters: GetSavedFilterCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getSavedFilterCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/savedfilters/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get saved filters' count for a specific project
   * Get saved filters' count
   */
  async getSavedFilterCount(
    requestParameters: GetSavedFilterCountRequest
  ): Promise<Count> {
    const response = await this.getSavedFilterCountRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get list of saved filters for a specific project
   * Get saved filters
   */
  async getSavedFiltersRaw(
    requestParameters: GetSavedFiltersRequest
  ): Promise<runtime.ApiResponse<Array<SavedFilter>>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getSavedFilters."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/savedfilters`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(SavedFilterFromJSON)
    );
  }

  /**
   * Get list of saved filters for a specific project
   * Get saved filters
   */
  async getSavedFilters(
    requestParameters: GetSavedFiltersRequest
  ): Promise<Array<SavedFilter>> {
    const response = await this.getSavedFiltersRaw(requestParameters);
    return await response.value();
  }

  /**
   * Saved Filter to update
   * Updates a saved filter
   */
  async updateSavedFilterRaw(
    requestParameters: UpdateSavedFilterRequest
  ): Promise<runtime.ApiResponse<SavedFilter>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateSavedFilter."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/savedfilters/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: SavedFilterPayloadToJSON(requestParameters.savedFilterPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      SavedFilterFromJSON(jsonValue)
    );
  }

  /**
   * Saved Filter to update
   * Updates a saved filter
   */
  async updateSavedFilter(
    requestParameters: UpdateSavedFilterRequest
  ): Promise<SavedFilter> {
    const response = await this.updateSavedFilterRaw(requestParameters);
    return await response.value();
  }
}
