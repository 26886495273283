// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from "../runtime";
/**
 *
 * @export
 * @interface ExportTestCasesPayload
 */
export interface ExportTestCasesPayload {
  /**
   * Project ID
   * @type {number}
   * @memberof ExportTestCasesPayload
   */
  project: number;
  /**
   * Export data as CSV/EXCEL
   * @type {string}
   * @memberof ExportTestCasesPayload
   */
  exportAs: ExportTestCasesPayloadExportAsEnum;
  /**
   * Stringified filter object (Ag-grid filters)
   * @type {string}
   * @memberof ExportTestCasesPayload
   */
  filterModel?: string;
  /**
   * Stringified array of sort model objects (Ag-grid sort models)
   * @type {string}
   * @memberof ExportTestCasesPayload
   */
  sortModel?: string;
  /**
   * Export selected or all test cases. If none is selected and filter is present then all filtered test cases will be considered.
   * @type {string}
   * @memberof ExportTestCasesPayload
   */
  criteria: ExportTestCasesPayloadCriteriaEnum;
  /**
   * Selected test case IDs - will be considered only if criteria is selected.
   * @type {Array<number>}
   * @memberof ExportTestCasesPayload
   */
  testCases?: Array<number>;
  /**
   * Fields provided here will be considered for export. If none then all default fields. <br /><br />You can specify the custom fields using the template \"cf_<customFieldId>\" so if you want data for custom field whose Id is 123, along with test case title, priority and suite then the fields parameter would be like - <br />{<br />&emsp; \"title\",<br />&emsp; \"priority\",<br />&emsp; \"suite\",<br />&emsp; \"cf_123\" <br />]
   * @type {Array<string>}
   * @memberof ExportTestCasesPayload
   */
  fields: Array<string>;
  /**
   * If test cases related to a particular test plan has to be exported.
   * @type {number}
   * @memberof ExportTestCasesPayload
   */
  testPlan?: number;
  /**
   * Will be considered only if test plan is provided. Selected test plan configurations IDs - If none then all.
   * @type {Array<number>}
   * @memberof ExportTestCasesPayload
   */
  configs?: Array<number>;
  /**
   * Will be considered only if test plan is provided. Selected run otherwise latest run.
   * @type {number}
   * @memberof ExportTestCasesPayload
   */
  run?: number;
  /**
   * Strip HTML tags from text fields
   * @type {boolean}
   * @memberof ExportTestCasesPayload
   */
  removeTags?: boolean;
}

export function ExportTestCasesPayloadFromJSON(
  json: any
): ExportTestCasesPayload {
  return {
    project: json["project"],
    exportAs: json["export_as"],
    filterModel: !exists(json, "filter_model")
      ? undefined
      : json["filter_model"],
    sortModel: !exists(json, "sort_model") ? undefined : json["sort_model"],
    criteria: json["criteria"],
    testCases: !exists(json, "testCases") ? undefined : json["testCases"],
    fields: json["fields"],
    testPlan: !exists(json, "testPlan") ? undefined : json["testPlan"],
    configs: !exists(json, "configs") ? undefined : json["configs"],
    run: !exists(json, "run") ? undefined : json["run"],
    removeTags: !exists(json, "remove_tags") ? undefined : json["remove_tags"]
  };
}

export function ExportTestCasesPayloadToJSON(
  value?: ExportTestCasesPayload
): any {
  if (value === undefined) {
    return undefined;
  }
  return {
    project: value.project,
    export_as: value.exportAs,
    filter_model: value.filterModel,
    sort_model: value.sortModel,
    criteria: value.criteria,
    testCases: value.testCases,
    fields: value.fields,
    testPlan: value.testPlan,
    configs: value.configs,
    run: value.run,
    remove_tags: value.removeTags
  };
}

/**
 * @export
 * @enum {string}
 */
export enum ExportTestCasesPayloadExportAsEnum {
  Csv = "csv",
  Excel = "excel"
}
/**
 * @export
 * @enum {string}
 */
export enum ExportTestCasesPayloadCriteriaEnum {
  All = "all",
  Selected = "selected"
}
