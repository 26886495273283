// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  BulkActionResult,
  BulkActionResultFromJSON,
  BulkActionResultToJSON,
  Count,
  CountFromJSON,
  CountToJSON,
  DefaultResponseError,
  DefaultResponseErrorFromJSON,
  DefaultResponseErrorToJSON,
  ExecutedTestCase,
  ExecutedTestCaseFromJSON,
  ExecutedTestCaseToJSON,
  ExecutedTestCaseAssignPayload,
  ExecutedTestCaseAssignPayloadFromJSON,
  ExecutedTestCaseAssignPayloadToJSON,
  ExecutedTestCaseBulkAddPayload,
  ExecutedTestCaseBulkAddPayloadFromJSON,
  ExecutedTestCaseBulkAddPayloadToJSON,
  ExecutedTestCasePayload,
  ExecutedTestCasePayloadFromJSON,
  ExecutedTestCasePayloadToJSON,
  ExecutedTestCaseValidatePayload,
  ExecutedTestCaseValidatePayloadFromJSON,
  ExecutedTestCaseValidatePayloadToJSON,
  ForbiddenError,
  ForbiddenErrorFromJSON,
  ForbiddenErrorToJSON,
  GenericActionResult,
  GenericActionResultFromJSON,
  GenericActionResultToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON,
  UpdateTimeTakenPayload,
  UpdateTimeTakenPayloadFromJSON,
  UpdateTimeTakenPayloadToJSON
} from "../models";

export interface AssignExecutedTestCaseRequest {
  executedTestCaseAssignPayload?: ExecutedTestCaseAssignPayload;
}

export interface BulkAddExecutedTestCasesRequest {
  executedTestCaseBulkAddPayload?: ExecutedTestCaseBulkAddPayload;
}

export interface BulkAddExecutedTestCasesOLDRequest {
  id: number;
  executedTestCaseBulkAddPayload?: ExecutedTestCaseBulkAddPayload;
}

export interface CreateExecutedTestCaseRequest {
  executedTestCasePayload?: ExecutedTestCasePayload;
}

export interface DeleteExecutedTestCaseRequest {
  id: number;
}

export interface GetExecutedTestCaseRequest {
  id: number;
}

export interface GetExecutedTestCaseCountRequest {
  project: number;
  filter: string;
}

export interface GetExecutedTestCasesRequest {
  project: number;
  limit?: number;
  start?: number;
  sort?: string;
  filter?: string;
}

export interface UpdateExecutedTestCaseRequest {
  id: number;
  executedTestCasePayload?: ExecutedTestCasePayload;
}

export interface UpdateTimeTakenRequest {
  id: number;
  updateTimeTakenPayload?: UpdateTimeTakenPayload;
}

export interface ValidateExecutedTestCasesRequest {
  executedTestCaseValidatePayload?: ExecutedTestCaseValidatePayload;
}

/**
 * no description
 */
export class ExecutedTestCasesApi extends runtime.BaseAPI {
  /**
   * Executed Test Case assign payload
   * Assign a executed test case
   */
  async assignExecutedTestCaseRaw(
    requestParameters: AssignExecutedTestCaseRequest
  ): Promise<runtime.ApiResponse<ExecutedTestCase>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/executedtestcases/assign`,
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: ExecutedTestCaseAssignPayloadToJSON(
        requestParameters.executedTestCaseAssignPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ExecutedTestCaseFromJSON(jsonValue)
    );
  }

  /**
   * Executed Test Case assign payload
   * Assign a executed test case
   */
  async assignExecutedTestCase(
    requestParameters: AssignExecutedTestCaseRequest
  ): Promise<ExecutedTestCase> {
    const response = await this.assignExecutedTestCaseRaw(requestParameters);
    return await response.value();
  }

  /**
   * Add (executed) test cases to testplan in bulk
   * Add test case to testplan
   */
  async bulkAddExecutedTestCasesRaw(
    requestParameters: BulkAddExecutedTestCasesRequest
  ): Promise<runtime.ApiResponse<any>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplans/executedtestcaseBulkAdd`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ExecutedTestCaseBulkAddPayloadToJSON(
        requestParameters.executedTestCaseBulkAddPayload
      )
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Add (executed) test cases to testplan in bulk
   * Add test case to testplan
   */
  async bulkAddExecutedTestCases(
    requestParameters: BulkAddExecutedTestCasesRequest
  ): Promise<any> {
    const response = await this.bulkAddExecutedTestCasesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Add executed test case for testplan
   * Add executed test case for testplan
   */
  async bulkAddExecutedTestCasesOLDRaw(
    requestParameters: BulkAddExecutedTestCasesOLDRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling bulkAddExecutedTestCasesOLD."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/executedtestcases/{id}/bulkAdd`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: ExecutedTestCaseBulkAddPayloadToJSON(
        requestParameters.executedTestCaseBulkAddPayload
      )
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Add executed test case for testplan
   * Add executed test case for testplan
   */
  async bulkAddExecutedTestCasesOLD(
    requestParameters: BulkAddExecutedTestCasesOLDRequest
  ): Promise<any> {
    const response = await this.bulkAddExecutedTestCasesOLDRaw(
      requestParameters
    );
    return await response.value();
  }

  /**
   * Executed Test Case to add
   * Create a new executed test case
   */
  async createExecutedTestCaseRaw(
    requestParameters: CreateExecutedTestCaseRequest
  ): Promise<runtime.ApiResponse<ExecutedTestCase>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/executedtestcases`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ExecutedTestCasePayloadToJSON(
        requestParameters.executedTestCasePayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ExecutedTestCaseFromJSON(jsonValue)
    );
  }

  /**
   * Executed Test Case to add
   * Create a new executed test case
   */
  async createExecutedTestCase(
    requestParameters: CreateExecutedTestCaseRequest
  ): Promise<ExecutedTestCase> {
    const response = await this.createExecutedTestCaseRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes specific executed test case
   * Deletes executed test case
   */
  async deleteExecutedTestCaseRaw(
    requestParameters: DeleteExecutedTestCaseRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteExecutedTestCase."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/executedtestcases/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deletes specific executed test case
   * Deletes executed test case
   */
  async deleteExecutedTestCase(
    requestParameters: DeleteExecutedTestCaseRequest
  ): Promise<any> {
    const response = await this.deleteExecutedTestCaseRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get details of a specific executed test case
   * Get executed test case
   */
  async getExecutedTestCaseRaw(
    requestParameters: GetExecutedTestCaseRequest
  ): Promise<runtime.ApiResponse<ExecutedTestCase>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getExecutedTestCase."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/executedtestcases/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ExecutedTestCaseFromJSON(jsonValue)
    );
  }

  /**
   * Get details of a specific executed test case
   * Get executed test case
   */
  async getExecutedTestCase(
    requestParameters: GetExecutedTestCaseRequest
  ): Promise<ExecutedTestCase> {
    const response = await this.getExecutedTestCaseRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get count of executed test cases
   * Get count of executed test cases
   */
  async getExecutedTestCaseCountRaw(
    requestParameters: GetExecutedTestCaseCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getExecutedTestCaseCount."
      );
    }

    if (
      requestParameters.filter === null ||
      requestParameters.filter === undefined
    ) {
      throw new runtime.RequiredError(
        "filter",
        "Required parameter requestParameters.filter was null or undefined when calling getExecutedTestCaseCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/executedtestcases/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get count of executed test cases
   * Get count of executed test cases
   */
  async getExecutedTestCaseCount(
    requestParameters: GetExecutedTestCaseCountRequest
  ): Promise<Count> {
    const response = await this.getExecutedTestCaseCountRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get list of executed test cases
   * Get list of executed test cases
   */
  async getExecutedTestCasesRaw(
    requestParameters: GetExecutedTestCasesRequest
  ): Promise<runtime.ApiResponse<Array<ExecutedTestCase>>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getExecutedTestCases."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/executedtestcases`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(ExecutedTestCaseFromJSON)
    );
  }

  /**
   * Get list of executed test cases
   * Get list of executed test cases
   */
  async getExecutedTestCases(
    requestParameters: GetExecutedTestCasesRequest
  ): Promise<Array<ExecutedTestCase>> {
    const response = await this.getExecutedTestCasesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Updates an executed test case
   * Updates an executed test case
   */
  async updateExecutedTestCaseRaw(
    requestParameters: UpdateExecutedTestCaseRequest
  ): Promise<runtime.ApiResponse<ExecutedTestCase>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateExecutedTestCase."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/executedtestcases/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: ExecutedTestCasePayloadToJSON(
        requestParameters.executedTestCasePayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ExecutedTestCaseFromJSON(jsonValue)
    );
  }

  /**
   * Updates an executed test case
   * Updates an executed test case
   */
  async updateExecutedTestCase(
    requestParameters: UpdateExecutedTestCaseRequest
  ): Promise<ExecutedTestCase> {
    const response = await this.updateExecutedTestCaseRaw(requestParameters);
    return await response.value();
  }

  /**
   * Time taken to be increamented by
   * Update executed test case time taken
   */
  async updateTimeTakenRaw(
    requestParameters: UpdateTimeTakenRequest
  ): Promise<runtime.ApiResponse<GenericActionResult>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateTimeTaken."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/executedtestcases/{id}/updateTimeTaken`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: UpdateTimeTakenPayloadToJSON(
        requestParameters.updateTimeTakenPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      GenericActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Time taken to be increamented by
   * Update executed test case time taken
   */
  async updateTimeTaken(
    requestParameters: UpdateTimeTakenRequest
  ): Promise<GenericActionResult> {
    const response = await this.updateTimeTakenRaw(requestParameters);
    return await response.value();
  }

  /**
   * Executed Test Case validate payload
   * Validate executed test cases
   */
  async validateExecutedTestCasesRaw(
    requestParameters: ValidateExecutedTestCasesRequest
  ): Promise<runtime.ApiResponse<BulkActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/executedtestcases/validate`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ExecutedTestCaseValidatePayloadToJSON(
        requestParameters.executedTestCaseValidatePayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      BulkActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Executed Test Case validate payload
   * Validate executed test cases
   */
  async validateExecutedTestCases(
    requestParameters: ValidateExecutedTestCasesRequest
  ): Promise<BulkActionResult> {
    const response = await this.validateExecutedTestCasesRaw(requestParameters);
    return await response.value();
  }
}
