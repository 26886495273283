// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  Count,
  CountFromJSON,
  CountToJSON,
  DefaultResponseError,
  DefaultResponseErrorFromJSON,
  DefaultResponseErrorToJSON,
  FetchIdPayload,
  FetchIdPayloadFromJSON,
  FetchIdPayloadToJSON,
  FetchIdResult,
  FetchIdResultFromJSON,
  FetchIdResultToJSON,
  ForbiddenError,
  ForbiddenErrorFromJSON,
  ForbiddenErrorToJSON,
  GenericActionResult,
  GenericActionResultFromJSON,
  GenericActionResultToJSON,
  Requirement,
  RequirementFromJSON,
  RequirementToJSON,
  RequirementPayload,
  RequirementPayloadFromJSON,
  RequirementPayloadToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON,
  TraceabilityMatrixPayload,
  TraceabilityMatrixPayloadFromJSON,
  TraceabilityMatrixPayloadToJSON,
  TraceabilityMatrixResult,
  TraceabilityMatrixResultFromJSON,
  TraceabilityMatrixResultToJSON
} from "../models";

export interface CreateRequirementRequest {
  requirementPayload?: RequirementPayload;
}

export interface DeleteRequirementRequest {
  id: number;
}

export interface GetProjectRequirementsCountRequest {
  project: number;
  filter?: string;
}

export interface GetRequirementRequest {
  id: number;
}

export interface GetRequirementIdsRequest {
  fetchIdPayload?: FetchIdPayload;
}

export interface GetRequirementsRequest {
  project: number;
  limit?: number;
  start?: number;
  sort?: string;
  filter?: string;
}

export interface GetTraceabilityMatrixRequest {
  traceabilityMatrixPayload?: TraceabilityMatrixPayload;
}

export interface UpdateRequirementRequest {
  id: number;
  requirementPayload?: RequirementPayload;
}

/**
 * no description
 */
export class RequirementsApi extends runtime.BaseAPI {
  /**
   * Requirement to add
   * Create a new requirement
   */
  async createRequirementRaw(
    requestParameters: CreateRequirementRequest
  ): Promise<runtime.ApiResponse<Requirement>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/requirements`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: RequirementPayloadToJSON(requestParameters.requirementPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      RequirementFromJSON(jsonValue)
    );
  }

  /**
   * Requirement to add
   * Create a new requirement
   */
  async createRequirement(
    requestParameters: CreateRequirementRequest
  ): Promise<Requirement> {
    const response = await this.createRequirementRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes a requirement
   * Deletes given requirement
   */
  async deleteRequirementRaw(
    requestParameters: DeleteRequirementRequest
  ): Promise<runtime.ApiResponse<GenericActionResult>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteRequirement."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/requirements/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      GenericActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Deletes a requirement
   * Deletes given requirement
   */
  async deleteRequirement(
    requestParameters: DeleteRequirementRequest
  ): Promise<GenericActionResult> {
    const response = await this.deleteRequirementRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get project's linked requirement count
   * Get project's linked requirement count
   */
  async getProjectRequirementsCountRaw(
    requestParameters: GetProjectRequirementsCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getProjectRequirementsCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/requirements/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get project's linked requirement count
   * Get project's linked requirement count
   */
  async getProjectRequirementsCount(
    requestParameters: GetProjectRequirementsCountRequest
  ): Promise<Count> {
    const response = await this.getProjectRequirementsCountRaw(
      requestParameters
    );
    return await response.value();
  }

  /**
   * Fetches the details of a requirement
   * Get requirement
   */
  async getRequirementRaw(
    requestParameters: GetRequirementRequest
  ): Promise<runtime.ApiResponse<Requirement>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getRequirement."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/requirements/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      RequirementFromJSON(jsonValue)
    );
  }

  /**
   * Fetches the details of a requirement
   * Get requirement
   */
  async getRequirement(
    requestParameters: GetRequirementRequest
  ): Promise<Requirement> {
    const response = await this.getRequirementRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get id(s) of test case(s) that match the filter criteria
   * Get matching test cases' id
   */
  async getRequirementIdsRaw(
    requestParameters: GetRequirementIdsRequest
  ): Promise<runtime.ApiResponse<FetchIdResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/requirements/fetchIds`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: FetchIdPayloadToJSON(requestParameters.fetchIdPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      FetchIdResultFromJSON(jsonValue)
    );
  }

  /**
   * Get id(s) of test case(s) that match the filter criteria
   * Get matching test cases' id
   */
  async getRequirementIds(
    requestParameters: GetRequirementIdsRequest
  ): Promise<FetchIdResult> {
    const response = await this.getRequirementIdsRaw(requestParameters);
    return await response.value();
  }

  /**
   * na
   * Get list of requirements
   */
  async getRequirementsRaw(
    requestParameters: GetRequirementsRequest
  ): Promise<runtime.ApiResponse<Array<Requirement>>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getRequirements."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/requirements`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(RequirementFromJSON)
    );
  }

  /**
   * na
   * Get list of requirements
   */
  async getRequirements(
    requestParameters: GetRequirementsRequest
  ): Promise<Array<Requirement>> {
    const response = await this.getRequirementsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get TraceabilityMatrix
   * Get Traceability Matrix
   */
  async getTraceabilityMatrixRaw(
    requestParameters: GetTraceabilityMatrixRequest
  ): Promise<runtime.ApiResponse<TraceabilityMatrixResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/requirements/traceability_matrix`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TraceabilityMatrixPayloadToJSON(
        requestParameters.traceabilityMatrixPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TraceabilityMatrixResultFromJSON(jsonValue)
    );
  }

  /**
   * Get TraceabilityMatrix
   * Get Traceability Matrix
   */
  async getTraceabilityMatrix(
    requestParameters: GetTraceabilityMatrixRequest
  ): Promise<TraceabilityMatrixResult> {
    const response = await this.getTraceabilityMatrixRaw(requestParameters);
    return await response.value();
  }

  /**
   * Updates a requirement
   * Updates a requirement
   */
  async updateRequirementRaw(
    requestParameters: UpdateRequirementRequest
  ): Promise<runtime.ApiResponse<Requirement>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateRequirement."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/requirements/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: RequirementPayloadToJSON(requestParameters.requirementPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      RequirementFromJSON(jsonValue)
    );
  }

  /**
   * Updates a requirement
   * Updates a requirement
   */
  async updateRequirement(
    requestParameters: UpdateRequirementRequest
  ): Promise<Requirement> {
    const response = await this.updateRequirementRaw(requestParameters);
    return await response.value();
  }
}
