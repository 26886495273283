// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  Count,
  CountFromJSON,
  CountToJSON,
  GenericActionResult,
  GenericActionResultFromJSON,
  GenericActionResultToJSON,
  IssueManagerLinkPayload,
  IssueManagerLinkPayloadFromJSON,
  IssueManagerLinkPayloadToJSON,
  IssueManagerProfile,
  IssueManagerProfileFromJSON,
  IssueManagerProfileToJSON,
  IssueManagerProfilePayload,
  IssueManagerProfilePayloadFromJSON,
  IssueManagerProfilePayloadToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON
} from "../models";

export interface CreateIssueManagerProfileRequest {
  issueManagerProfilePayload?: IssueManagerProfilePayload;
}

export interface DeleteIssueManagerProfileRequest {
  id: number;
}

export interface GetIssueManagerProfileRequest {
  id: number;
}

export interface GetIssueManagerProfileCountRequest {
  project: number;
  filter?: string;
}

export interface GetIssueManagerProfilesRequest {
  project?: number;
  limit?: number;
  sort?: string;
  start?: number;
  filter?: string;
}

export interface LinkIssueManagerRequest {
  issueManagerLinkPayload?: IssueManagerLinkPayload;
}

export interface UpdateIssueManagerProfileRequest {
  id: number;
  issueManagerProfilePayload?: IssueManagerProfilePayload;
}

/**
 * no description
 */
export class IssueManagerProfilesApi extends runtime.BaseAPI {
  /**
   * Issue Manager Profile to add
   * Create a new issue manager profile
   */
  async createIssueManagerProfileRaw(
    requestParameters: CreateIssueManagerProfileRequest
  ): Promise<runtime.ApiResponse<IssueManagerProfile>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/issuemanagerprofiles`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: IssueManagerProfilePayloadToJSON(
        requestParameters.issueManagerProfilePayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      IssueManagerProfileFromJSON(jsonValue)
    );
  }

  /**
   * Issue Manager Profile to add
   * Create a new issue manager profile
   */
  async createIssueManagerProfile(
    requestParameters: CreateIssueManagerProfileRequest
  ): Promise<IssueManagerProfile> {
    const response = await this.createIssueManagerProfileRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes specific issue manager profile
   * Delete issue manager profile
   */
  async deleteIssueManagerProfileRaw(
    requestParameters: DeleteIssueManagerProfileRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteIssueManagerProfile."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/issuemanagerprofiles/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deletes specific issue manager profile
   * Delete issue manager profile
   */
  async deleteIssueManagerProfile(
    requestParameters: DeleteIssueManagerProfileRequest
  ): Promise<any> {
    const response = await this.deleteIssueManagerProfileRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get specific issue manager profile
   * Get issue manager profile
   */
  async getIssueManagerProfileRaw(
    requestParameters: GetIssueManagerProfileRequest
  ): Promise<runtime.ApiResponse<IssueManagerProfile>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getIssueManagerProfile."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/issuemanagerprofiles/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      IssueManagerProfileFromJSON(jsonValue)
    );
  }

  /**
   * Get specific issue manager profile
   * Get issue manager profile
   */
  async getIssueManagerProfile(
    requestParameters: GetIssueManagerProfileRequest
  ): Promise<IssueManagerProfile> {
    const response = await this.getIssueManagerProfileRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get count of issue manager profiles
   * Get count of issue manager profiles
   */
  async getIssueManagerProfileCountRaw(
    requestParameters: GetIssueManagerProfileCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getIssueManagerProfileCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/issuemanagerprofiles/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get count of issue manager profiles
   * Get count of issue manager profiles
   */
  async getIssueManagerProfileCount(
    requestParameters: GetIssueManagerProfileCountRequest
  ): Promise<Count> {
    const response = await this.getIssueManagerProfileCountRaw(
      requestParameters
    );
    return await response.value();
  }

  /**
   * Get list of issue manager profiles
   * Get list of issue manager profiles
   */
  async getIssueManagerProfilesRaw(
    requestParameters: GetIssueManagerProfilesRequest
  ): Promise<runtime.ApiResponse<Array<IssueManagerProfile>>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/issuemanagerprofiles`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(IssueManagerProfileFromJSON)
    );
  }

  /**
   * Get list of issue manager profiles
   * Get list of issue manager profiles
   */
  async getIssueManagerProfiles(
    requestParameters: GetIssueManagerProfilesRequest
  ): Promise<Array<IssueManagerProfile>> {
    const response = await this.getIssueManagerProfilesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Link Issue Manager
   * Link issue manager
   */
  async linkIssueManagerRaw(
    requestParameters: LinkIssueManagerRequest
  ): Promise<runtime.ApiResponse<GenericActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/issuemanagerprofiles/link`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: IssueManagerLinkPayloadToJSON(
        requestParameters.issueManagerLinkPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      GenericActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Link Issue Manager
   * Link issue manager
   */
  async linkIssueManager(
    requestParameters: LinkIssueManagerRequest
  ): Promise<GenericActionResult> {
    const response = await this.linkIssueManagerRaw(requestParameters);
    return await response.value();
  }

  /**
   * Issue Manager Profile to update
   * Updates a issue manager profile
   */
  async updateIssueManagerProfileRaw(
    requestParameters: UpdateIssueManagerProfileRequest
  ): Promise<runtime.ApiResponse<IssueManagerProfile>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateIssueManagerProfile."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/issuemanagerprofiles/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: IssueManagerProfilePayloadToJSON(
        requestParameters.issueManagerProfilePayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      IssueManagerProfileFromJSON(jsonValue)
    );
  }

  /**
   * Issue Manager Profile to update
   * Updates a issue manager profile
   */
  async updateIssueManagerProfile(
    requestParameters: UpdateIssueManagerProfileRequest
  ): Promise<IssueManagerProfile> {
    const response = await this.updateIssueManagerProfileRaw(requestParameters);
    return await response.value();
  }
}
