// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  Count,
  CountFromJSON,
  CountToJSON,
  Invitation,
  InvitationFromJSON,
  InvitationToJSON,
  InvitationPayload,
  InvitationPayloadFromJSON,
  InvitationPayloadToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON
} from "../models";

export interface AcceptInvitationRequest {
  inviteCode: number;
}

export interface CreateInvitationRequest {
  invitationPayload?: InvitationPayload;
}

export interface DeleteInvitationRequest {
  id: number;
}

export interface GetInvitationRequest {
  id: number;
}

export interface GetInvitationByCodeRequest {
  inviteCode: number;
}

export interface GetInvitationCountRequest {
  company: number;
}

export interface GetInvitationsRequest {
  limit?: number;
  sort?: string;
  start?: number;
  filter?: string;
}

export interface ResendInvitationRequest {
  invitationPayload?: InvitationPayload;
}

export interface UpdateInvitationRequest {
  id: number;
  invitationPayload?: InvitationPayload;
}

/**
 * no description
 */
export class InvitationsApi extends runtime.BaseAPI {
  /**
   * Accept invitation
   * Accept invitation
   */
  async acceptInvitationRaw(
    requestParameters: AcceptInvitationRequest
  ): Promise<runtime.ApiResponse<Invitation>> {
    if (
      requestParameters.inviteCode === null ||
      requestParameters.inviteCode === undefined
    ) {
      throw new runtime.RequiredError(
        "inviteCode",
        "Required parameter requestParameters.inviteCode was null or undefined when calling acceptInvitation."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/invitationsbycode/{inviteCode}`.replace(
        `{${"inviteCode"}}`,
        encodeURIComponent(String(requestParameters.inviteCode))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      InvitationFromJSON(jsonValue)
    );
  }

  /**
   * Accept invitation
   * Accept invitation
   */
  async acceptInvitation(
    requestParameters: AcceptInvitationRequest
  ): Promise<Invitation> {
    const response = await this.acceptInvitationRaw(requestParameters);
    return await response.value();
  }

  /**
   * Invitation to add
   * Create a new invitation
   */
  async createInvitationRaw(
    requestParameters: CreateInvitationRequest
  ): Promise<runtime.ApiResponse<Invitation>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/invitations`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: InvitationPayloadToJSON(requestParameters.invitationPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      InvitationFromJSON(jsonValue)
    );
  }

  /**
   * Invitation to add
   * Create a new invitation
   */
  async createInvitation(
    requestParameters: CreateInvitationRequest
  ): Promise<Invitation> {
    const response = await this.createInvitationRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes a specific invitation
   * Delete invitation
   */
  async deleteInvitationRaw(
    requestParameters: DeleteInvitationRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteInvitation."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/invitations/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deletes a specific invitation
   * Delete invitation
   */
  async deleteInvitation(
    requestParameters: DeleteInvitationRequest
  ): Promise<any> {
    const response = await this.deleteInvitationRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get details of specific invitation sent
   * Get invitation
   */
  async getInvitationRaw(
    requestParameters: GetInvitationRequest
  ): Promise<runtime.ApiResponse<Invitation>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getInvitation."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/invitations/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      InvitationFromJSON(jsonValue)
    );
  }

  /**
   * Get details of specific invitation sent
   * Get invitation
   */
  async getInvitation(
    requestParameters: GetInvitationRequest
  ): Promise<Invitation> {
    const response = await this.getInvitationRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get invitation code
   * Get invitation
   */
  async getInvitationByCodeRaw(
    requestParameters: GetInvitationByCodeRequest
  ): Promise<runtime.ApiResponse<Invitation>> {
    if (
      requestParameters.inviteCode === null ||
      requestParameters.inviteCode === undefined
    ) {
      throw new runtime.RequiredError(
        "inviteCode",
        "Required parameter requestParameters.inviteCode was null or undefined when calling getInvitationByCode."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/invitationsbycode/{inviteCode}`.replace(
        `{${"inviteCode"}}`,
        encodeURIComponent(String(requestParameters.inviteCode))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      InvitationFromJSON(jsonValue)
    );
  }

  /**
   * Get invitation code
   * Get invitation
   */
  async getInvitationByCode(
    requestParameters: GetInvitationByCodeRequest
  ): Promise<Invitation> {
    const response = await this.getInvitationByCodeRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get invitations' count
   * Get count of invitations
   */
  async getInvitationCountRaw(
    requestParameters: GetInvitationCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getInvitationCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/invitations/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get invitations' count
   * Get count of invitations
   */
  async getInvitationCount(
    requestParameters: GetInvitationCountRequest
  ): Promise<Count> {
    const response = await this.getInvitationCountRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get list of invitations sent
   * Get list of invitations
   */
  async getInvitationsRaw(
    requestParameters: GetInvitationsRequest
  ): Promise<runtime.ApiResponse<Array<Invitation>>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/invitations`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(InvitationFromJSON)
    );
  }

  /**
   * Get list of invitations sent
   * Get list of invitations
   */
  async getInvitations(
    requestParameters: GetInvitationsRequest
  ): Promise<Array<Invitation>> {
    const response = await this.getInvitationsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Invitation to add
   * Resend invitation
   */
  async resendInvitationRaw(
    requestParameters: ResendInvitationRequest
  ): Promise<runtime.ApiResponse<Invitation>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/invitations/resendInvitation`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: InvitationPayloadToJSON(requestParameters.invitationPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      InvitationFromJSON(jsonValue)
    );
  }

  /**
   * Invitation to add
   * Resend invitation
   */
  async resendInvitation(
    requestParameters: ResendInvitationRequest
  ): Promise<Invitation> {
    const response = await this.resendInvitationRaw(requestParameters);
    return await response.value();
  }

  /**
   * Invitation to update
   * Updates a invitation
   */
  async updateInvitationRaw(
    requestParameters: UpdateInvitationRequest
  ): Promise<runtime.ApiResponse<Invitation>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateInvitation."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/invitations/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: InvitationPayloadToJSON(requestParameters.invitationPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      InvitationFromJSON(jsonValue)
    );
  }

  /**
   * Invitation to update
   * Updates a invitation
   */
  async updateInvitation(
    requestParameters: UpdateInvitationRequest
  ): Promise<Invitation> {
    const response = await this.updateInvitationRaw(requestParameters);
    return await response.value();
  }
}
