// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  Count,
  CountFromJSON,
  CountToJSON,
  Role,
  RoleFromJSON,
  RoleToJSON,
  RolePayload,
  RolePayloadFromJSON,
  RolePayloadToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON
} from "../models";

export interface CloneRoleRequest {
  id: number;
}

export interface CreateRoleRequest {
  rolePayload?: RolePayload;
}

export interface DeleteRoleRequest {
  id: number;
}

export interface GetRoleRequest {
  id: number;
}

export interface GetRoleCountRequest {
  company: number;
}

export interface GetRolesRequest {
  company: number;
  limit?: number;
  start?: number;
  sort?: string;
  filter?: string;
}

export interface UpdateRoleRequest {
  id: number;
  rolePayload?: RolePayload;
}

/**
 * no description
 */
export class RolesApi extends runtime.BaseAPI {
  /**
   * Clone Role
   * Clone
   */
  async cloneRoleRaw(
    requestParameters: CloneRoleRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling cloneRole."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/tcroles/clone/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Clone Role
   * Clone
   */
  async cloneRole(requestParameters: CloneRoleRequest): Promise<any> {
    const response = await this.cloneRoleRaw(requestParameters);
    return await response.value();
  }

  /**
   * Role to add
   * Create a new role
   */
  async createRoleRaw(
    requestParameters: CreateRoleRequest
  ): Promise<runtime.ApiResponse<Role>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/tcroles`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: RolePayloadToJSON(requestParameters.rolePayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      RoleFromJSON(jsonValue)
    );
  }

  /**
   * Role to add
   * Create a new role
   */
  async createRole(requestParameters: CreateRoleRequest): Promise<Role> {
    const response = await this.createRoleRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes a specific role
   * Delete role
   */
  async deleteRoleRaw(
    requestParameters: DeleteRoleRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteRole."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/tcroles/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deletes a specific role
   * Delete role
   */
  async deleteRole(requestParameters: DeleteRoleRequest): Promise<any> {
    const response = await this.deleteRoleRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get details of a specific role
   * Get role
   */
  async getRoleRaw(
    requestParameters: GetRoleRequest
  ): Promise<runtime.ApiResponse<Role>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getRole."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/tcroles/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      RoleFromJSON(jsonValue)
    );
  }

  /**
   * Get details of a specific role
   * Get role
   */
  async getRole(requestParameters: GetRoleRequest): Promise<Role> {
    const response = await this.getRoleRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get roles'count for a specific company
   * Get roles' count
   */
  async getRoleCountRaw(
    requestParameters: GetRoleCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getRoleCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/tcroles/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get roles'count for a specific company
   * Get roles' count
   */
  async getRoleCount(requestParameters: GetRoleCountRequest): Promise<Count> {
    const response = await this.getRoleCountRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get list of roles
   * Get roles
   */
  async getRolesRaw(
    requestParameters: GetRolesRequest
  ): Promise<runtime.ApiResponse<Array<Role>>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getRoles."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/tcroles`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(RoleFromJSON)
    );
  }

  /**
   * Get list of roles
   * Get roles
   */
  async getRoles(requestParameters: GetRolesRequest): Promise<Array<Role>> {
    const response = await this.getRolesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Role to update
   * Updates a role
   */
  async updateRoleRaw(
    requestParameters: UpdateRoleRequest
  ): Promise<runtime.ApiResponse<Role>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateRole."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/tcroles/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: RolePayloadToJSON(requestParameters.rolePayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      RoleFromJSON(jsonValue)
    );
  }

  /**
   * Role to update
   * Updates a role
   */
  async updateRole(requestParameters: UpdateRoleRequest): Promise<Role> {
    const response = await this.updateRoleRaw(requestParameters);
    return await response.value();
  }
}
