// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  BulkActionResult,
  BulkActionResultFromJSON,
  BulkActionResultToJSON,
  Count,
  CountFromJSON,
  CountToJSON,
  DefaultResponseError,
  DefaultResponseErrorFromJSON,
  DefaultResponseErrorToJSON,
  ForbiddenError,
  ForbiddenErrorFromJSON,
  ForbiddenErrorToJSON,
  Project,
  ProjectFromJSON,
  ProjectToJSON,
  ProjectCreatePayload,
  ProjectCreatePayloadFromJSON,
  ProjectCreatePayloadToJSON,
  ProjectPayload,
  ProjectPayloadFromJSON,
  ProjectPayloadToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON
} from "../models";

export interface ArchiveProjectRequest {
  id: number;
}

export interface CreateProjectRequest {
  projectCreatePayload?: ProjectCreatePayload;
}

export interface DeleteProjectRequest {
  id: number;
}

export interface GetProjectRequest {
  id: number;
}

export interface GetProjectCountRequest {
  company: number;
  filter?: string;
}

export interface GetProjectsRequest {
  company: number;
  limit?: number;
  start?: number;
  sort?: string;
  filter?: string;
}

export interface PopulateSampleProjectDataRequest {
  id: number;
}

export interface ReopenProjectRequest {
  id: number;
}

export interface UpdateProjectRequest {
  id: number;
  projectPayload?: ProjectPayload;
}

/**
 * no description
 */
export class ProjectsApi extends runtime.BaseAPI {
  /**
   * Archives specific project
   * Archive given project
   */
  async archiveProjectRaw(
    requestParameters: ArchiveProjectRequest
  ): Promise<runtime.ApiResponse<Project>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling archiveProject."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/projects/archive/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ProjectFromJSON(jsonValue)
    );
  }

  /**
   * Archives specific project
   * Archive given project
   */
  async archiveProject(
    requestParameters: ArchiveProjectRequest
  ): Promise<Project> {
    const response = await this.archiveProjectRaw(requestParameters);
    return await response.value();
  }

  /**
   * Adds a new project
   * Adds a new project
   */
  async createProjectRaw(
    requestParameters: CreateProjectRequest
  ): Promise<runtime.ApiResponse<Project>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/projects`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ProjectCreatePayloadToJSON(requestParameters.projectCreatePayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ProjectFromJSON(jsonValue)
    );
  }

  /**
   * Adds a new project
   * Adds a new project
   */
  async createProject(
    requestParameters: CreateProjectRequest
  ): Promise<Project> {
    const response = await this.createProjectRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes a project
   * Deletes given project
   */
  async deleteProjectRaw(
    requestParameters: DeleteProjectRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteProject."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/projects/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deletes a project
   * Deletes given project
   */
  async deleteProject(requestParameters: DeleteProjectRequest): Promise<any> {
    const response = await this.deleteProjectRaw(requestParameters);
    return await response.value();
  }

  /**
   * Fetches the details of a project
   * Get project
   */
  async getProjectRaw(
    requestParameters: GetProjectRequest
  ): Promise<runtime.ApiResponse<Project>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getProject."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/projects/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ProjectFromJSON(jsonValue)
    );
  }

  /**
   * Fetches the details of a project
   * Get project
   */
  async getProject(requestParameters: GetProjectRequest): Promise<Project> {
    const response = await this.getProjectRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get projects' count
   * Get projects' count
   */
  async getProjectCountRaw(
    requestParameters: GetProjectCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getProjectCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/projects/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get projects' count
   * Get projects' count
   */
  async getProjectCount(
    requestParameters: GetProjectCountRequest
  ): Promise<Count> {
    const response = await this.getProjectCountRaw(requestParameters);
    return await response.value();
  }

  /**
   * Fetches list of projects
   * Get list of projects
   */
  async getProjectsRaw(
    requestParameters: GetProjectsRequest
  ): Promise<runtime.ApiResponse<Array<Project>>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getProjects."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/projects`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(ProjectFromJSON)
    );
  }

  /**
   * Fetches list of projects
   * Get list of projects
   */
  async getProjects(
    requestParameters: GetProjectsRequest
  ): Promise<Array<Project>> {
    const response = await this.getProjectsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Populate sample data in project
   * Populate sample data in project
   */
  async populateSampleProjectDataRaw(
    requestParameters: PopulateSampleProjectDataRequest
  ): Promise<runtime.ApiResponse<BulkActionResult>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling populateSampleProjectData."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/projects/populateSampleData/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      BulkActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Populate sample data in project
   * Populate sample data in project
   */
  async populateSampleProjectData(
    requestParameters: PopulateSampleProjectDataRequest
  ): Promise<BulkActionResult> {
    const response = await this.populateSampleProjectDataRaw(requestParameters);
    return await response.value();
  }

  /**
   * Reopens an archived project
   * Reopen project
   */
  async reopenProjectRaw(
    requestParameters: ReopenProjectRequest
  ): Promise<runtime.ApiResponse<Project>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling reopenProject."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/projects/reopen/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ProjectFromJSON(jsonValue)
    );
  }

  /**
   * Reopens an archived project
   * Reopen project
   */
  async reopenProject(
    requestParameters: ReopenProjectRequest
  ): Promise<Project> {
    const response = await this.reopenProjectRaw(requestParameters);
    return await response.value();
  }

  /**
   * Updates a project
   * Updates a project
   */
  async updateProjectRaw(
    requestParameters: UpdateProjectRequest
  ): Promise<runtime.ApiResponse<Project>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateProject."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/projects/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: ProjectPayloadToJSON(requestParameters.projectPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ProjectFromJSON(jsonValue)
    );
  }

  /**
   * Updates a project
   * Updates a project
   */
  async updateProject(
    requestParameters: UpdateProjectRequest
  ): Promise<Project> {
    const response = await this.updateProjectRaw(requestParameters);
    return await response.value();
  }
}
