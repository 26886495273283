// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists } from "../runtime";
import {
  CustomFieldCollectionOutput,
  CustomFieldCollectionOutputFromJSON,
  CustomFieldCollectionOutputToJSON,
  Requirement,
  RequirementFromJSON,
  RequirementToJSON,
  Step,
  StepFromJSON,
  StepToJSON,
  StepParsed,
  StepParsedFromJSON,
  StepParsedToJSON,
  Suite,
  SuiteFromJSON,
  SuiteToJSON,
  Tag,
  TagFromJSON,
  TagToJSON,
  TestCaseRevisionModifiedFields,
  TestCaseRevisionModifiedFieldsFromJSON,
  TestCaseRevisionModifiedFieldsToJSON,
  Upload,
  UploadFromJSON,
  UploadToJSON,
  UserMinified,
  UserMinifiedFromJSON,
  UserMinifiedToJSON
} from "./";

/**
 *
 * @export
 * @interface TestCaseRevision
 */
export interface TestCaseRevision {
  /**
   *
   * @type {number}
   * @memberof TestCaseRevision
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof TestCaseRevision
   */
  revision: number;
  /**
   *
   * @type {TestCaseRevisionModifiedFields}
   * @memberof TestCaseRevision
   */
  modifiedFields: TestCaseRevisionModifiedFields;
  /**
   *
   * @type {number}
   * @memberof TestCaseRevision
   */
  testcase: number;
  /**
   * Will be true only for latest version else false
   * @type {boolean}
   * @memberof TestCaseRevision
   */
  isHead?: boolean;
  /**
   * Date-time when test case revision created
   * @type {string}
   * @memberof TestCaseRevision
   */
  createdAt?: string;
  /**
   * Date-time when test case revision updated
   * @type {string}
   * @memberof TestCaseRevision
   */
  updatedAt?: string;
  /**
   *
   * @type {number}
   * @memberof TestCaseRevision
   */
  updatedBy?: number;
  /**
   *
   * @type {string}
   * @memberof TestCaseRevision
   */
  actionType?: string;
  /**
   *
   * @type {string}
   * @memberof TestCaseRevision
   */
  title?: string;
  /**
   *
   * @type {Suite}
   * @memberof TestCaseRevision
   */
  suite?: Suite;
  /**
   * Test case description (generated by rich text editor)
   * @type {string}
   * @memberof TestCaseRevision
   */
  description?: string;
  /**
   *
   * @type {Array<Step>}
   * @memberof TestCaseRevision
   */
  steps?: Array<Step>;
  /**
   *
   * @type {Array<StepParsed>}
   * @memberof TestCaseRevision
   */
  stepsParsed?: Array<StepParsed>;
  /**
   * Priority, 0 - Low, 1- Normal, 2 - High
   * @type {number}
   * @memberof TestCaseRevision
   */
  priority?: TestCaseRevisionPriorityEnum;
  /**
   *
   * @type {Array<Tag>}
   * @memberof TestCaseRevision
   */
  tags?: Array<Tag>;
  /**
   *
   * @type {Array<Requirement>}
   * @memberof TestCaseRevision
   */
  requirements?: Array<Requirement>;
  /**
   *
   * @type {Array<Upload>}
   * @memberof TestCaseRevision
   */
  attachments?: Array<Upload>;
  /**
   * Array of custom fields
   * @type {Array<CustomFieldCollectionOutput>}
   * @memberof TestCaseRevision
   */
  customFields?: Array<CustomFieldCollectionOutput>;
  /**
   *
   * @type {number}
   * @memberof TestCaseRevision
   */
  sortOrder?: number;
  /**
   * Date-time when test case created
   * @type {string}
   * @memberof TestCaseRevision
   */
  testcaseCreatedAt?: string;
  /**
   * Date-time when test case updated
   * @type {string}
   * @memberof TestCaseRevision
   */
  testcaseUpdatedAt?: string;
  /**
   *
   * @type {UserMinified}
   * @memberof TestCaseRevision
   */
  createdBy?: UserMinified;
  /**
   *
   * @type {number}
   * @memberof TestCaseRevision
   */
  lastRunStatus?: number;
  /**
   *
   * @type {number}
   * @memberof TestCaseRevision
   */
  runCount?: number;
  /**
   *
   * @type {number}
   * @memberof TestCaseRevision
   */
  avgExecutionTime?: number;
  /**
   *
   * @type {string}
   * @memberof TestCaseRevision
   */
  lastRunOn?: string;
  /**
   * Test failure percentage
   * @type {number}
   * @memberof TestCaseRevision
   */
  failureRate?: number;
  /**
   * Will be true only for links
   * @type {boolean}
   * @memberof TestCaseRevision
   */
  isReference?: boolean;
  /**
   * Only for links - source entity ID
   * @type {number}
   * @memberof TestCaseRevision
   */
  sourceId?: number;
  /**
   * Only for links - source Project ID
   * @type {number}
   * @memberof TestCaseRevision
   */
  sourceProject?: number;
  /**
   * Comments by poster and reviewer - JSON stringified (applicable when test case review feature is enabled)
   * @type {string}
   * @memberof TestCaseRevision
   */
  reviewComments?: string;
  /**
   * Reviewer Id (applicable when test case review feature is enabled)
   * @type {number}
   * @memberof TestCaseRevision
   */
  reviewer?: number;
  /**
   * Poster Id (applicable when test case review feature is enabled)
   * @type {number}
   * @memberof TestCaseRevision
   */
  poster?: number;
  /**
   * Review Status (applicable when test case review feature is enabled)
   * @type {number}
   * @memberof TestCaseRevision
   */
  reviewStatus?: number;
  /**
   * Automation status (applicable when test case automation feature is enabled)
   * @type {number}
   * @memberof TestCaseRevision
   */
  automationStatus?: number;
  /**
   * JSON stringified (applicable when test case automation feature is enabled)
   * @type {string}
   * @memberof TestCaseRevision
   */
  automationInfo?: string;
}

export function TestCaseRevisionFromJSON(json: any): TestCaseRevision {
  return {
    id: json["id"],
    revision: json["revision"],
    modifiedFields: TestCaseRevisionModifiedFieldsFromJSON(
      json["modified_fields"]
    ),
    testcase: json["testcase"],
    isHead: !exists(json, "is_head") ? undefined : json["is_head"],
    createdAt: !exists(json, "created_at") ? undefined : json["created_at"],
    updatedAt: !exists(json, "updated_at") ? undefined : json["updated_at"],
    updatedBy: !exists(json, "updated_by") ? undefined : json["updated_by"],
    actionType: !exists(json, "action_type") ? undefined : json["action_type"],
    title: !exists(json, "title") ? undefined : json["title"],
    suite: !exists(json, "suite") ? undefined : SuiteFromJSON(json["suite"]),
    description: !exists(json, "description") ? undefined : json["description"],
    steps: !exists(json, "steps")
      ? undefined
      : (json["steps"] as Array<any>).map(StepFromJSON),
    stepsParsed: !exists(json, "steps_parsed")
      ? undefined
      : (json["steps_parsed"] as Array<any>).map(StepParsedFromJSON),
    priority: !exists(json, "priority") ? undefined : json["priority"],
    tags: !exists(json, "tags")
      ? undefined
      : (json["tags"] as Array<any>).map(TagFromJSON),
    requirements: !exists(json, "requirements")
      ? undefined
      : (json["requirements"] as Array<any>).map(RequirementFromJSON),
    attachments: !exists(json, "attachments")
      ? undefined
      : (json["attachments"] as Array<any>).map(UploadFromJSON),
    customFields: !exists(json, "custom_fields")
      ? undefined
      : (json["custom_fields"] as Array<any>).map(
          CustomFieldCollectionOutputFromJSON
        ),
    sortOrder: !exists(json, "sort_order") ? undefined : json["sort_order"],
    testcaseCreatedAt: !exists(json, "testcase_created_at")
      ? undefined
      : json["testcase_created_at"],
    testcaseUpdatedAt: !exists(json, "testcase_updated_at")
      ? undefined
      : json["testcase_updated_at"],
    createdBy: !exists(json, "created_by")
      ? undefined
      : UserMinifiedFromJSON(json["created_by"]),
    lastRunStatus: !exists(json, "last_run_status")
      ? undefined
      : json["last_run_status"],
    runCount: !exists(json, "run_count") ? undefined : json["run_count"],
    avgExecutionTime: !exists(json, "avg_execution_time")
      ? undefined
      : json["avg_execution_time"],
    lastRunOn: !exists(json, "last_run_on") ? undefined : json["last_run_on"],
    failureRate: !exists(json, "failure_rate")
      ? undefined
      : json["failure_rate"],
    isReference: !exists(json, "is_reference")
      ? undefined
      : json["is_reference"],
    sourceId: !exists(json, "source_id") ? undefined : json["source_id"],
    sourceProject: !exists(json, "source_project")
      ? undefined
      : json["source_project"],
    reviewComments: !exists(json, "review_comments")
      ? undefined
      : json["review_comments"],
    reviewer: !exists(json, "reviewer") ? undefined : json["reviewer"],
    poster: !exists(json, "poster") ? undefined : json["poster"],
    reviewStatus: !exists(json, "review_status")
      ? undefined
      : json["review_status"],
    automationStatus: !exists(json, "automation_status")
      ? undefined
      : json["automation_status"],
    automationInfo: !exists(json, "automation_info")
      ? undefined
      : json["automation_info"]
  };
}

export function TestCaseRevisionToJSON(value?: TestCaseRevision): any {
  if (value === undefined) {
    return undefined;
  }
  return {
    id: value.id,
    revision: value.revision,
    modified_fields: TestCaseRevisionModifiedFieldsToJSON(value.modifiedFields),
    testcase: value.testcase,
    is_head: value.isHead,
    created_at: value.createdAt,
    updated_at: value.updatedAt,
    updated_by: value.updatedBy,
    action_type: value.actionType,
    title: value.title,
    suite: SuiteToJSON(value.suite),
    description: value.description,
    steps:
      value.steps === undefined
        ? undefined
        : (value.steps as Array<any>).map(StepToJSON),
    steps_parsed:
      value.stepsParsed === undefined
        ? undefined
        : (value.stepsParsed as Array<any>).map(StepParsedToJSON),
    priority: value.priority,
    tags:
      value.tags === undefined
        ? undefined
        : (value.tags as Array<any>).map(TagToJSON),
    requirements:
      value.requirements === undefined
        ? undefined
        : (value.requirements as Array<any>).map(RequirementToJSON),
    attachments:
      value.attachments === undefined
        ? undefined
        : (value.attachments as Array<any>).map(UploadToJSON),
    custom_fields:
      value.customFields === undefined
        ? undefined
        : (value.customFields as Array<any>).map(
            CustomFieldCollectionOutputToJSON
          ),
    sort_order: value.sortOrder,
    testcase_created_at: value.testcaseCreatedAt,
    testcase_updated_at: value.testcaseUpdatedAt,
    created_by: UserMinifiedToJSON(value.createdBy),
    last_run_status: value.lastRunStatus,
    run_count: value.runCount,
    avg_execution_time: value.avgExecutionTime,
    last_run_on: value.lastRunOn,
    failure_rate: value.failureRate,
    is_reference: value.isReference,
    source_id: value.sourceId,
    source_project: value.sourceProject,
    review_comments: value.reviewComments,
    reviewer: value.reviewer,
    poster: value.poster,
    review_status: value.reviewStatus,
    automation_status: value.automationStatus,
    automation_info: value.automationInfo
  };
}

/**
 * @export
 * @enum {string}
 */
export enum TestCaseRevisionPriorityEnum {
  NUMBER_0 = 0,
  NUMBER_1 = 1,
  NUMBER_2 = 2
}
