// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  BulkActionResult,
  BulkActionResultFromJSON,
  BulkActionResultToJSON,
  Count,
  CountFromJSON,
  CountToJSON,
  CreateActionResult,
  CreateActionResultFromJSON,
  CreateActionResultToJSON,
  FolderTree,
  FolderTreeFromJSON,
  FolderTreeToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON,
  TestPlanFolder,
  TestPlanFolderFromJSON,
  TestPlanFolderToJSON,
  TestPlanFolderCopyPayload,
  TestPlanFolderCopyPayloadFromJSON,
  TestPlanFolderCopyPayloadToJSON,
  TestPlanFolderPayload,
  TestPlanFolderPayloadFromJSON,
  TestPlanFolderPayloadToJSON,
  TestPlanFoldersOrderPayload,
  TestPlanFoldersOrderPayloadFromJSON,
  TestPlanFoldersOrderPayloadToJSON
} from "../models";

export interface CopyTestPlanFolderRequest {
  project: number;
  testPlanFolderCopyPayload?: TestPlanFolderCopyPayload;
}

export interface CreateTestPlanFolderRequest {
  testPlanFolderPayload?: TestPlanFolderPayload;
}

export interface DeleteTestPlanFolderRequest {
  id: number;
}

export interface GetTestPlanFolderRequest {
  id: number;
}

export interface GetTestPlanFolderCountRequest {
  project: number;
}

export interface GetTestPlanFolderTreeRequest {
  project: number;
}

export interface GetTestPlanFoldersRequest {
  project: number;
  limit?: number;
  sort?: string;
  start?: number;
  filter?: string;
}

export interface SetTestplansOrderRequest {
  testPlanFoldersOrderPayload?: TestPlanFoldersOrderPayload;
}

export interface UpdateTestPlanFolderRequest {
  id: number;
  testPlanFolderPayload?: TestPlanFolderPayload;
}

/**
 * no description
 */
export class TestPlanFoldersApi extends runtime.BaseAPI {
  /**
   * Duplicates a test plan folder
   * Duplicate test plan folder
   */
  async copyTestPlanFolderRaw(
    requestParameters: CopyTestPlanFolderRequest
  ): Promise<runtime.ApiResponse<BulkActionResult>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling copyTestPlanFolder."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplanfolder/copy`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TestPlanFolderCopyPayloadToJSON(
        requestParameters.testPlanFolderCopyPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      BulkActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Duplicates a test plan folder
   * Duplicate test plan folder
   */
  async copyTestPlanFolder(
    requestParameters: CopyTestPlanFolderRequest
  ): Promise<BulkActionResult> {
    const response = await this.copyTestPlanFolderRaw(requestParameters);
    return await response.value();
  }

  /**
   * Test Plan Folder to add
   * Create a new test plan folder
   */
  async createTestPlanFolderRaw(
    requestParameters: CreateTestPlanFolderRequest
  ): Promise<runtime.ApiResponse<TestPlanFolder>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplanfolders`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TestPlanFolderPayloadToJSON(requestParameters.testPlanFolderPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestPlanFolderFromJSON(jsonValue)
    );
  }

  /**
   * Test Plan Folder to add
   * Create a new test plan folder
   */
  async createTestPlanFolder(
    requestParameters: CreateTestPlanFolderRequest
  ): Promise<TestPlanFolder> {
    const response = await this.createTestPlanFolderRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes a specific test plan folder
   * Delete test plan folder
   */
  async deleteTestPlanFolderRaw(
    requestParameters: DeleteTestPlanFolderRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteTestPlanFolder."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplanfolders/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deletes a specific test plan folder
   * Delete test plan folder
   */
  async deleteTestPlanFolder(
    requestParameters: DeleteTestPlanFolderRequest
  ): Promise<any> {
    const response = await this.deleteTestPlanFolderRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get details of specific test plan folder
   * Get test plan folder
   */
  async getTestPlanFolderRaw(
    requestParameters: GetTestPlanFolderRequest
  ): Promise<runtime.ApiResponse<TestPlanFolder>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getTestPlanFolder."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplanfolders/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestPlanFolderFromJSON(jsonValue)
    );
  }

  /**
   * Get details of specific test plan folder
   * Get test plan folder
   */
  async getTestPlanFolder(
    requestParameters: GetTestPlanFolderRequest
  ): Promise<TestPlanFolder> {
    const response = await this.getTestPlanFolderRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get test plan folders' count in a specific project
   * Get test plan folders' count
   */
  async getTestPlanFolderCountRaw(
    requestParameters: GetTestPlanFolderCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestPlanFolderCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplanfolders/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get test plan folders' count in a specific project
   * Get test plan folders' count
   */
  async getTestPlanFolderCount(
    requestParameters: GetTestPlanFolderCountRequest
  ): Promise<Count> {
    const response = await this.getTestPlanFolderCountRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get test plan folder tree for a specific project
   * Get test plan folder tree
   */
  async getTestPlanFolderTreeRaw(
    requestParameters: GetTestPlanFolderTreeRequest
  ): Promise<runtime.ApiResponse<Array<FolderTree>>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestPlanFolderTree."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplanfolders/getTree`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(FolderTreeFromJSON)
    );
  }

  /**
   * Get test plan folder tree for a specific project
   * Get test plan folder tree
   */
  async getTestPlanFolderTree(
    requestParameters: GetTestPlanFolderTreeRequest
  ): Promise<Array<FolderTree>> {
    const response = await this.getTestPlanFolderTreeRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get list of test plan folders for a specific project
   * Get test plan folders
   */
  async getTestPlanFoldersRaw(
    requestParameters: GetTestPlanFoldersRequest
  ): Promise<runtime.ApiResponse<Array<TestPlanFolder>>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestPlanFolders."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplanfolders`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(TestPlanFolderFromJSON)
    );
  }

  /**
   * Get list of test plan folders for a specific project
   * Get test plan folders
   */
  async getTestPlanFolders(
    requestParameters: GetTestPlanFoldersRequest
  ): Promise<Array<TestPlanFolder>> {
    const response = await this.getTestPlanFoldersRaw(requestParameters);
    return await response.value();
  }

  /**
   * Set sort order for testplan folders
   * Set sort order for testplan folders
   */
  async setTestplansOrderRaw(
    requestParameters: SetTestplansOrderRequest
  ): Promise<runtime.ApiResponse<CreateActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplanfolders/setOrder`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TestPlanFoldersOrderPayloadToJSON(
        requestParameters.testPlanFoldersOrderPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CreateActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Set sort order for testplan folders
   * Set sort order for testplan folders
   */
  async setTestplansOrder(
    requestParameters: SetTestplansOrderRequest
  ): Promise<CreateActionResult> {
    const response = await this.setTestplansOrderRaw(requestParameters);
    return await response.value();
  }

  /**
   * Test Plan Folder to update
   * Updates a test plan folder
   */
  async updateTestPlanFolderRaw(
    requestParameters: UpdateTestPlanFolderRequest
  ): Promise<runtime.ApiResponse<TestPlanFolder>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateTestPlanFolder."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplanfolders/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: TestPlanFolderPayloadToJSON(requestParameters.testPlanFolderPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestPlanFolderFromJSON(jsonValue)
    );
  }

  /**
   * Test Plan Folder to update
   * Updates a test plan folder
   */
  async updateTestPlanFolder(
    requestParameters: UpdateTestPlanFolderRequest
  ): Promise<TestPlanFolder> {
    const response = await this.updateTestPlanFolderRaw(requestParameters);
    return await response.value();
  }
}
