// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  Count,
  CountFromJSON,
  CountToJSON,
  DefaultResponseError,
  DefaultResponseErrorFromJSON,
  DefaultResponseErrorToJSON,
  ForbiddenError,
  ForbiddenErrorFromJSON,
  ForbiddenErrorToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON,
  TestPlanRegression,
  TestPlanRegressionFromJSON,
  TestPlanRegressionToJSON,
  TestPlanRegressionPayload,
  TestPlanRegressionPayloadFromJSON,
  TestPlanRegressionPayloadToJSON
} from "../models";

export interface AddTestPlanRegressionRequest {
  testPlanRegressionPayload?: TestPlanRegressionPayload;
}

export interface DeleteTestPlanRegressionRequest {
  id: number;
}

export interface GetTestPlanRegressionRequest {
  id: number;
}

export interface GetTestPlanRegressionCountRequest {
  project: number;
}

export interface GetTestPlanRegressionsRequest {
  project: number;
  testplan: number;
  testPlanConfigurationId?: number;
  limit?: number;
  start?: number;
  sort?: string;
  filter?: string;
}

export interface UpdateTestPlanRegressionRequest {
  id: number;
  testPlanRegressionPayload?: TestPlanRegressionPayload;
}

/**
 * no description
 */
export class TestPlanRegressionsApi extends runtime.BaseAPI {
  /**
   * Adds a new regression for a test plan
   * Add a test plan regression
   */
  async addTestPlanRegressionRaw(
    requestParameters: AddTestPlanRegressionRequest
  ): Promise<runtime.ApiResponse<TestPlanRegression>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplanregressions`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TestPlanRegressionPayloadToJSON(
        requestParameters.testPlanRegressionPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestPlanRegressionFromJSON(jsonValue)
    );
  }

  /**
   * Adds a new regression for a test plan
   * Add a test plan regression
   */
  async addTestPlanRegression(
    requestParameters: AddTestPlanRegressionRequest
  ): Promise<TestPlanRegression> {
    const response = await this.addTestPlanRegressionRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes a specific test plan regression
   * Deletes test plan regression
   */
  async deleteTestPlanRegressionRaw(
    requestParameters: DeleteTestPlanRegressionRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteTestPlanRegression."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplanregressions/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deletes a specific test plan regression
   * Deletes test plan regression
   */
  async deleteTestPlanRegression(
    requestParameters: DeleteTestPlanRegressionRequest
  ): Promise<any> {
    const response = await this.deleteTestPlanRegressionRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get details of specific test plan regression
   * Get test plan regression
   */
  async getTestPlanRegressionRaw(
    requestParameters: GetTestPlanRegressionRequest
  ): Promise<runtime.ApiResponse<TestPlanRegression>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getTestPlanRegression."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplanregressions/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestPlanRegressionFromJSON(jsonValue)
    );
  }

  /**
   * Get details of specific test plan regression
   * Get test plan regression
   */
  async getTestPlanRegression(
    requestParameters: GetTestPlanRegressionRequest
  ): Promise<TestPlanRegression> {
    const response = await this.getTestPlanRegressionRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get test plan regressions' count for a specific project
   * Get test plan regressions' count
   */
  async getTestPlanRegressionCountRaw(
    requestParameters: GetTestPlanRegressionCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestPlanRegressionCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplanregressions/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get test plan regressions' count for a specific project
   * Get test plan regressions' count
   */
  async getTestPlanRegressionCount(
    requestParameters: GetTestPlanRegressionCountRequest
  ): Promise<Count> {
    const response = await this.getTestPlanRegressionCountRaw(
      requestParameters
    );
    return await response.value();
  }

  /**
   * Get details of regressions for specific test plan
   * Get test plan regressions
   */
  async getTestPlanRegressionsRaw(
    requestParameters: GetTestPlanRegressionsRequest
  ): Promise<runtime.ApiResponse<Array<TestPlanRegression>>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestPlanRegressions."
      );
    }

    if (
      requestParameters.testplan === null ||
      requestParameters.testplan === undefined
    ) {
      throw new runtime.RequiredError(
        "testplan",
        "Required parameter requestParameters.testplan was null or undefined when calling getTestPlanRegressions."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.testplan !== undefined) {
      queryParameters["testplan"] = requestParameters.testplan;
    }

    if (requestParameters.testPlanConfigurationId !== undefined) {
      queryParameters["test_plan_configuration_id"] =
        requestParameters.testPlanConfigurationId;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplanregressions`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(TestPlanRegressionFromJSON)
    );
  }

  /**
   * Get details of regressions for specific test plan
   * Get test plan regressions
   */
  async getTestPlanRegressions(
    requestParameters: GetTestPlanRegressionsRequest
  ): Promise<Array<TestPlanRegression>> {
    const response = await this.getTestPlanRegressionsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Test Plan Regression to update
   * Updates a test plan regression
   */
  async updateTestPlanRegressionRaw(
    requestParameters: UpdateTestPlanRegressionRequest
  ): Promise<runtime.ApiResponse<TestPlanRegression>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateTestPlanRegression."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplanregressions/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: TestPlanRegressionPayloadToJSON(
        requestParameters.testPlanRegressionPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestPlanRegressionFromJSON(jsonValue)
    );
  }

  /**
   * Test Plan Regression to update
   * Updates a test plan regression
   */
  async updateTestPlanRegression(
    requestParameters: UpdateTestPlanRegressionRequest
  ): Promise<TestPlanRegression> {
    const response = await this.updateTestPlanRegressionRaw(requestParameters);
    return await response.value();
  }
}
