// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  CancelSubscriptionPayload,
  CancelSubscriptionPayloadFromJSON,
  CancelSubscriptionPayloadToJSON,
  Count,
  CountFromJSON,
  CountToJSON,
  CustomerAccountInfomation,
  CustomerAccountInfomationFromJSON,
  CustomerAccountInfomationToJSON,
  CustomerSubscriptionsInfo,
  CustomerSubscriptionsInfoFromJSON,
  CustomerSubscriptionsInfoToJSON,
  GenericActionResult,
  GenericActionResultFromJSON,
  GenericActionResultToJSON,
  Invoice,
  InvoiceFromJSON,
  InvoiceToJSON,
  InvoicePreview,
  InvoicePreviewFromJSON,
  InvoicePreviewToJSON,
  PaymentProfile,
  PaymentProfileFromJSON,
  PaymentProfileToJSON,
  PaymentProfilePayload,
  PaymentProfilePayloadFromJSON,
  PaymentProfilePayloadToJSON,
  SaasInstance,
  SaasInstanceFromJSON,
  SaasInstanceToJSON,
  SaasInstanceBillingPayload,
  SaasInstanceBillingPayloadFromJSON,
  SaasInstanceBillingPayloadToJSON,
  SwitchFreePlanPayload,
  SwitchFreePlanPayloadFromJSON,
  SwitchFreePlanPayloadToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON
} from "../models";

export interface CancelSubscriptionRequest {
  cancelSubscriptionPayload?: CancelSubscriptionPayload;
}

export interface CreatePaymentProfileRequest {
  paymentProfilePayload?: PaymentProfilePayload;
}

export interface DeletePaymentProfileRequest {
  id: number;
}

export interface GetActiveSubscriptionRequest {
  company: number;
}

export interface GetCompanyOldRequest {
  id: number;
}

export interface GetInvoicePreviewRequest {
  company: number;
  plan: string;
}

export interface GetInvoicesRequest {
  company: number;
}

export interface GetManageAccountInfoRequest {
  company: number;
  authenticate?: boolean;
}

export interface GetPaymentProfileRequest {
  id: number;
}

export interface GetPaymentProfileCountRequest {
  company: number;
}

export interface GetPaymentProfilesRequest {
  company: number;
  limit?: number;
  start?: number;
  sort?: string;
  filter?: string;
}

export interface SwitchToFreePlanRequest {
  switchFreePlanPayload?: SwitchFreePlanPayload;
}

export interface UpdateCompanyBillingDetailsOldRequest {
  id: number;
  saasInstanceBillingPayload?: SaasInstanceBillingPayload;
}

export interface UpdatePaymentProfileRequest {
  id: number;
  paymentProfilePayload?: PaymentProfilePayload;
}

/**
 * no description
 */
export class SaasOnlyApi extends runtime.BaseAPI {
  /**
   * Cancel subscription / existing trial
   * Cancel subscription
   */
  async cancelSubscriptionRaw(
    requestParameters: CancelSubscriptionRequest
  ): Promise<runtime.ApiResponse<GenericActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/paymentprofiles/cancelSubscription`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CancelSubscriptionPayloadToJSON(
        requestParameters.cancelSubscriptionPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      GenericActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Cancel subscription / existing trial
   * Cancel subscription
   */
  async cancelSubscription(
    requestParameters: CancelSubscriptionRequest
  ): Promise<GenericActionResult> {
    const response = await this.cancelSubscriptionRaw(requestParameters);
    return await response.value();
  }

  /**
   * Payment Profile to add
   * Create a new payment profile
   */
  async createPaymentProfileRaw(
    requestParameters: CreatePaymentProfileRequest
  ): Promise<runtime.ApiResponse<PaymentProfile>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/paymentprofiles`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: PaymentProfilePayloadToJSON(requestParameters.paymentProfilePayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      PaymentProfileFromJSON(jsonValue)
    );
  }

  /**
   * Payment Profile to add
   * Create a new payment profile
   */
  async createPaymentProfile(
    requestParameters: CreatePaymentProfileRequest
  ): Promise<PaymentProfile> {
    const response = await this.createPaymentProfileRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deprecated
   * Deletes given payment profile (Deprecated)
   */
  async deletePaymentProfileRaw(
    requestParameters: DeletePaymentProfileRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deletePaymentProfile."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/paymentprofiles/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deprecated
   * Deletes given payment profile (Deprecated)
   */
  async deletePaymentProfile(
    requestParameters: DeletePaymentProfileRequest
  ): Promise<any> {
    const response = await this.deletePaymentProfileRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get active subscription(s) for a specific company
   * Get active subscription(s)
   */
  async getActiveSubscriptionRaw(
    requestParameters: GetActiveSubscriptionRequest
  ): Promise<runtime.ApiResponse<CustomerSubscriptionsInfo>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getActiveSubscription."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/paymentprofiles/getActiveSubscription`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CustomerSubscriptionsInfoFromJSON(jsonValue)
    );
  }

  /**
   * Get active subscription(s) for a specific company
   * Get active subscription(s)
   */
  async getActiveSubscription(
    requestParameters: GetActiveSubscriptionRequest
  ): Promise<CustomerSubscriptionsInfo> {
    const response = await this.getActiveSubscriptionRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get information specific to a company
   * Get company info
   */
  async getCompanyOldRaw(
    requestParameters: GetCompanyOldRequest
  ): Promise<runtime.ApiResponse<SaasInstance>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getCompanyOld."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/saasinstances/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      SaasInstanceFromJSON(jsonValue)
    );
  }

  /**
   * Get information specific to a company
   * Get company info
   */
  async getCompanyOld(
    requestParameters: GetCompanyOldRequest
  ): Promise<SaasInstance> {
    const response = await this.getCompanyOldRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get invoice preview
   * Get invoice preview
   */
  async getInvoicePreviewRaw(
    requestParameters: GetInvoicePreviewRequest
  ): Promise<runtime.ApiResponse<InvoicePreview>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getInvoicePreview."
      );
    }

    if (
      requestParameters.plan === null ||
      requestParameters.plan === undefined
    ) {
      throw new runtime.RequiredError(
        "plan",
        "Required parameter requestParameters.plan was null or undefined when calling getInvoicePreview."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    if (requestParameters.plan !== undefined) {
      queryParameters["plan"] = requestParameters.plan;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/paymentprofiles/getInvoicePreview`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      InvoicePreviewFromJSON(jsonValue)
    );
  }

  /**
   * Get invoice preview
   * Get invoice preview
   */
  async getInvoicePreview(
    requestParameters: GetInvoicePreviewRequest
  ): Promise<InvoicePreview> {
    const response = await this.getInvoicePreviewRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get list of paid invoices
   * Get list of paid invoices
   */
  async getInvoicesRaw(
    requestParameters: GetInvoicesRequest
  ): Promise<runtime.ApiResponse<Array<Invoice>>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getInvoices."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/invoices`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(InvoiceFromJSON)
    );
  }

  /**
   * Get list of paid invoices
   * Get list of paid invoices
   */
  async getInvoices(
    requestParameters: GetInvoicesRequest
  ): Promise<Array<Invoice>> {
    const response = await this.getInvoicesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get account information along with manage page URL
   * Get manage account information URL
   */
  async getManageAccountInfoRaw(
    requestParameters: GetManageAccountInfoRequest
  ): Promise<runtime.ApiResponse<CustomerAccountInfomation>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getManageAccountInfo."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    if (requestParameters.authenticate !== undefined) {
      queryParameters["authenticate"] = requestParameters.authenticate;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/paymentprofiles/getManageAccountInfo`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CustomerAccountInfomationFromJSON(jsonValue)
    );
  }

  /**
   * Get account information along with manage page URL
   * Get manage account information URL
   */
  async getManageAccountInfo(
    requestParameters: GetManageAccountInfoRequest
  ): Promise<CustomerAccountInfomation> {
    const response = await this.getManageAccountInfoRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get details of payment profile
   * Get payment profile
   */
  async getPaymentProfileRaw(
    requestParameters: GetPaymentProfileRequest
  ): Promise<runtime.ApiResponse<PaymentProfile>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getPaymentProfile."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/paymentprofiles/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      PaymentProfileFromJSON(jsonValue)
    );
  }

  /**
   * Get details of payment profile
   * Get payment profile
   */
  async getPaymentProfile(
    requestParameters: GetPaymentProfileRequest
  ): Promise<PaymentProfile> {
    const response = await this.getPaymentProfileRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get count of payment profiles
   * Get count of payment profiles
   */
  async getPaymentProfileCountRaw(
    requestParameters: GetPaymentProfileCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getPaymentProfileCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/paymentprofiles/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get count of payment profiles
   * Get count of payment profiles
   */
  async getPaymentProfileCount(
    requestParameters: GetPaymentProfileCountRequest
  ): Promise<Count> {
    const response = await this.getPaymentProfileCountRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get list of payment profiles
   * Get list of payment profiles
   */
  async getPaymentProfilesRaw(
    requestParameters: GetPaymentProfilesRequest
  ): Promise<runtime.ApiResponse<Array<PaymentProfile>>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getPaymentProfiles."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/paymentprofiles`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(PaymentProfileFromJSON)
    );
  }

  /**
   * Get list of payment profiles
   * Get list of payment profiles
   */
  async getPaymentProfiles(
    requestParameters: GetPaymentProfilesRequest
  ): Promise<Array<PaymentProfile>> {
    const response = await this.getPaymentProfilesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Switch an instance to free plan if limits are not exhausted
   * Switch an instance to free plan
   */
  async switchToFreePlanRaw(
    requestParameters: SwitchToFreePlanRequest
  ): Promise<runtime.ApiResponse<GenericActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/companies/switchToFreePlan`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: SwitchFreePlanPayloadToJSON(requestParameters.switchFreePlanPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      GenericActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Switch an instance to free plan if limits are not exhausted
   * Switch an instance to free plan
   */
  async switchToFreePlan(
    requestParameters: SwitchToFreePlanRequest
  ): Promise<GenericActionResult> {
    const response = await this.switchToFreePlanRaw(requestParameters);
    return await response.value();
  }

  /**
   * Updates billing details of specified company
   * Update billing details
   */
  async updateCompanyBillingDetailsOldRaw(
    requestParameters: UpdateCompanyBillingDetailsOldRequest
  ): Promise<runtime.ApiResponse<SaasInstance>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateCompanyBillingDetailsOld."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/saasinstances/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: SaasInstanceBillingPayloadToJSON(
        requestParameters.saasInstanceBillingPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      SaasInstanceFromJSON(jsonValue)
    );
  }

  /**
   * Updates billing details of specified company
   * Update billing details
   */
  async updateCompanyBillingDetailsOld(
    requestParameters: UpdateCompanyBillingDetailsOldRequest
  ): Promise<SaasInstance> {
    const response = await this.updateCompanyBillingDetailsOldRaw(
      requestParameters
    );
    return await response.value();
  }

  /**
   * Payment Profile to update
   * Updates a payment profile
   */
  async updatePaymentProfileRaw(
    requestParameters: UpdatePaymentProfileRequest
  ): Promise<runtime.ApiResponse<PaymentProfile>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updatePaymentProfile."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/paymentprofiles/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: PaymentProfilePayloadToJSON(requestParameters.paymentProfilePayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      PaymentProfileFromJSON(jsonValue)
    );
  }

  /**
   * Payment Profile to update
   * Updates a payment profile
   */
  async updatePaymentProfile(
    requestParameters: UpdatePaymentProfileRequest
  ): Promise<PaymentProfile> {
    const response = await this.updatePaymentProfileRaw(requestParameters);
    return await response.value();
  }
}
