// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  BulkActionResult,
  BulkActionResultFromJSON,
  BulkActionResultToJSON,
  BulkDeleteTestplanCasesPayload,
  BulkDeleteTestplanCasesPayloadFromJSON,
  BulkDeleteTestplanCasesPayloadToJSON,
  BulkUpdateTestplanCasesPayload,
  BulkUpdateTestplanCasesPayloadFromJSON,
  BulkUpdateTestplanCasesPayloadToJSON,
  Count,
  CountFromJSON,
  CountToJSON,
  DefaultResponseError,
  DefaultResponseErrorFromJSON,
  DefaultResponseErrorToJSON,
  FetchIdPayload,
  FetchIdPayloadFromJSON,
  FetchIdPayloadToJSON,
  FetchIdResult,
  FetchIdResultFromJSON,
  FetchIdResultToJSON,
  ForbiddenError,
  ForbiddenErrorFromJSON,
  ForbiddenErrorToJSON,
  SuiteTree,
  SuiteTreeFromJSON,
  SuiteTreeToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON,
  TestPlanCasesSortOrderPayload,
  TestPlanCasesSortOrderPayloadFromJSON,
  TestPlanCasesSortOrderPayloadToJSON,
  TestPlanTestCase,
  TestPlanTestCaseFromJSON,
  TestPlanTestCaseToJSON,
  TestPlanTestCaseBulkAddPayload,
  TestPlanTestCaseBulkAddPayloadFromJSON,
  TestPlanTestCaseBulkAddPayloadToJSON,
  TestPlanTestCasePayload,
  TestPlanTestCasePayloadFromJSON,
  TestPlanTestCasePayloadToJSON
} from "../models";

export interface AddTestPlanTestCasesRequest {
  testPlanTestCasePayload?: TestPlanTestCasePayload;
}

export interface BulkAddTestPlanTestCasesRequest {
  testPlanTestCaseBulkAddPayload?: TestPlanTestCaseBulkAddPayload;
}

export interface BulkDeleteTestplanCasesRequest {
  bulkDeleteTestplanCasesPayload?: BulkDeleteTestplanCasesPayload;
}

export interface BulkUpdateTestplanCasesRequest {
  bulkUpdateTestplanCasesPayload?: BulkUpdateTestplanCasesPayload;
}

export interface DeleteTestPlanTestCaseRequest {
  id: number;
  project: number;
}

export interface GetTestPlanSuiteTreeRequest {
  testplan: number;
}

export interface GetTestPlanTestCaseRequest {
  id: number;
  project?: number;
}

export interface GetTestPlanTestCaseCountRequest {
  project: number;
  filter: string;
}

export interface GetTestPlanTestCaseIdsRequest {
  fetchIdPayload?: FetchIdPayload;
}

export interface GetTestPlanTestCasesRequest {
  project: number;
  testplan: number;
  limit?: number;
  start?: number;
  sort?: string;
  filter?: string;
}

export interface SetTestPlanCasesSortOrderRequest {
  testPlanCasesSortOrderPayload?: TestPlanCasesSortOrderPayload;
}

export interface UpdateTestPlanTestCaseRequest {
  id: number;
  testPlanTestCasePayload?: TestPlanTestCasePayload;
}

/**
 * no description
 */
export class TestPlanTestCasesApi extends runtime.BaseAPI {
  /**
   * Array of test cases IDs -or- array of filter queries
   * Add test cases to a test plan
   */
  async addTestPlanTestCasesRaw(
    requestParameters: AddTestPlanTestCasesRequest
  ): Promise<runtime.ApiResponse<any>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplantestcases`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TestPlanTestCasePayloadToJSON(
        requestParameters.testPlanTestCasePayload
      )
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Array of test cases IDs -or- array of filter queries
   * Add test cases to a test plan
   */
  async addTestPlanTestCases(
    requestParameters: AddTestPlanTestCasesRequest
  ): Promise<any> {
    const response = await this.addTestPlanTestCasesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Array of test cases IDs -or- array of filter queries
   * Bulk add test cases to a test plan
   */
  async bulkAddTestPlanTestCasesRaw(
    requestParameters: BulkAddTestPlanTestCasesRequest
  ): Promise<runtime.ApiResponse<BulkActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplantestcases/bulkAdd`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TestPlanTestCaseBulkAddPayloadToJSON(
        requestParameters.testPlanTestCaseBulkAddPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      BulkActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Array of test cases IDs -or- array of filter queries
   * Bulk add test cases to a test plan
   */
  async bulkAddTestPlanTestCases(
    requestParameters: BulkAddTestPlanTestCasesRequest
  ): Promise<BulkActionResult> {
    const response = await this.bulkAddTestPlanTestCasesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update testplan test cases in bulk
   * Update testplan test cases in bulk
   */
  async bulkDeleteTestplanCasesRaw(
    requestParameters: BulkDeleteTestplanCasesRequest
  ): Promise<runtime.ApiResponse<BulkActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplantestcases/bulkDelete`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: BulkDeleteTestplanCasesPayloadToJSON(
        requestParameters.bulkDeleteTestplanCasesPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      BulkActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Update testplan test cases in bulk
   * Update testplan test cases in bulk
   */
  async bulkDeleteTestplanCases(
    requestParameters: BulkDeleteTestplanCasesRequest
  ): Promise<BulkActionResult> {
    const response = await this.bulkDeleteTestplanCasesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Update testplan test cases in bulk
   * Update testplan test cases in bulk
   */
  async bulkUpdateTestplanCasesRaw(
    requestParameters: BulkUpdateTestplanCasesRequest
  ): Promise<runtime.ApiResponse<BulkActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplantestcases/bulkAction`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: BulkUpdateTestplanCasesPayloadToJSON(
        requestParameters.bulkUpdateTestplanCasesPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      BulkActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Update testplan test cases in bulk
   * Update testplan test cases in bulk
   */
  async bulkUpdateTestplanCases(
    requestParameters: BulkUpdateTestplanCasesRequest
  ): Promise<BulkActionResult> {
    const response = await this.bulkUpdateTestplanCasesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes a specific test case added for a test plan
   * Delete a test plan test case
   */
  async deleteTestPlanTestCaseRaw(
    requestParameters: DeleteTestPlanTestCaseRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteTestPlanTestCase."
      );
    }

    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling deleteTestPlanTestCase."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplantestcases/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deletes a specific test case added for a test plan
   * Delete a test plan test case
   */
  async deleteTestPlanTestCase(
    requestParameters: DeleteTestPlanTestCaseRequest
  ): Promise<any> {
    const response = await this.deleteTestPlanTestCaseRaw(requestParameters);
    return await response.value();
  }

  /**
   * returns suite tree of execution
   * suite tree for test plan's test cases
   */
  async getTestPlanSuiteTreeRaw(
    requestParameters: GetTestPlanSuiteTreeRequest
  ): Promise<runtime.ApiResponse<SuiteTree>> {
    if (
      requestParameters.testplan === null ||
      requestParameters.testplan === undefined
    ) {
      throw new runtime.RequiredError(
        "testplan",
        "Required parameter requestParameters.testplan was null or undefined when calling getTestPlanSuiteTree."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.testplan !== undefined) {
      queryParameters["testplan"] = requestParameters.testplan;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplantestcases/suitetree`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      SuiteTreeFromJSON(jsonValue)
    );
  }

  /**
   * returns suite tree of execution
   * suite tree for test plan's test cases
   */
  async getTestPlanSuiteTree(
    requestParameters: GetTestPlanSuiteTreeRequest
  ): Promise<SuiteTree> {
    const response = await this.getTestPlanSuiteTreeRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get details of a specific test case added to a test plan
   * Get test plan test case
   */
  async getTestPlanTestCaseRaw(
    requestParameters: GetTestPlanTestCaseRequest
  ): Promise<runtime.ApiResponse<TestPlanTestCase>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getTestPlanTestCase."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplantestcases/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestPlanTestCaseFromJSON(jsonValue)
    );
  }

  /**
   * Get details of a specific test case added to a test plan
   * Get test plan test case
   */
  async getTestPlanTestCase(
    requestParameters: GetTestPlanTestCaseRequest
  ): Promise<TestPlanTestCase> {
    const response = await this.getTestPlanTestCaseRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get count of test cases added to a test plan
   * Get test plan test cases' count
   */
  async getTestPlanTestCaseCountRaw(
    requestParameters: GetTestPlanTestCaseCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestPlanTestCaseCount."
      );
    }

    if (
      requestParameters.filter === null ||
      requestParameters.filter === undefined
    ) {
      throw new runtime.RequiredError(
        "filter",
        "Required parameter requestParameters.filter was null or undefined when calling getTestPlanTestCaseCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplantestcases/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get count of test cases added to a test plan
   * Get test plan test cases' count
   */
  async getTestPlanTestCaseCount(
    requestParameters: GetTestPlanTestCaseCountRequest
  ): Promise<Count> {
    const response = await this.getTestPlanTestCaseCountRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get id(s) of test case(s) under a test plan that match the filter criteria
   * Get matching test plan test cases' id
   */
  async getTestPlanTestCaseIdsRaw(
    requestParameters: GetTestPlanTestCaseIdsRequest
  ): Promise<runtime.ApiResponse<FetchIdResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplantestcases/fetchIds`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: FetchIdPayloadToJSON(requestParameters.fetchIdPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      FetchIdResultFromJSON(jsonValue)
    );
  }

  /**
   * Get id(s) of test case(s) under a test plan that match the filter criteria
   * Get matching test plan test cases' id
   */
  async getTestPlanTestCaseIds(
    requestParameters: GetTestPlanTestCaseIdsRequest
  ): Promise<FetchIdResult> {
    const response = await this.getTestPlanTestCaseIdsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get list of test cases for a test plan
   * Get test plan test cases
   */
  async getTestPlanTestCasesRaw(
    requestParameters: GetTestPlanTestCasesRequest
  ): Promise<runtime.ApiResponse<Array<TestPlanTestCase>>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestPlanTestCases."
      );
    }

    if (
      requestParameters.testplan === null ||
      requestParameters.testplan === undefined
    ) {
      throw new runtime.RequiredError(
        "testplan",
        "Required parameter requestParameters.testplan was null or undefined when calling getTestPlanTestCases."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.testplan !== undefined) {
      queryParameters["testplan"] = requestParameters.testplan;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplantestcases`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(TestPlanTestCaseFromJSON)
    );
  }

  /**
   * Get list of test cases for a test plan
   * Get test plan test cases
   */
  async getTestPlanTestCases(
    requestParameters: GetTestPlanTestCasesRequest
  ): Promise<Array<TestPlanTestCase>> {
    const response = await this.getTestPlanTestCasesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Set sort order for test cases on run page
   * Set sort order for test cases on run page
   */
  async setTestPlanCasesSortOrderRaw(
    requestParameters: SetTestPlanCasesSortOrderRequest
  ): Promise<runtime.ApiResponse<BulkActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplantestcases/setSortOrder`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TestPlanCasesSortOrderPayloadToJSON(
        requestParameters.testPlanCasesSortOrderPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      BulkActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Set sort order for test cases on run page
   * Set sort order for test cases on run page
   */
  async setTestPlanCasesSortOrder(
    requestParameters: SetTestPlanCasesSortOrderRequest
  ): Promise<BulkActionResult> {
    const response = await this.setTestPlanCasesSortOrderRaw(requestParameters);
    return await response.value();
  }

  /**
   * Test Plan Test Case to update
   * Updates a test plan test case
   */
  async updateTestPlanTestCaseRaw(
    requestParameters: UpdateTestPlanTestCaseRequest
  ): Promise<runtime.ApiResponse<TestPlanTestCase>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateTestPlanTestCase."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplantestcases/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: TestPlanTestCasePayloadToJSON(
        requestParameters.testPlanTestCasePayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestPlanTestCaseFromJSON(jsonValue)
    );
  }

  /**
   * Test Plan Test Case to update
   * Updates a test plan test case
   */
  async updateTestPlanTestCase(
    requestParameters: UpdateTestPlanTestCaseRequest
  ): Promise<TestPlanTestCase> {
    const response = await this.updateTestPlanTestCaseRaw(requestParameters);
    return await response.value();
  }
}
