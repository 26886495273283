// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  BulkActionResult,
  BulkActionResultFromJSON,
  BulkActionResultToJSON,
  Count,
  CountFromJSON,
  CountToJSON,
  CreateActionResult,
  CreateActionResultFromJSON,
  CreateActionResultToJSON,
  GenericActionResult,
  GenericActionResultFromJSON,
  GenericActionResultToJSON,
  GenericFolder,
  GenericFolderFromJSON,
  GenericFolderToJSON,
  GenericFolderPayload,
  GenericFolderPayloadFromJSON,
  GenericFolderPayloadToJSON,
  GenericFolderTree,
  GenericFolderTreeFromJSON,
  GenericFolderTreeToJSON,
  GenericFoldersOrderPayload,
  GenericFoldersOrderPayloadFromJSON,
  GenericFoldersOrderPayloadToJSON,
  RequirementFolderCopyPayload,
  RequirementFolderCopyPayloadFromJSON,
  RequirementFolderCopyPayloadToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON
} from "../models";

export interface CopyRequirementFolderRequest {
  project: number;
  requirementFolderCopyPayload?: RequirementFolderCopyPayload;
}

export interface CreateRequirementFolderRequest {
  genericFolderPayload?: GenericFolderPayload;
}

export interface DeleteRequirementFolderRequest {
  id: number;
}

export interface GetRequirementFolderRequest {
  id: number;
}

export interface GetRequirementFolderCountRequest {
  project: number;
}

export interface GetRequirementFolderTreeRequest {
  project: number;
}

export interface GetRequirementFoldersRequest {
  project: number;
  limit?: number;
  sort?: string;
  start?: number;
  filter?: string;
}

export interface SetRequirementsOrderRequest {
  genericFoldersOrderPayload?: GenericFoldersOrderPayload;
}

export interface UpdateRequirementFolderRequest {
  id: number;
  genericFolderPayload?: GenericFolderPayload;
}

/**
 * no description
 */
export class RequirementFoldersApi extends runtime.BaseAPI {
  /**
   * Duplicates a requirement folder
   * Duplicate requirement folder
   */
  async copyRequirementFolderRaw(
    requestParameters: CopyRequirementFolderRequest
  ): Promise<runtime.ApiResponse<BulkActionResult>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling copyRequirementFolder."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/requirementfolders/copy`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: RequirementFolderCopyPayloadToJSON(
        requestParameters.requirementFolderCopyPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      BulkActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Duplicates a requirement folder
   * Duplicate requirement folder
   */
  async copyRequirementFolder(
    requestParameters: CopyRequirementFolderRequest
  ): Promise<BulkActionResult> {
    const response = await this.copyRequirementFolderRaw(requestParameters);
    return await response.value();
  }

  /**
   * Requirement Folder to add
   * Create a new requirement folder
   */
  async createRequirementFolderRaw(
    requestParameters: CreateRequirementFolderRequest
  ): Promise<runtime.ApiResponse<GenericFolder>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/requirementfolders`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: GenericFolderPayloadToJSON(requestParameters.genericFolderPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      GenericFolderFromJSON(jsonValue)
    );
  }

  /**
   * Requirement Folder to add
   * Create a new requirement folder
   */
  async createRequirementFolder(
    requestParameters: CreateRequirementFolderRequest
  ): Promise<GenericFolder> {
    const response = await this.createRequirementFolderRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes a specific requirement folder
   * Delete requirement folder
   */
  async deleteRequirementFolderRaw(
    requestParameters: DeleteRequirementFolderRequest
  ): Promise<runtime.ApiResponse<GenericActionResult>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteRequirementFolder."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/requirementfolders/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      GenericActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Deletes a specific requirement folder
   * Delete requirement folder
   */
  async deleteRequirementFolder(
    requestParameters: DeleteRequirementFolderRequest
  ): Promise<GenericActionResult> {
    const response = await this.deleteRequirementFolderRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get details of specific requirement folder
   * Get requirement folder
   */
  async getRequirementFolderRaw(
    requestParameters: GetRequirementFolderRequest
  ): Promise<runtime.ApiResponse<GenericFolder>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getRequirementFolder."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/requirementfolders/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      GenericFolderFromJSON(jsonValue)
    );
  }

  /**
   * Get details of specific requirement folder
   * Get requirement folder
   */
  async getRequirementFolder(
    requestParameters: GetRequirementFolderRequest
  ): Promise<GenericFolder> {
    const response = await this.getRequirementFolderRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get requirement folders' count in a specific project
   * Get requirement folders' count
   */
  async getRequirementFolderCountRaw(
    requestParameters: GetRequirementFolderCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getRequirementFolderCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/requirementfolders/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get requirement folders' count in a specific project
   * Get requirement folders' count
   */
  async getRequirementFolderCount(
    requestParameters: GetRequirementFolderCountRequest
  ): Promise<Count> {
    const response = await this.getRequirementFolderCountRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get requirement folder tree for a specific project
   * Get requirement folder tree
   */
  async getRequirementFolderTreeRaw(
    requestParameters: GetRequirementFolderTreeRequest
  ): Promise<runtime.ApiResponse<Array<GenericFolderTree>>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getRequirementFolderTree."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/requirementfolders/getTree`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(GenericFolderTreeFromJSON)
    );
  }

  /**
   * Get requirement folder tree for a specific project
   * Get requirement folder tree
   */
  async getRequirementFolderTree(
    requestParameters: GetRequirementFolderTreeRequest
  ): Promise<Array<GenericFolderTree>> {
    const response = await this.getRequirementFolderTreeRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get list of requirement folders for a specific project
   * Get requirement folders
   */
  async getRequirementFoldersRaw(
    requestParameters: GetRequirementFoldersRequest
  ): Promise<runtime.ApiResponse<Array<GenericFolder>>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getRequirementFolders."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/requirementfolders`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(GenericFolderFromJSON)
    );
  }

  /**
   * Get list of requirement folders for a specific project
   * Get requirement folders
   */
  async getRequirementFolders(
    requestParameters: GetRequirementFoldersRequest
  ): Promise<Array<GenericFolder>> {
    const response = await this.getRequirementFoldersRaw(requestParameters);
    return await response.value();
  }

  /**
   * Set sort order for requirement folders
   * Set sort order for requirement folders
   */
  async setRequirementsOrderRaw(
    requestParameters: SetRequirementsOrderRequest
  ): Promise<runtime.ApiResponse<CreateActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/requirementfolders/setOrder`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: GenericFoldersOrderPayloadToJSON(
        requestParameters.genericFoldersOrderPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CreateActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Set sort order for requirement folders
   * Set sort order for requirement folders
   */
  async setRequirementsOrder(
    requestParameters: SetRequirementsOrderRequest
  ): Promise<CreateActionResult> {
    const response = await this.setRequirementsOrderRaw(requestParameters);
    return await response.value();
  }

  /**
   * Requirement Folder to update
   * Updates a requirement folder
   */
  async updateRequirementFolderRaw(
    requestParameters: UpdateRequirementFolderRequest
  ): Promise<runtime.ApiResponse<GenericFolder>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateRequirementFolder."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/requirementfolders/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: GenericFolderPayloadToJSON(requestParameters.genericFolderPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      GenericFolderFromJSON(jsonValue)
    );
  }

  /**
   * Requirement Folder to update
   * Updates a requirement folder
   */
  async updateRequirementFolder(
    requestParameters: UpdateRequirementFolderRequest
  ): Promise<GenericFolder> {
    const response = await this.updateRequirementFolderRaw(requestParameters);
    return await response.value();
  }
}
