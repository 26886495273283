import { Upload } from "../services/generated-sdk";
import Storage from "browserstorage-ttl";
import { getLocationForCurrentSession } from "./data-residency";

export const getAvatarURL = (avatar: Upload) => {
  if (!avatar) return null;
  let url;
  //console.log({ avatar });
  if (avatar.provider == "mock") {
    url = avatar.url;
  } else {
    let location = getLocationForCurrentSession();
    url =
      avatar.provider === "local"
        ? process.env["API_BASE_URL"] + "/" + avatar.url
        : avatar.url;
    if (location == "EU") {
      url =
        avatar.provider === "local"
          ? process.env["API_BASE_URL_EU"] + "/" + avatar.url
          : avatar.url;
    }
  }
  return url;
};

export const storeUserSession = (user, expiry) => {
  Storage.set("TC_USER", JSON.stringify(user), expiry);
};
