// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  Count,
  CountFromJSON,
  CountToJSON,
  MarkTaskPayload,
  MarkTaskPayloadFromJSON,
  MarkTaskPayloadToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON,
  Task,
  TaskFromJSON,
  TaskToJSON,
  TaskPayload,
  TaskPayloadFromJSON,
  TaskPayloadToJSON
} from "../models";

export interface DeleteTaskRequest {
  id: number;
  company: number;
}

export interface GetTaskRequest {
  id: number;
  company: number;
}

export interface GetTaskCountRequest {
  company: number;
  filter?: string;
}

export interface GetTasksRequest {
  company: number;
  project?: number;
  user?: number;
  limit?: number;
  start?: number;
  sort?: string;
  filter?: string;
}

export interface MarkTaskRequest {
  id: number;
  markTaskPayload?: MarkTaskPayload;
}

export interface UpdateTaskRequest {
  id: number;
  taskPayload?: TaskPayload;
}

/**
 * no description
 */
export class TasksApi extends runtime.BaseAPI {
  /**
   * Deletes a specific task
   * Delete task
   */
  async deleteTaskRaw(
    requestParameters: DeleteTaskRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteTask."
      );
    }

    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling deleteTask."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/tasks/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deletes a specific task
   * Delete task
   */
  async deleteTask(requestParameters: DeleteTaskRequest): Promise<any> {
    const response = await this.deleteTaskRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get a specific task
   * Get task
   */
  async getTaskRaw(
    requestParameters: GetTaskRequest
  ): Promise<runtime.ApiResponse<Task>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getTask."
      );
    }

    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getTask."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/tasks/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TaskFromJSON(jsonValue)
    );
  }

  /**
   * Get a specific task
   * Get task
   */
  async getTask(requestParameters: GetTaskRequest): Promise<Task> {
    const response = await this.getTaskRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get tasks' count for a specific company
   * Get tasks' count
   */
  async getTaskCountRaw(
    requestParameters: GetTaskCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getTaskCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/tasks/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get tasks' count for a specific company
   * Get tasks' count
   */
  async getTaskCount(requestParameters: GetTaskCountRequest): Promise<Count> {
    const response = await this.getTaskCountRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get list of tasks specific for a company
   * Get tasks
   */
  async getTasksRaw(
    requestParameters: GetTasksRequest
  ): Promise<runtime.ApiResponse<Array<Task>>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getTasks."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.user !== undefined) {
      queryParameters["user"] = requestParameters.user;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/tasks`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(TaskFromJSON)
    );
  }

  /**
   * Get list of tasks specific for a company
   * Get tasks
   */
  async getTasks(requestParameters: GetTasksRequest): Promise<Array<Task>> {
    const response = await this.getTasksRaw(requestParameters);
    return await response.value();
  }

  /**
   * Task to update
   * Mark a task
   */
  async markTaskRaw(
    requestParameters: MarkTaskRequest
  ): Promise<runtime.ApiResponse<Task>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling markTask."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/tasks/{id}/markTask`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: MarkTaskPayloadToJSON(requestParameters.markTaskPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TaskFromJSON(jsonValue)
    );
  }

  /**
   * Task to update
   * Mark a task
   */
  async markTask(requestParameters: MarkTaskRequest): Promise<Task> {
    const response = await this.markTaskRaw(requestParameters);
    return await response.value();
  }

  /**
   * Task to update
   * Updates a task
   */
  async updateTaskRaw(
    requestParameters: UpdateTaskRequest
  ): Promise<runtime.ApiResponse<Task>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateTask."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/tasks/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: TaskPayloadToJSON(requestParameters.taskPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TaskFromJSON(jsonValue)
    );
  }

  /**
   * Task to update
   * Updates a task
   */
  async updateTask(requestParameters: UpdateTaskRequest): Promise<Task> {
    const response = await this.updateTaskRaw(requestParameters);
    return await response.value();
  }
}
