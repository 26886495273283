// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  BulkActionResult,
  BulkActionResultFromJSON,
  BulkActionResultToJSON,
  DefaultResponseError,
  DefaultResponseErrorFromJSON,
  DefaultResponseErrorToJSON,
  DuplicateTestPlanPayload,
  DuplicateTestPlanPayloadFromJSON,
  DuplicateTestPlanPayloadToJSON,
  FetchIdPayload,
  FetchIdPayloadFromJSON,
  FetchIdPayloadToJSON,
  FetchIdResult,
  FetchIdResultFromJSON,
  FetchIdResultToJSON,
  ForbiddenError,
  ForbiddenErrorFromJSON,
  ForbiddenErrorToJSON,
  PlanCaseValidationStatusCount,
  PlanCaseValidationStatusCountFromJSON,
  PlanCaseValidationStatusCountToJSON,
  ResultSummary,
  ResultSummaryFromJSON,
  ResultSummaryToJSON,
  TestPlan,
  TestPlanFromJSON,
  TestPlanToJSON,
  TestPlanBulkActionPayload,
  TestPlanBulkActionPayloadFromJSON,
  TestPlanBulkActionPayloadToJSON,
  TestPlanMinified,
  TestPlanMinifiedFromJSON,
  TestPlanMinifiedToJSON,
  TestPlanPayload,
  TestPlanPayloadFromJSON,
  TestPlanPayloadToJSON
} from "../models";

export interface AddTestPlanRequest {
  testPlanPayload?: TestPlanPayload;
}

export interface ArchiveTestPlanRequest {
  id: number;
}

export interface CopyTestPlanRequest {
  id: number;
  duplicateTestPlanPayload?: DuplicateTestPlanPayload;
}

export interface DeleteTestPlanRequest {
  id: number;
  project: number;
}

export interface EditTestPlanRequest {
  id: number;
  testPlanPayload?: TestPlanPayload;
}

export interface GetMyProgressRequest {
  id: number;
  regression?: number;
}

export interface GetTestPlanRequest {
  id: number;
  showUserTimeTaken?: number;
}

export interface GetTestPlanIdsRequest {
  fetchIdPayload?: FetchIdPayload;
}

export interface GetTestPlansRequest {
  project: number;
  limit?: number;
  start?: number;
  sort?: string;
  filter?: string;
}

export interface GetValidationStatusesRequest {
  id: number;
  project: number;
  filter?: string;
}

export interface ReopenTestPlanRequest {
  id: number;
}

export interface TestPlanBulkActionsRequest {
  testPlanBulkActionPayload?: TestPlanBulkActionPayload;
}

/**
 * no description
 */
export class TestPlansApi extends runtime.BaseAPI {
  /**
   * Adds a new test plan
   * Add new test plan
   */
  async addTestPlanRaw(
    requestParameters: AddTestPlanRequest
  ): Promise<runtime.ApiResponse<TestPlanMinified>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplans`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TestPlanPayloadToJSON(requestParameters.testPlanPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestPlanMinifiedFromJSON(jsonValue)
    );
  }

  /**
   * Adds a new test plan
   * Add new test plan
   */
  async addTestPlan(
    requestParameters: AddTestPlanRequest
  ): Promise<TestPlanMinified> {
    const response = await this.addTestPlanRaw(requestParameters);
    return await response.value();
  }

  /**
   * Archives specific test plan
   * Archive given test plan
   */
  async archiveTestPlanRaw(
    requestParameters: ArchiveTestPlanRequest
  ): Promise<runtime.ApiResponse<TestPlan>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling archiveTestPlan."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplan/archive/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestPlanFromJSON(jsonValue)
    );
  }

  /**
   * Archives specific test plan
   * Archive given test plan
   */
  async archiveTestPlan(
    requestParameters: ArchiveTestPlanRequest
  ): Promise<TestPlan> {
    const response = await this.archiveTestPlanRaw(requestParameters);
    return await response.value();
  }

  /**
   * Duplicates specific test plan
   * Duplicate given test plan
   */
  async copyTestPlanRaw(
    requestParameters: CopyTestPlanRequest
  ): Promise<runtime.ApiResponse<BulkActionResult>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling copyTestPlan."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplan/copy/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: DuplicateTestPlanPayloadToJSON(
        requestParameters.duplicateTestPlanPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      BulkActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Duplicates specific test plan
   * Duplicate given test plan
   */
  async copyTestPlan(
    requestParameters: CopyTestPlanRequest
  ): Promise<BulkActionResult> {
    const response = await this.copyTestPlanRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes a specific test plan
   * Delete test plan
   */
  async deleteTestPlanRaw(
    requestParameters: DeleteTestPlanRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteTestPlan."
      );
    }

    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling deleteTestPlan."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplans/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deletes a specific test plan
   * Delete test plan
   */
  async deleteTestPlan(requestParameters: DeleteTestPlanRequest): Promise<any> {
    const response = await this.deleteTestPlanRaw(requestParameters);
    return await response.value();
  }

  /**
   * Edits a specific test plan
   * Edit a test plan
   */
  async editTestPlanRaw(
    requestParameters: EditTestPlanRequest
  ): Promise<runtime.ApiResponse<TestPlanMinified>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling editTestPlan."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplans/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: TestPlanPayloadToJSON(requestParameters.testPlanPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestPlanMinifiedFromJSON(jsonValue)
    );
  }

  /**
   * Edits a specific test plan
   * Edit a test plan
   */
  async editTestPlan(
    requestParameters: EditTestPlanRequest
  ): Promise<TestPlanMinified> {
    const response = await this.editTestPlanRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get results of specific test plan for logged in user
   * Get specific Test Plan result
   */
  async getMyProgressRaw(
    requestParameters: GetMyProgressRequest
  ): Promise<runtime.ApiResponse<ResultSummary>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getMyProgress."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.regression !== undefined) {
      queryParameters["regression"] = requestParameters.regression;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplans/{id}/getMyProgress`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ResultSummaryFromJSON(jsonValue)
    );
  }

  /**
   * Get results of specific test plan for logged in user
   * Get specific Test Plan result
   */
  async getMyProgress(
    requestParameters: GetMyProgressRequest
  ): Promise<ResultSummary> {
    const response = await this.getMyProgressRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get details of specific Test Plan
   * Get Test Plan
   */
  async getTestPlanRaw(
    requestParameters: GetTestPlanRequest
  ): Promise<runtime.ApiResponse<TestPlan>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getTestPlan."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.showUserTimeTaken !== undefined) {
      queryParameters["show_user_time_taken"] =
        requestParameters.showUserTimeTaken;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplans/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestPlanFromJSON(jsonValue)
    );
  }

  /**
   * Get details of specific Test Plan
   * Get Test Plan
   */
  async getTestPlan(requestParameters: GetTestPlanRequest): Promise<TestPlan> {
    const response = await this.getTestPlanRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get id(s) of test plan(s) that match the filter criteria
   * Get matching test plans' id(s)
   */
  async getTestPlanIdsRaw(
    requestParameters: GetTestPlanIdsRequest
  ): Promise<runtime.ApiResponse<FetchIdResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplans/fetchIds`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: FetchIdPayloadToJSON(requestParameters.fetchIdPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      FetchIdResultFromJSON(jsonValue)
    );
  }

  /**
   * Get id(s) of test plan(s) that match the filter criteria
   * Get matching test plans' id(s)
   */
  async getTestPlanIds(
    requestParameters: GetTestPlanIdsRequest
  ): Promise<FetchIdResult> {
    const response = await this.getTestPlanIdsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get details of test plans under a specific project
   * Get test plans
   */
  async getTestPlansRaw(
    requestParameters: GetTestPlansRequest
  ): Promise<runtime.ApiResponse<Array<TestPlan>>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestPlans."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplans`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(TestPlanFromJSON)
    );
  }

  /**
   * Get details of test plans under a specific project
   * Get test plans
   */
  async getTestPlans(
    requestParameters: GetTestPlansRequest
  ): Promise<Array<TestPlan>> {
    const response = await this.getTestPlansRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get specific test plan cases validation statuses
   * Get specific Test Plan cases validation statuses
   */
  async getValidationStatusesRaw(
    requestParameters: GetValidationStatusesRequest
  ): Promise<runtime.ApiResponse<Array<PlanCaseValidationStatusCount>>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getValidationStatuses."
      );
    }

    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getValidationStatuses."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplans/{id}/validationStatuses`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(PlanCaseValidationStatusCountFromJSON)
    );
  }

  /**
   * Get specific test plan cases validation statuses
   * Get specific Test Plan cases validation statuses
   */
  async getValidationStatuses(
    requestParameters: GetValidationStatusesRequest
  ): Promise<Array<PlanCaseValidationStatusCount>> {
    const response = await this.getValidationStatusesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Reopenes specific test plan
   * Re-open given test plan
   */
  async reopenTestPlanRaw(
    requestParameters: ReopenTestPlanRequest
  ): Promise<runtime.ApiResponse<TestPlan>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling reopenTestPlan."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplan/reopen/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestPlanFromJSON(jsonValue)
    );
  }

  /**
   * Reopenes specific test plan
   * Re-open given test plan
   */
  async reopenTestPlan(
    requestParameters: ReopenTestPlanRequest
  ): Promise<TestPlan> {
    const response = await this.reopenTestPlanRaw(requestParameters);
    return await response.value();
  }

  /**
   * Bulk actions on test plans
   */
  async testPlanBulkActionsRaw(
    requestParameters: TestPlanBulkActionsRequest
  ): Promise<runtime.ApiResponse<BulkActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplans/bulkAction`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TestPlanBulkActionPayloadToJSON(
        requestParameters.testPlanBulkActionPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      BulkActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Bulk actions on test plans
   */
  async testPlanBulkActions(
    requestParameters: TestPlanBulkActionsRequest
  ): Promise<BulkActionResult> {
    const response = await this.testPlanBulkActionsRaw(requestParameters);
    return await response.value();
  }
}
