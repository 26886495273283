// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  BulkActionResult,
  BulkActionResultFromJSON,
  BulkActionResultToJSON,
  Count,
  CountFromJSON,
  CountToJSON,
  DefaultResponseError,
  DefaultResponseErrorFromJSON,
  DefaultResponseErrorToJSON,
  ForbiddenError,
  ForbiddenErrorFromJSON,
  ForbiddenErrorToJSON,
  Issue,
  IssueFromJSON,
  IssueToJSON,
  IssueBulkActionPayload,
  IssueBulkActionPayloadFromJSON,
  IssueBulkActionPayloadToJSON,
  IssuePayload,
  IssuePayloadFromJSON,
  IssuePayloadToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON,
  UpdateAttachmentPayload,
  UpdateAttachmentPayloadFromJSON,
  UpdateAttachmentPayloadToJSON
} from "../models";

export interface CreateIssueRequest {
  issuePayload?: IssuePayload;
}

export interface DeleteIssueRequest {
  id: number;
  project: number;
}

export interface GetIssueRequest {
  id: number;
  project: number;
}

export interface GetIssueCountRequest {
  project: number;
  filter?: string;
}

export interface GetIssuesRequest {
  project: number;
  limit?: number;
  start?: number;
  sort?: string;
  filter?: string;
}

export interface IssueBulkActionRequest {
  issueBulkActionPayload?: IssueBulkActionPayload;
}

export interface UpdateIssueRequest {
  id: number;
  issuePayload?: IssuePayload;
}

export interface UpdateIssueAttachmentsRequest {
  id: number;
  updateAttachmentPayload?: UpdateAttachmentPayload;
}

/**
 * no description
 */
export class IssuesApi extends runtime.BaseAPI {
  /**
   * Issue to add
   * Create a new issue
   */
  async createIssueRaw(
    requestParameters: CreateIssueRequest
  ): Promise<runtime.ApiResponse<Issue>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/issues`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: IssuePayloadToJSON(requestParameters.issuePayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      IssueFromJSON(jsonValue)
    );
  }

  /**
   * Issue to add
   * Create a new issue
   */
  async createIssue(requestParameters: CreateIssueRequest): Promise<Issue> {
    const response = await this.createIssueRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes a specific issue
   * Delete Issue
   */
  async deleteIssueRaw(
    requestParameters: DeleteIssueRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteIssue."
      );
    }

    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling deleteIssue."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/issues/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deletes a specific issue
   * Delete Issue
   */
  async deleteIssue(requestParameters: DeleteIssueRequest): Promise<any> {
    const response = await this.deleteIssueRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get details of a specific issue
   * Get issue
   */
  async getIssueRaw(
    requestParameters: GetIssueRequest
  ): Promise<runtime.ApiResponse<Issue>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getIssue."
      );
    }

    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getIssue."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/issues/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      IssueFromJSON(jsonValue)
    );
  }

  /**
   * Get details of a specific issue
   * Get issue
   */
  async getIssue(requestParameters: GetIssueRequest): Promise<Issue> {
    const response = await this.getIssueRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get count of issues for specific project
   * Get issues count
   */
  async getIssueCountRaw(
    requestParameters: GetIssueCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getIssueCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/issues/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get count of issues for specific project
   * Get issues count
   */
  async getIssueCount(requestParameters: GetIssueCountRequest): Promise<Count> {
    const response = await this.getIssueCountRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get issues specific to a project
   * Get issues
   */
  async getIssuesRaw(
    requestParameters: GetIssuesRequest
  ): Promise<runtime.ApiResponse<Array<Issue>>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getIssues."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/issues`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(IssueFromJSON)
    );
  }

  /**
   * Get issues specific to a project
   * Get issues
   */
  async getIssues(requestParameters: GetIssuesRequest): Promise<Array<Issue>> {
    const response = await this.getIssuesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Bulk action on issues
   * Issues Bulk action
   */
  async issueBulkActionRaw(
    requestParameters: IssueBulkActionRequest
  ): Promise<runtime.ApiResponse<BulkActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/issues/bulkAction`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: IssueBulkActionPayloadToJSON(
        requestParameters.issueBulkActionPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      BulkActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Bulk action on issues
   * Issues Bulk action
   */
  async issueBulkAction(
    requestParameters: IssueBulkActionRequest
  ): Promise<BulkActionResult> {
    const response = await this.issueBulkActionRaw(requestParameters);
    return await response.value();
  }

  /**
   * Issue to update
   * Updates a issue
   */
  async updateIssueRaw(
    requestParameters: UpdateIssueRequest
  ): Promise<runtime.ApiResponse<Issue>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateIssue."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/issues/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: IssuePayloadToJSON(requestParameters.issuePayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      IssueFromJSON(jsonValue)
    );
  }

  /**
   * Issue to update
   * Updates a issue
   */
  async updateIssue(requestParameters: UpdateIssueRequest): Promise<Issue> {
    const response = await this.updateIssueRaw(requestParameters);
    return await response.value();
  }

  /**
   * Issue to update
   * Update issue attachments
   */
  async updateIssueAttachmentsRaw(
    requestParameters: UpdateIssueAttachmentsRequest
  ): Promise<runtime.ApiResponse<Issue>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateIssueAttachments."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/issues/{id}/updateAttachments`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: UpdateAttachmentPayloadToJSON(
        requestParameters.updateAttachmentPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      IssueFromJSON(jsonValue)
    );
  }

  /**
   * Issue to update
   * Update issue attachments
   */
  async updateIssueAttachments(
    requestParameters: UpdateIssueAttachmentsRequest
  ): Promise<Issue> {
    const response = await this.updateIssueAttachmentsRaw(requestParameters);
    return await response.value();
  }
}
