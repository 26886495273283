import * as _ from "lodash";
import moment from "moment-timezone";
import { getManageOptions, getRoleOptions } from "./common";
export const getIntercomSettings = (loggedInUser = null, company = null) => {
  let intercomSettings = {
    app_id: process.env.INTERCOM_APP_ID,
    custom_launcher_selector: "#launch_intercom_link"
  };
  if (
    loggedInUser &&
    loggedInUser.id &&
    loggedInUser.name &&
    !loggedInUser.isGuest
  ) {
    intercomSettings["rank"] = loggedInUser.rank;
    intercomSettings["name"] = loggedInUser.name;
    intercomSettings["email"] = loggedInUser.email;
    intercomSettings["user_id"] = loggedInUser.id;
    intercomSettings["is_superadmin"] =
      loggedInUser.id === _.get(company, "owner.id");
    //intercomSettings["created_at"] = moment(loggedInUser.createdAt).unix();

    if (company && company.id && company.name) {
      intercomSettings["company"] = {};
      intercomSettings["company"]["created_at"] = moment(company.createdAt)
        .utc()
        .unix();
      let compInfo = _.get(company, "otherInfo", {});
      let roleOptions = getRoleOptions();
      let manageOptions = getManageOptions();
      intercomSettings["company"]["company_id"] = company.id;
      if (compInfo != undefined && Object.keys(compInfo).length) {
        intercomSettings["company"]["team_size"] =
          compInfo && compInfo.teamSize ? compInfo.teamSize : "N/A";
        //intercomSettings["company_teamsize"] = intercomSettings["company"]["team_size"];
        //intercomSettings["company"]["company_size"] = compInfo && compInfo.companySize ? compInfo.companySize : "N/A";
        intercomSettings["company"]["size"] =
          compInfo && compInfo.companySize ? compInfo.companySize : "N/A";
        //intercomSettings["company_size"] = intercomSettings["company"]["company_size"];
        intercomSettings["company"]["role"] =
          compInfo && compInfo.role
            ? roleOptions[compInfo.role] != undefined
              ? roleOptions[compInfo.role]
              : compInfo.role
            : "N/A";
        //intercomSettings["company_role"] = intercomSettings["company"]["role"];
        // intercomSettings["company"]["manages"] = compInfo && compInfo.manages ? manageOptions[compInfo.manages] != undefined ? manageOptions[compInfo.manages] : compInfo.manages : "N/A";
        intercomSettings["company"]["industry"] =
          compInfo && compInfo.manages
            ? manageOptions[compInfo.manages] != undefined
              ? manageOptions[compInfo.manages]
              : compInfo.manages
            : "N/A";
        //intercomSettings["company_manages"] = intercomSettings["company"]["manages"];
        intercomSettings["company"]["phone"] =
          compInfo && compInfo.phone != undefined && compInfo.phone.length
            ? compInfo.phone
            : "";
      }
      if (company.phone != undefined && company.phone.length) {
        intercomSettings["company"]["phone"] = company.phone;
      }
      intercomSettings["company"]["name"] = company.name;
      intercomSettings["company"]["plan"] = company.plan;
      intercomSettings["company"]["is_trialing"] =
        company.trialDetails.isActive;
      intercomSettings["company"]["trial_opted"] = company.trialOpted;
      intercomSettings["company"]["trial_started_on"] = company.trialDetails
        .isActive
        ? moment(company.trialStartedOn)
            .utc()
            .unix()
        : null;
      intercomSettings["company"]["term"] =
        company.paymentProfile && company.paymentProfile.billingCycle
          ? company.paymentProfile.billingCycle
          : null;
      intercomSettings["company"]["seats"] =
        company.paymentProfile && company.paymentProfile.users
          ? company.paymentProfile.users
          : null;
      intercomSettings["company"]["projects_count"] =
        company.stats && company.stats.projects ? company.stats.projects : null;
      intercomSettings["company"]["users_count"] =
        company.stats && company.stats.users ? company.stats.users : null;
      intercomSettings["company"]["testcases_count"] =
        company.stats && company.stats.testCases ? company.stats.testCases : 0;
      intercomSettings["company"]["testplans_count"] =
        company.stats && company.stats.testPlans ? company.stats.testPlans : 0;
      intercomSettings["company"]["customfields_count"] =
        company.stats && company.stats.customFields
          ? company.stats.customFields
          : 0;
      intercomSettings["company"]["configurations_count"] =
        company.stats && company.stats.configurations
          ? company.stats.configurations
          : 0;
      intercomSettings["company"]["runs_count"] =
        company.stats && company.stats.runs ? company.stats.runs : 0;
      intercomSettings["company"]["issuemanager_integrated"] =
        company.stats &&
        company.stats.issueManagers &&
        company.stats.issueManagers.length
          ? true
          : false;
      intercomSettings["company"]["issuemanagers"] =
        company.stats &&
        company.stats.issueManagers &&
        company.stats.issueManagers.length
          ? company.stats.issueManagers.join(",")
          : 0;
      //intercomSettings["company"]["executedcases_count"] = company.stats && company.stats.executedCases ? company.stats.executedCases : null;

      let planStatus =
        company.planStatus == "active" ? company.planStatus : null;
      if (!planStatus) {
        if (company.trialDetails && company.trialDetails.isActive) {
          planStatus = "trialing";
        } else if (company.plan != "free") {
          planStatus = company.planStatus;
        }
      }
      intercomSettings["company"]["plan_status"] = planStatus;
      intercomSettings["company"]["qac_enabled"] = false;
      if (
        company.qacFeatureEnabled &&
        company.copilotUser &&
        company.copilotUser.id
      ) {
        intercomSettings["company"]["qac_enabled"] = true;
      }
    }
  }
  return intercomSettings;
};
