// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  Count,
  CountFromJSON,
  CountToJSON,
  GenericActionResult,
  GenericActionResultFromJSON,
  GenericActionResultToJSON,
  IssueManagerLinkPayload,
  IssueManagerLinkPayloadFromJSON,
  IssueManagerLinkPayloadToJSON,
  Requirement,
  RequirementFromJSON,
  RequirementToJSON,
  RequirementProfile,
  RequirementProfileFromJSON,
  RequirementProfileToJSON,
  RequirementProfilePayload,
  RequirementProfilePayloadFromJSON,
  RequirementProfilePayloadToJSON,
  RequirementResource,
  RequirementResourceFromJSON,
  RequirementResourceToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON
} from "../models";

export interface CreateRequirementProfileRequest {
  requirementProfilePayload?: RequirementProfilePayload;
}

export interface DeleteRequirementProfileRequest {
  id: number;
}

export interface GetRequirementCountRequest {
  project: number;
  filter?: string;
}

export interface GetRequirementProfileRequest {
  id: number;
}

export interface GetRequirementProfileCountRequest {
  project: number;
  filter?: string;
}

export interface GetRequirementProfilesRequest {
  project?: number;
  limit?: number;
  sort?: string;
  start?: number;
  filter?: string;
}

export interface GetRequirementResourceRequest {
  project: number;
  limit?: number;
  sort?: string;
  start?: number;
  filter?: string;
}

export interface LinkRequirementManagerRequest {
  issueManagerLinkPayload?: IssueManagerLinkPayload;
}

export interface SearchRequirementIssuesRequest {
  project: number;
  limit?: number;
  sort?: string;
  start?: number;
  filter?: string;
}

export interface UpdateRequirementProfileRequest {
  id: number;
  requirementProfilePayload?: RequirementProfilePayload;
}

/**
 * no description
 */
export class RequirementProfilesApi extends runtime.BaseAPI {
  /**
   * requirement Profile to add
   * Create a new requirement profile
   */
  async createRequirementProfileRaw(
    requestParameters: CreateRequirementProfileRequest
  ): Promise<runtime.ApiResponse<RequirementProfile>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/requirementprofiles`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: RequirementProfilePayloadToJSON(
        requestParameters.requirementProfilePayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      RequirementProfileFromJSON(jsonValue)
    );
  }

  /**
   * requirement Profile to add
   * Create a new requirement profile
   */
  async createRequirementProfile(
    requestParameters: CreateRequirementProfileRequest
  ): Promise<RequirementProfile> {
    const response = await this.createRequirementProfileRaw(requestParameters);
    return await response.value();
  }

  /**
   * na
   * Deletes given requirement profile
   */
  async deleteRequirementProfileRaw(
    requestParameters: DeleteRequirementProfileRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteRequirementProfile."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/requirementprofiles/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * na
   * Deletes given requirement profile
   */
  async deleteRequirementProfile(
    requestParameters: DeleteRequirementProfileRequest
  ): Promise<any> {
    const response = await this.deleteRequirementProfileRaw(requestParameters);
    return await response.value();
  }

  /**
   * na
   * Get count of project's requirement from linked requirement manager
   */
  async getRequirementCountRaw(
    requestParameters: GetRequirementCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getRequirementCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/requirementprofiles/getRequirementCount`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * na
   * Get count of project's requirement from linked requirement manager
   */
  async getRequirementCount(
    requestParameters: GetRequirementCountRequest
  ): Promise<Count> {
    const response = await this.getRequirementCountRaw(requestParameters);
    return await response.value();
  }

  /**
   * na
   * Get requirement profile
   */
  async getRequirementProfileRaw(
    requestParameters: GetRequirementProfileRequest
  ): Promise<runtime.ApiResponse<RequirementProfile>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getRequirementProfile."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/requirementprofiles/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      RequirementProfileFromJSON(jsonValue)
    );
  }

  /**
   * na
   * Get requirement profile
   */
  async getRequirementProfile(
    requestParameters: GetRequirementProfileRequest
  ): Promise<RequirementProfile> {
    const response = await this.getRequirementProfileRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get count of requirement profiles
   * Get count of requirement profiles
   */
  async getRequirementProfileCountRaw(
    requestParameters: GetRequirementProfileCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getRequirementProfileCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/requirementprofiles/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get count of requirement profiles
   * Get count of requirement profiles
   */
  async getRequirementProfileCount(
    requestParameters: GetRequirementProfileCountRequest
  ): Promise<Count> {
    const response = await this.getRequirementProfileCountRaw(
      requestParameters
    );
    return await response.value();
  }

  /**
   * Get list of requirement profiles
   * Get list of requirement profiles
   */
  async getRequirementProfilesRaw(
    requestParameters: GetRequirementProfilesRequest
  ): Promise<runtime.ApiResponse<Array<RequirementProfile>>> {
    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/requirementprofiles`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(RequirementProfileFromJSON)
    );
  }

  /**
   * Get list of requirement profiles
   * Get list of requirement profiles
   */
  async getRequirementProfiles(
    requestParameters: GetRequirementProfilesRequest
  ): Promise<Array<RequirementProfile>> {
    const response = await this.getRequirementProfilesRaw(requestParameters);
    return await response.value();
  }

  /**
   * na
   * Get list of projects and other fields of requirement
   */
  async getRequirementResourceRaw(
    requestParameters: GetRequirementResourceRequest
  ): Promise<runtime.ApiResponse<RequirementResource>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getRequirementResource."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/requirementprofiles/getResource`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      RequirementResourceFromJSON(jsonValue)
    );
  }

  /**
   * na
   * Get list of projects and other fields of requirement
   */
  async getRequirementResource(
    requestParameters: GetRequirementResourceRequest
  ): Promise<RequirementResource> {
    const response = await this.getRequirementResourceRaw(requestParameters);
    return await response.value();
  }

  /**
   * Link Issue Manager
   * Link requirement manager
   */
  async linkRequirementManagerRaw(
    requestParameters: LinkRequirementManagerRequest
  ): Promise<runtime.ApiResponse<GenericActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/requirementprofiles/link`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: IssueManagerLinkPayloadToJSON(
        requestParameters.issueManagerLinkPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      GenericActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Link Issue Manager
   * Link requirement manager
   */
  async linkRequirementManager(
    requestParameters: LinkRequirementManagerRequest
  ): Promise<GenericActionResult> {
    const response = await this.linkRequirementManagerRaw(requestParameters);
    return await response.value();
  }

  /**
   * na
   * Get list of requirement by searching the linked requirement manager
   */
  async searchRequirementIssuesRaw(
    requestParameters: SearchRequirementIssuesRequest
  ): Promise<runtime.ApiResponse<Array<Requirement>>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling searchRequirementIssues."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/requirementprofiles/searchIssues`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(RequirementFromJSON)
    );
  }

  /**
   * na
   * Get list of requirement by searching the linked requirement manager
   */
  async searchRequirementIssues(
    requestParameters: SearchRequirementIssuesRequest
  ): Promise<Array<Requirement>> {
    const response = await this.searchRequirementIssuesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Requirement Profile to update
   * Updates a requirement profile
   */
  async updateRequirementProfileRaw(
    requestParameters: UpdateRequirementProfileRequest
  ): Promise<runtime.ApiResponse<RequirementProfile>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateRequirementProfile."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/requirementprofiles/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: RequirementProfilePayloadToJSON(
        requestParameters.requirementProfilePayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      RequirementProfileFromJSON(jsonValue)
    );
  }

  /**
   * Requirement Profile to update
   * Updates a requirement profile
   */
  async updateRequirementProfile(
    requestParameters: UpdateRequirementProfileRequest
  ): Promise<RequirementProfile> {
    const response = await this.updateRequirementProfileRaw(requestParameters);
    return await response.value();
  }
}
