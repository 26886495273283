// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  GenericActionResult,
  GenericActionResultFromJSON,
  GenericActionResultToJSON,
  Setting,
  SettingFromJSON,
  SettingToJSON,
  SettingPayload,
  SettingPayloadFromJSON,
  SettingPayloadToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON
} from "../models";

export interface DeleteSettingRequest {
  company: number;
  settingType?: string;
  filter?: string;
}

export interface GetSettingsRequest {
  company: number;
  limit?: number;
  filter?: string;
  settingType?: string;
}

export interface SetSettingsRequest {
  settingPayload?: SettingPayload;
}

/**
 * no description
 */
export class SettingsApi extends runtime.BaseAPI {
  /**
   * Deletes settings for a specific project or a global setting
   * Delete setting
   */
  async deleteSettingRaw(
    requestParameters: DeleteSettingRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling deleteSetting."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.settingType !== undefined) {
      queryParameters["setting_type"] = requestParameters.settingType;
    }

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/settings`,
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deletes settings for a specific project or a global setting
   * Delete setting
   */
  async deleteSetting(requestParameters: DeleteSettingRequest): Promise<any> {
    const response = await this.deleteSettingRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get list of settings specific to a company
   * Get settings
   */
  async getSettingsRaw(
    requestParameters: GetSettingsRequest
  ): Promise<runtime.ApiResponse<Array<Setting>>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getSettings."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    if (requestParameters.settingType !== undefined) {
      queryParameters["setting_type"] = requestParameters.settingType;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/settings`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(SettingFromJSON)
    );
  }

  /**
   * Get list of settings specific to a company
   * Get settings
   */
  async getSettings(
    requestParameters: GetSettingsRequest
  ): Promise<Array<Setting>> {
    const response = await this.getSettingsRaw(requestParameters);
    return await response.value();
  }

  /**
   * settings to save
   * Save settings
   */
  async setSettingsRaw(
    requestParameters: SetSettingsRequest
  ): Promise<runtime.ApiResponse<GenericActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/settings`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: SettingPayloadToJSON(requestParameters.settingPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      GenericActionResultFromJSON(jsonValue)
    );
  }

  /**
   * settings to save
   * Save settings
   */
  async setSettings(
    requestParameters: SetSettingsRequest
  ): Promise<GenericActionResult> {
    const response = await this.setSettingsRaw(requestParameters);
    return await response.value();
  }
}
