// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  Count,
  CountFromJSON,
  CountToJSON,
  DefaultResponseError,
  DefaultResponseErrorFromJSON,
  DefaultResponseErrorToJSON,
  ForbiddenError,
  ForbiddenErrorFromJSON,
  ForbiddenErrorToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON,
  TestPlanComment,
  TestPlanCommentFromJSON,
  TestPlanCommentToJSON,
  TestPlanCommentPayload,
  TestPlanCommentPayloadFromJSON,
  TestPlanCommentPayloadToJSON
} from "../models";

export interface AddTestPlanCommentRequest {
  testPlanCommentPayload?: TestPlanCommentPayload;
}

export interface DeleteTestPlanCommentRequest {
  id: number;
  project: number;
}

export interface GetTestPlanCommentRequest {
  id: number;
  project: number;
}

export interface GetTestPlanCommentsRequest {
  testPlan: number;
  project: number;
  limit?: number;
  start?: number;
  sort?: string;
  filter?: string;
}

export interface GetTestPlanCommentsCountRequest {
  project: number;
  testPlan: number;
}

export interface UpdateTestPlanCommentRequest {
  id: number;
  testPlanCommentPayload?: TestPlanCommentPayload;
}

/**
 * no description
 */
export class TestplansCommentsApi extends runtime.BaseAPI {
  /**
   * Adds a new test plan comment
   * Add test plan comment
   */
  async addTestPlanCommentRaw(
    requestParameters: AddTestPlanCommentRequest
  ): Promise<runtime.ApiResponse<TestPlanComment>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplancomments`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TestPlanCommentPayloadToJSON(
        requestParameters.testPlanCommentPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestPlanCommentFromJSON(jsonValue)
    );
  }

  /**
   * Adds a new test plan comment
   * Add test plan comment
   */
  async addTestPlanComment(
    requestParameters: AddTestPlanCommentRequest
  ): Promise<TestPlanComment> {
    const response = await this.addTestPlanCommentRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes specific test plan comment
   * Delete test plan comment
   */
  async deleteTestPlanCommentRaw(
    requestParameters: DeleteTestPlanCommentRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteTestPlanComment."
      );
    }

    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling deleteTestPlanComment."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplancomments/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deletes specific test plan comment
   * Delete test plan comment
   */
  async deleteTestPlanComment(
    requestParameters: DeleteTestPlanCommentRequest
  ): Promise<any> {
    const response = await this.deleteTestPlanCommentRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get specific test plan comment
   * Get test plan comment
   */
  async getTestPlanCommentRaw(
    requestParameters: GetTestPlanCommentRequest
  ): Promise<runtime.ApiResponse<TestPlanComment>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getTestPlanComment."
      );
    }

    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestPlanComment."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplancomments/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestPlanCommentFromJSON(jsonValue)
    );
  }

  /**
   * Get specific test plan comment
   * Get test plan comment
   */
  async getTestPlanComment(
    requestParameters: GetTestPlanCommentRequest
  ): Promise<TestPlanComment> {
    const response = await this.getTestPlanCommentRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get comments for a specific test plan
   * Get test plan comments
   */
  async getTestPlanCommentsRaw(
    requestParameters: GetTestPlanCommentsRequest
  ): Promise<runtime.ApiResponse<Array<TestPlanComment>>> {
    if (
      requestParameters.testPlan === null ||
      requestParameters.testPlan === undefined
    ) {
      throw new runtime.RequiredError(
        "testPlan",
        "Required parameter requestParameters.testPlan was null or undefined when calling getTestPlanComments."
      );
    }

    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestPlanComments."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.testPlan !== undefined) {
      queryParameters["test_plan"] = requestParameters.testPlan;
    }

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplancomments`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(TestPlanCommentFromJSON)
    );
  }

  /**
   * Get comments for a specific test plan
   * Get test plan comments
   */
  async getTestPlanComments(
    requestParameters: GetTestPlanCommentsRequest
  ): Promise<Array<TestPlanComment>> {
    const response = await this.getTestPlanCommentsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get test plan comments' count
   * Get test plan comments' count
   */
  async getTestPlanCommentsCountRaw(
    requestParameters: GetTestPlanCommentsCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestPlanCommentsCount."
      );
    }

    if (
      requestParameters.testPlan === null ||
      requestParameters.testPlan === undefined
    ) {
      throw new runtime.RequiredError(
        "testPlan",
        "Required parameter requestParameters.testPlan was null or undefined when calling getTestPlanCommentsCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.testPlan !== undefined) {
      queryParameters["test_plan"] = requestParameters.testPlan;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplancomments/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get test plan comments' count
   * Get test plan comments' count
   */
  async getTestPlanCommentsCount(
    requestParameters: GetTestPlanCommentsCountRequest
  ): Promise<Count> {
    const response = await this.getTestPlanCommentsCountRaw(requestParameters);
    return await response.value();
  }

  /**
   * Test Plan to update
   * Updates a test plan comment
   */
  async updateTestPlanCommentRaw(
    requestParameters: UpdateTestPlanCommentRequest
  ): Promise<runtime.ApiResponse<TestPlanComment>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateTestPlanComment."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplancomments/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: TestPlanCommentPayloadToJSON(
        requestParameters.testPlanCommentPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestPlanCommentFromJSON(jsonValue)
    );
  }

  /**
   * Test Plan to update
   * Updates a test plan comment
   */
  async updateTestPlanComment(
    requestParameters: UpdateTestPlanCommentRequest
  ): Promise<TestPlanComment> {
    const response = await this.updateTestPlanCommentRaw(requestParameters);
    return await response.value();
  }
}
