// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  Count,
  CountFromJSON,
  CountToJSON,
  DefaultResponseError,
  DefaultResponseErrorFromJSON,
  DefaultResponseErrorToJSON,
  ForbiddenError,
  ForbiddenErrorFromJSON,
  ForbiddenErrorToJSON,
  ReusableStep,
  ReusableStepFromJSON,
  ReusableStepToJSON,
  ReusableStepPayload,
  ReusableStepPayloadFromJSON,
  ReusableStepPayloadToJSON
} from "../models";

export interface DeleteReusableStepRequest {
  project: number;
  id: number;
}

export interface GetAllReusableStepsRequest {
  project: number;
  limit?: number;
  start?: number;
  sort?: string;
  filter?: string;
}

export interface GetReusableStepRequest {
  project: number;
  id: number;
}

export interface GetReusablestepsCountRequest {
  project: number;
}

export interface SaveReusableStepRequest {
  project: number;
  reusableStepPayload?: ReusableStepPayload;
}

export interface UpdateReusableStepRequest {
  id: number;
  reusableStepPayload?: ReusableStepPayload;
}

/**
 * no description
 */
export class ReusableStepsApi extends runtime.BaseAPI {
  /**
   * Deletes a set of reusable steps
   * Delete reusable steps
   */
  async deleteReusableStepRaw(
    requestParameters: DeleteReusableStepRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling deleteReusableStep."
      );
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteReusableStep."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/reusablesteps/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deletes a set of reusable steps
   * Delete reusable steps
   */
  async deleteReusableStep(
    requestParameters: DeleteReusableStepRequest
  ): Promise<any> {
    const response = await this.deleteReusableStepRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get complete list of reusable steps (with paging)
   * Get list of reusable steps
   */
  async getAllReusableStepsRaw(
    requestParameters: GetAllReusableStepsRequest
  ): Promise<runtime.ApiResponse<Array<ReusableStep>>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getAllReusableSteps."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/reusablesteps`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(ReusableStepFromJSON)
    );
  }

  /**
   * Get complete list of reusable steps (with paging)
   * Get list of reusable steps
   */
  async getAllReusableSteps(
    requestParameters: GetAllReusableStepsRequest
  ): Promise<Array<ReusableStep>> {
    const response = await this.getAllReusableStepsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get details of specific reusable step
   * Get reusable step
   */
  async getReusableStepRaw(
    requestParameters: GetReusableStepRequest
  ): Promise<runtime.ApiResponse<ReusableStep>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getReusableStep."
      );
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getReusableStep."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/reusablesteps/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ReusableStepFromJSON(jsonValue)
    );
  }

  /**
   * Get details of specific reusable step
   * Get reusable step
   */
  async getReusableStep(
    requestParameters: GetReusableStepRequest
  ): Promise<ReusableStep> {
    const response = await this.getReusableStepRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get reusable steps' count
   * Get reusable steps' count
   */
  async getReusablestepsCountRaw(
    requestParameters: GetReusablestepsCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getReusablestepsCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/reusablesteps/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get reusable steps' count
   * Get reusable steps' count
   */
  async getReusablestepsCount(
    requestParameters: GetReusablestepsCountRequest
  ): Promise<Count> {
    const response = await this.getReusablestepsCountRaw(requestParameters);
    return await response.value();
  }

  /**
   * Adds a new set of reusable steps
   * Add reusable steps
   */
  async saveReusableStepRaw(
    requestParameters: SaveReusableStepRequest
  ): Promise<runtime.ApiResponse<ReusableStep>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling saveReusableStep."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/reusablesteps`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ReusableStepPayloadToJSON(requestParameters.reusableStepPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ReusableStepFromJSON(jsonValue)
    );
  }

  /**
   * Adds a new set of reusable steps
   * Add reusable steps
   */
  async saveReusableStep(
    requestParameters: SaveReusableStepRequest
  ): Promise<ReusableStep> {
    const response = await this.saveReusableStepRaw(requestParameters);
    return await response.value();
  }

  /**
   * Updates a set of reusable steps
   * Update reusable steps
   */
  async updateReusableStepRaw(
    requestParameters: UpdateReusableStepRequest
  ): Promise<runtime.ApiResponse<ReusableStep>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateReusableStep."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/reusablesteps/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: ReusableStepPayloadToJSON(requestParameters.reusableStepPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ReusableStepFromJSON(jsonValue)
    );
  }

  /**
   * Updates a set of reusable steps
   * Update reusable steps
   */
  async updateReusableStep(
    requestParameters: UpdateReusableStepRequest
  ): Promise<ReusableStep> {
    const response = await this.updateReusableStepRaw(requestParameters);
    return await response.value();
  }
}
