// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  Count,
  CountFromJSON,
  CountToJSON,
  ProjectUser,
  ProjectUserFromJSON,
  ProjectUserToJSON,
  ProjectUserPayload,
  ProjectUserPayloadFromJSON,
  ProjectUserPayloadToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON
} from "../models";

export interface CreateProjectUserRequest {
  projectUserPayload?: ProjectUserPayload;
}

export interface DeleteProjectUserRequest {
  id: number;
}

export interface GetProjectUserRequest {
  id: number;
}

export interface GetProjectUserCountRequest {
  project: number;
}

export interface GetProjectUsersRequest {
  project: number;
  limit?: number;
  start?: number;
  sort?: string;
  filter?: string;
}

export interface UpdateProjectUserRequest {
  id: number;
  projectUserPayload?: ProjectUserPayload;
}

/**
 * no description
 */
export class ProjectUsersApi extends runtime.BaseAPI {
  /**
   * Project Member to add
   * Create a new project user
   */
  async createProjectUserRaw(
    requestParameters: CreateProjectUserRequest
  ): Promise<runtime.ApiResponse<ProjectUser>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/projectusers`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ProjectUserPayloadToJSON(requestParameters.projectUserPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ProjectUserFromJSON(jsonValue)
    );
  }

  /**
   * Project Member to add
   * Create a new project user
   */
  async createProjectUser(
    requestParameters: CreateProjectUserRequest
  ): Promise<ProjectUser> {
    const response = await this.createProjectUserRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes a specific project member
   * Delete project member
   */
  async deleteProjectUserRaw(
    requestParameters: DeleteProjectUserRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteProjectUser."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/projectusers/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deletes a specific project member
   * Delete project member
   */
  async deleteProjectUser(
    requestParameters: DeleteProjectUserRequest
  ): Promise<any> {
    const response = await this.deleteProjectUserRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get details of a specific project member
   * Get project member
   */
  async getProjectUserRaw(
    requestParameters: GetProjectUserRequest
  ): Promise<runtime.ApiResponse<ProjectUser>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getProjectUser."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/projectusers/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ProjectUserFromJSON(jsonValue)
    );
  }

  /**
   * Get details of a specific project member
   * Get project member
   */
  async getProjectUser(
    requestParameters: GetProjectUserRequest
  ): Promise<ProjectUser> {
    const response = await this.getProjectUserRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get project members' count
   * Get project members' count
   */
  async getProjectUserCountRaw(
    requestParameters: GetProjectUserCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getProjectUserCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/projectusers/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get project members' count
   * Get project members' count
   */
  async getProjectUserCount(
    requestParameters: GetProjectUserCountRequest
  ): Promise<Count> {
    const response = await this.getProjectUserCountRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get details of members in a project
   * Get project users' list
   */
  async getProjectUsersRaw(
    requestParameters: GetProjectUsersRequest
  ): Promise<runtime.ApiResponse<Array<ProjectUser>>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getProjectUsers."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/projectusers`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(ProjectUserFromJSON)
    );
  }

  /**
   * Get details of members in a project
   * Get project users' list
   */
  async getProjectUsers(
    requestParameters: GetProjectUsersRequest
  ): Promise<Array<ProjectUser>> {
    const response = await this.getProjectUsersRaw(requestParameters);
    return await response.value();
  }

  /**
   * Project Member to update
   * Updates a project user
   */
  async updateProjectUserRaw(
    requestParameters: UpdateProjectUserRequest
  ): Promise<runtime.ApiResponse<ProjectUser>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateProjectUser."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/projectusers/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: ProjectUserPayloadToJSON(requestParameters.projectUserPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      ProjectUserFromJSON(jsonValue)
    );
  }

  /**
   * Project Member to update
   * Updates a project user
   */
  async updateProjectUser(
    requestParameters: UpdateProjectUserRequest
  ): Promise<ProjectUser> {
    const response = await this.updateProjectUserRaw(requestParameters);
    return await response.value();
  }
}
