// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  Count,
  CountFromJSON,
  CountToJSON,
  DefaultResponseError,
  DefaultResponseErrorFromJSON,
  DefaultResponseErrorToJSON,
  ForbiddenError,
  ForbiddenErrorFromJSON,
  ForbiddenErrorToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON,
  TestCaseComment,
  TestCaseCommentFromJSON,
  TestCaseCommentToJSON,
  TestCaseCommentPayload,
  TestCaseCommentPayloadFromJSON,
  TestCaseCommentPayloadToJSON
} from "../models";

export interface AddTestCaseCommentRequest {
  testCaseCommentPayload?: TestCaseCommentPayload;
}

export interface DeleteTestCaseCommentRequest {
  id: number;
  project: number;
}

export interface GetTestCaseCommentRequest {
  id: number;
  project: number;
}

export interface GetTestCaseCommentsRequest {
  testCase: number;
  project: number;
  limit?: number;
  start?: number;
  sort?: string;
  filter?: string;
}

export interface GetTestCaseCommentsCountRequest {
  project: number;
  testCase: number;
}

export interface UpdateTestCaseCommentRequest {
  id: number;
  testCaseCommentPayload?: TestCaseCommentPayload;
}

/**
 * no description
 */
export class TestcasesCommentsApi extends runtime.BaseAPI {
  /**
   * Adds a new test case comment
   * Add test case comment
   */
  async addTestCaseCommentRaw(
    requestParameters: AddTestCaseCommentRequest
  ): Promise<runtime.ApiResponse<TestCaseComment>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcasecomments`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TestCaseCommentPayloadToJSON(
        requestParameters.testCaseCommentPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestCaseCommentFromJSON(jsonValue)
    );
  }

  /**
   * Adds a new test case comment
   * Add test case comment
   */
  async addTestCaseComment(
    requestParameters: AddTestCaseCommentRequest
  ): Promise<TestCaseComment> {
    const response = await this.addTestCaseCommentRaw(requestParameters);
    return await response.value();
  }

  /**
   * Delete a specific comment added for a test case
   * Delete test case comment
   */
  async deleteTestCaseCommentRaw(
    requestParameters: DeleteTestCaseCommentRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteTestCaseComment."
      );
    }

    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling deleteTestCaseComment."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcasecomments/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Delete a specific comment added for a test case
   * Delete test case comment
   */
  async deleteTestCaseComment(
    requestParameters: DeleteTestCaseCommentRequest
  ): Promise<any> {
    const response = await this.deleteTestCaseCommentRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get comments added for a specific test case
   * Get test case comment
   */
  async getTestCaseCommentRaw(
    requestParameters: GetTestCaseCommentRequest
  ): Promise<runtime.ApiResponse<TestCaseComment>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getTestCaseComment."
      );
    }

    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestCaseComment."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcasecomments/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestCaseCommentFromJSON(jsonValue)
    );
  }

  /**
   * Get comments added for a specific test case
   * Get test case comment
   */
  async getTestCaseComment(
    requestParameters: GetTestCaseCommentRequest
  ): Promise<TestCaseComment> {
    const response = await this.getTestCaseCommentRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get comments for a specific test case
   * Get test case comments
   */
  async getTestCaseCommentsRaw(
    requestParameters: GetTestCaseCommentsRequest
  ): Promise<runtime.ApiResponse<Array<TestCaseComment>>> {
    if (
      requestParameters.testCase === null ||
      requestParameters.testCase === undefined
    ) {
      throw new runtime.RequiredError(
        "testCase",
        "Required parameter requestParameters.testCase was null or undefined when calling getTestCaseComments."
      );
    }

    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestCaseComments."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.testCase !== undefined) {
      queryParameters["test_case"] = requestParameters.testCase;
    }

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcasecomments`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(TestCaseCommentFromJSON)
    );
  }

  /**
   * Get comments for a specific test case
   * Get test case comments
   */
  async getTestCaseComments(
    requestParameters: GetTestCaseCommentsRequest
  ): Promise<Array<TestCaseComment>> {
    const response = await this.getTestCaseCommentsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get count of comments added for a specific test case
   * Get test case comments'count
   */
  async getTestCaseCommentsCountRaw(
    requestParameters: GetTestCaseCommentsCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestCaseCommentsCount."
      );
    }

    if (
      requestParameters.testCase === null ||
      requestParameters.testCase === undefined
    ) {
      throw new runtime.RequiredError(
        "testCase",
        "Required parameter requestParameters.testCase was null or undefined when calling getTestCaseCommentsCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.testCase !== undefined) {
      queryParameters["test_case"] = requestParameters.testCase;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcasecomments/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get count of comments added for a specific test case
   * Get test case comments'count
   */
  async getTestCaseCommentsCount(
    requestParameters: GetTestCaseCommentsCountRequest
  ): Promise<Count> {
    const response = await this.getTestCaseCommentsCountRaw(requestParameters);
    return await response.value();
  }

  /**
   * Test Case to update
   * Updates a test case comment
   */
  async updateTestCaseCommentRaw(
    requestParameters: UpdateTestCaseCommentRequest
  ): Promise<runtime.ApiResponse<TestCaseComment>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateTestCaseComment."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcasecomments/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: TestCaseCommentPayloadToJSON(
        requestParameters.testCaseCommentPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestCaseCommentFromJSON(jsonValue)
    );
  }

  /**
   * Test Case to update
   * Updates a test case comment
   */
  async updateTestCaseComment(
    requestParameters: UpdateTestCaseCommentRequest
  ): Promise<TestCaseComment> {
    const response = await this.updateTestCaseCommentRaw(requestParameters);
    return await response.value();
  }
}
