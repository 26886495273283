// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  ChangeSuperAdminPayload,
  ChangeSuperAdminPayloadFromJSON,
  ChangeSuperAdminPayloadToJSON,
  Count,
  CountFromJSON,
  CountToJSON,
  DefaultResponseError,
  DefaultResponseErrorFromJSON,
  DefaultResponseErrorToJSON,
  ForbiddenError,
  ForbiddenErrorFromJSON,
  ForbiddenErrorToJSON,
  ForgotPasswordPayload,
  ForgotPasswordPayloadFromJSON,
  ForgotPasswordPayloadToJSON,
  GenericActionResult,
  GenericActionResultFromJSON,
  GenericActionResultToJSON,
  LoggedInUser,
  LoggedInUserFromJSON,
  LoggedInUserToJSON,
  LoginMethod,
  LoginMethodFromJSON,
  LoginMethodToJSON,
  ProjectsettingMini,
  ProjectsettingMiniFromJSON,
  ProjectsettingMiniToJSON,
  RegisteredUser,
  RegisteredUserFromJSON,
  RegisteredUserToJSON,
  ResetAllPasswordPayload,
  ResetAllPasswordPayloadFromJSON,
  ResetAllPasswordPayloadToJSON,
  ResetPasswordPayload,
  ResetPasswordPayloadFromJSON,
  ResetPasswordPayloadToJSON,
  SendVerificationPayload,
  SendVerificationPayloadFromJSON,
  SendVerificationPayloadToJSON,
  SetupProfilePayload,
  SetupProfilePayloadFromJSON,
  SetupProfilePayloadToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON,
  User,
  UserFromJSON,
  UserToJSON,
  UserCreatePayload,
  UserCreatePayloadFromJSON,
  UserCreatePayloadToJSON,
  UserEditPayload,
  UserEditPayloadFromJSON,
  UserEditPayloadToJSON,
  UserLoginPayload,
  UserLoginPayloadFromJSON,
  UserLoginPayloadToJSON,
  VerifyEmailPayload,
  VerifyEmailPayloadFromJSON,
  VerifyEmailPayloadToJSON
} from "../models";

export interface ConfirmUserRequest {
  confirmation: string;
}

export interface CreateUserRequest {
  userCreatePayload?: UserCreatePayload;
}

export interface DeleteUserRequest {
  userID: number;
  company: number;
}

export interface DowngradeAdminRequest {
  id: number;
  company: number;
}

export interface EditUserRequest {
  userID: number;
  userEditPayload?: UserEditPayload;
}

export interface ForgotPasswordRequest {
  forgotPasswordPayload?: ForgotPasswordPayload;
}

export interface GetUserRequest {
  userID: number;
  company: number;
}

export interface GetUserLoginMethodRequest {
  email: string;
}

export interface GetUserProfileRequest {
  username: string;
}

export interface GetUsersRequest {
  company: number;
  limit?: number;
  start?: number;
  sort?: string;
  filter?: string;
}

export interface GetUsersCountRequest {
  company: number;
}

export interface LoginRequest {
  userLoginPayload?: UserLoginPayload;
}

export interface ResendVerficationRequest {
  sendVerificationPayload?: SendVerificationPayload;
}

export interface ResetAllPasswordsRequest {
  resetAllPasswordPayload?: ResetAllPasswordPayload;
}

export interface ResetPasswordRequest {
  resetPasswordPayload?: ResetPasswordPayload;
}

export interface SendVerficationRequest {
  sendVerificationPayload?: SendVerificationPayload;
}

export interface SetSuperAdminRequest {
  changeSuperAdminPayload?: ChangeSuperAdminPayload;
}

export interface SetupProfileRequest {
  setupProfilePayload?: SetupProfilePayload;
}

export interface UpgradeUserRequest {
  id: number;
  company: number;
}

export interface VerfiyEmailRequest {
  verifyEmailPayload?: VerifyEmailPayload;
}

/**
 * no description
 */
export class UsersApi extends runtime.BaseAPI {
  /**
   * Confirm user's email with the code
   * Confirm user signup
   */
  async confirmUserRaw(
    requestParameters: ConfirmUserRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.confirmation === null ||
      requestParameters.confirmation === undefined
    ) {
      throw new runtime.RequiredError(
        "confirmation",
        "Required parameter requestParameters.confirmation was null or undefined when calling confirmUser."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.confirmation !== undefined) {
      queryParameters["confirmation"] = requestParameters.confirmation;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/auth/email-confirmation`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Confirm user's email with the code
   * Confirm user signup
   */
  async confirmUser(requestParameters: ConfirmUserRequest): Promise<void> {
    await this.confirmUserRaw(requestParameters);
  }

  /**
   * This will create a new user
   * Create a new user
   */
  async createUserRaw(
    requestParameters: CreateUserRequest
  ): Promise<runtime.ApiResponse<RegisteredUser>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/auth/local/register`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: UserCreatePayloadToJSON(requestParameters.userCreatePayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      RegisteredUserFromJSON(jsonValue)
    );
  }

  /**
   * This will create a new user
   * Create a new user
   */
  async createUser(
    requestParameters: CreateUserRequest
  ): Promise<RegisteredUser> {
    const response = await this.createUserRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes a user and his/her associations with all project of company
   * Delete user
   */
  async deleteUserRaw(
    requestParameters: DeleteUserRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (
      requestParameters.userID === null ||
      requestParameters.userID === undefined
    ) {
      throw new runtime.RequiredError(
        "userID",
        "Required parameter requestParameters.userID was null or undefined when calling deleteUser."
      );
    }

    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling deleteUser."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/users/{userID}`.replace(
        `{${"userID"}}`,
        encodeURIComponent(String(requestParameters.userID))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deletes a user and his/her associations with all project of company
   * Delete user
   */
  async deleteUser(requestParameters: DeleteUserRequest): Promise<any> {
    const response = await this.deleteUserRaw(requestParameters);
    return await response.value();
  }

  /**
   * Downgrades an administrator to user
   * Downgrade administrator to user
   */
  async downgradeAdminRaw(
    requestParameters: DowngradeAdminRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling downgradeAdmin."
      );
    }

    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling downgradeAdmin."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/users/downgrade/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Downgrades an administrator to user
   * Downgrade administrator to user
   */
  async downgradeAdmin(requestParameters: DowngradeAdminRequest): Promise<any> {
    const response = await this.downgradeAdminRaw(requestParameters);
    return await response.value();
  }

  /**
   * If user is admin, he can edit any user's profile, otherwise he can only edit his own profile
   * Edit user
   */
  async editUserRaw(
    requestParameters: EditUserRequest
  ): Promise<runtime.ApiResponse<User>> {
    if (
      requestParameters.userID === null ||
      requestParameters.userID === undefined
    ) {
      throw new runtime.RequiredError(
        "userID",
        "Required parameter requestParameters.userID was null or undefined when calling editUser."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/users/{userID}`.replace(
        `{${"userID"}}`,
        encodeURIComponent(String(requestParameters.userID))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: UserEditPayloadToJSON(requestParameters.userEditPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      UserFromJSON(jsonValue)
    );
  }

  /**
   * If user is admin, he can edit any user's profile, otherwise he can only edit his own profile
   * Edit user
   */
  async editUser(requestParameters: EditUserRequest): Promise<User> {
    const response = await this.editUserRaw(requestParameters);
    return await response.value();
  }

  /**
   * Sends the instructions to reset password via email to id provided with request
   * Sends the reset password email to user
   */
  async forgotPasswordRaw(
    requestParameters: ForgotPasswordRequest
  ): Promise<runtime.ApiResponse<any>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/auth/forgot-password`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ForgotPasswordPayloadToJSON(requestParameters.forgotPasswordPayload)
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Sends the instructions to reset password via email to id provided with request
   * Sends the reset password email to user
   */
  async forgotPassword(requestParameters: ForgotPasswordRequest): Promise<any> {
    const response = await this.forgotPasswordRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get issue managers user can link to
   * Get issue managers user can link to
   */
  async getMyIssueManagersRaw(): Promise<
    runtime.ApiResponse<Array<ProjectsettingMini>>
  > {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/users/getMyIssueManagers`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(ProjectsettingMiniFromJSON)
    );
  }

  /**
   * Get issue managers user can link to
   * Get issue managers user can link to
   */
  async getMyIssueManagers(): Promise<Array<ProjectsettingMini>> {
    const response = await this.getMyIssueManagersRaw();
    return await response.value();
  }

  /**
   * Get currently logged in user
   * Get user
   */
  async getMyUserRaw(): Promise<runtime.ApiResponse<User>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/users/me`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      UserFromJSON(jsonValue)
    );
  }

  /**
   * Get currently logged in user
   * Get user
   */
  async getMyUser(): Promise<User> {
    const response = await this.getMyUserRaw();
    return await response.value();
  }

  /**
   * Get details of a specific user
   * Get user
   */
  async getUserRaw(
    requestParameters: GetUserRequest
  ): Promise<runtime.ApiResponse<User>> {
    if (
      requestParameters.userID === null ||
      requestParameters.userID === undefined
    ) {
      throw new runtime.RequiredError(
        "userID",
        "Required parameter requestParameters.userID was null or undefined when calling getUser."
      );
    }

    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getUser."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/users/{userID}`.replace(
        `{${"userID"}}`,
        encodeURIComponent(String(requestParameters.userID))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      UserFromJSON(jsonValue)
    );
  }

  /**
   * Get details of a specific user
   * Get user
   */
  async getUser(requestParameters: GetUserRequest): Promise<User> {
    const response = await this.getUserRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get details of login method used by a user
   * Get user login method
   */
  async getUserLoginMethodRaw(
    requestParameters: GetUserLoginMethodRequest
  ): Promise<runtime.ApiResponse<LoginMethod>> {
    if (
      requestParameters.email === null ||
      requestParameters.email === undefined
    ) {
      throw new runtime.RequiredError(
        "email",
        "Required parameter requestParameters.email was null or undefined when calling getUserLoginMethod."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.email !== undefined) {
      queryParameters["email"] = requestParameters.email;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/users/getUserLoginMethod`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      LoginMethodFromJSON(jsonValue)
    );
  }

  /**
   * Get details of login method used by a user
   * Get user login method
   */
  async getUserLoginMethod(
    requestParameters: GetUserLoginMethodRequest
  ): Promise<LoginMethod> {
    const response = await this.getUserLoginMethodRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get profile of a specific user
   * Get user profile
   */
  async getUserProfileRaw(
    requestParameters: GetUserProfileRequest
  ): Promise<runtime.ApiResponse<User>> {
    if (
      requestParameters.username === null ||
      requestParameters.username === undefined
    ) {
      throw new runtime.RequiredError(
        "username",
        "Required parameter requestParameters.username was null or undefined when calling getUserProfile."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.username !== undefined) {
      queryParameters["username"] = requestParameters.username;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/users/profile`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      UserFromJSON(jsonValue)
    );
  }

  /**
   * Get profile of a specific user
   * Get user profile
   */
  async getUserProfile(
    requestParameters: GetUserProfileRequest
  ): Promise<User> {
    const response = await this.getUserProfileRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get list of all users in a company
   * Get users
   */
  async getUsersRaw(
    requestParameters: GetUsersRequest
  ): Promise<runtime.ApiResponse<Array<User>>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getUsers."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/users`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(UserFromJSON)
    );
  }

  /**
   * Get list of all users in a company
   * Get users
   */
  async getUsers(requestParameters: GetUsersRequest): Promise<Array<User>> {
    const response = await this.getUsersRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get users' count for a specific company
   * Get users' count
   */
  async getUsersCountRaw(
    requestParameters: GetUsersCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling getUsersCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/users/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get users' count for a specific company
   * Get users' count
   */
  async getUsersCount(requestParameters: GetUsersCountRequest): Promise<Count> {
    const response = await this.getUsersCountRaw(requestParameters);
    return await response.value();
  }

  /**
   * To get JWT for logged  in user
   * Login
   */
  async loginRaw(
    requestParameters: LoginRequest
  ): Promise<runtime.ApiResponse<LoggedInUser>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/auth/local`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: UserLoginPayloadToJSON(requestParameters.userLoginPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      LoggedInUserFromJSON(jsonValue)
    );
  }

  /**
   * To get JWT for logged  in user
   * Login
   */
  async login(requestParameters: LoginRequest): Promise<LoggedInUser> {
    const response = await this.loginRaw(requestParameters);
    return await response.value();
  }

  /**
   * Logout a user
   * Logout user
   */
  async logoutRaw(): Promise<runtime.ApiResponse<GenericActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/users/logout`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      GenericActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Logout a user
   * Logout user
   */
  async logout(): Promise<GenericActionResult> {
    const response = await this.logoutRaw();
    return await response.value();
  }

  /**
   * Resend verification email
   * Resend verification email
   */
  async resendVerficationRaw(
    requestParameters: ResendVerficationRequest
  ): Promise<runtime.ApiResponse<GenericActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/users/resendVerification`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: SendVerificationPayloadToJSON(
        requestParameters.sendVerificationPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      GenericActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Resend verification email
   * Resend verification email
   */
  async resendVerfication(
    requestParameters: ResendVerficationRequest
  ): Promise<GenericActionResult> {
    const response = await this.resendVerficationRaw(requestParameters);
    return await response.value();
  }

  /**
   * Reset all passwords due to password policy changes
   * Reset all passwords
   */
  async resetAllPasswordsRaw(
    requestParameters: ResetAllPasswordsRequest
  ): Promise<runtime.ApiResponse<GenericActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/users/resetAllPasswords`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ResetAllPasswordPayloadToJSON(
        requestParameters.resetAllPasswordPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      GenericActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Reset all passwords due to password policy changes
   * Reset all passwords
   */
  async resetAllPasswords(
    requestParameters: ResetAllPasswordsRequest
  ): Promise<GenericActionResult> {
    const response = await this.resetAllPasswordsRaw(requestParameters);
    return await response.value();
  }

  /**
   * This will reset user's password
   * Reset Password
   */
  async resetPasswordRaw(
    requestParameters: ResetPasswordRequest
  ): Promise<runtime.ApiResponse<RegisteredUser>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/auth/reset-password`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ResetPasswordPayloadToJSON(requestParameters.resetPasswordPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      RegisteredUserFromJSON(jsonValue)
    );
  }

  /**
   * This will reset user's password
   * Reset Password
   */
  async resetPassword(
    requestParameters: ResetPasswordRequest
  ): Promise<RegisteredUser> {
    const response = await this.resetPasswordRaw(requestParameters);
    return await response.value();
  }

  /**
   * Send verification email
   * Send verification email
   */
  async sendVerficationRaw(
    requestParameters: SendVerficationRequest
  ): Promise<runtime.ApiResponse<GenericActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/users/sendVerification`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: SendVerificationPayloadToJSON(
        requestParameters.sendVerificationPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      GenericActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Send verification email
   * Send verification email
   */
  async sendVerfication(
    requestParameters: SendVerficationRequest
  ): Promise<GenericActionResult> {
    const response = await this.sendVerficationRaw(requestParameters);
    return await response.value();
  }

  /**
   * Upgrades a user as super administrator
   * Upgrades a user as super administrator
   */
  async setSuperAdminRaw(
    requestParameters: SetSuperAdminRequest
  ): Promise<runtime.ApiResponse<GenericActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/users/changeSuperAdmin`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: ChangeSuperAdminPayloadToJSON(
        requestParameters.changeSuperAdminPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      GenericActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Upgrades a user as super administrator
   * Upgrades a user as super administrator
   */
  async setSuperAdmin(
    requestParameters: SetSuperAdminRequest
  ): Promise<GenericActionResult> {
    const response = await this.setSuperAdminRaw(requestParameters);
    return await response.value();
  }

  /**
   * If user is admin, he can edit any user, otherwise he can only edit his own profile
   * Setup profile when user is invited
   */
  async setupProfileRaw(
    requestParameters: SetupProfileRequest
  ): Promise<runtime.ApiResponse<User>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/setupProfile`,
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: SetupProfilePayloadToJSON(requestParameters.setupProfilePayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      UserFromJSON(jsonValue)
    );
  }

  /**
   * If user is admin, he can edit any user, otherwise he can only edit his own profile
   * Setup profile when user is invited
   */
  async setupProfile(requestParameters: SetupProfileRequest): Promise<User> {
    const response = await this.setupProfileRaw(requestParameters);
    return await response.value();
  }

  /**
   * Upgrades a user to an administrator
   * Upgrades a user to an administrator
   */
  async upgradeUserRaw(
    requestParameters: UpgradeUserRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling upgradeUser."
      );
    }

    if (
      requestParameters.company === null ||
      requestParameters.company === undefined
    ) {
      throw new runtime.RequiredError(
        "company",
        "Required parameter requestParameters.company was null or undefined when calling upgradeUser."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.company !== undefined) {
      queryParameters["company"] = requestParameters.company;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/users/upgrade/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Upgrades a user to an administrator
   * Upgrades a user to an administrator
   */
  async upgradeUser(requestParameters: UpgradeUserRequest): Promise<any> {
    const response = await this.upgradeUserRaw(requestParameters);
    return await response.value();
  }

  /**
   * Send verification email
   * Verify email
   */
  async verfiyEmailRaw(
    requestParameters: VerfiyEmailRequest
  ): Promise<runtime.ApiResponse<LoggedInUser>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/users/verifyEmail`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: VerifyEmailPayloadToJSON(requestParameters.verifyEmailPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      LoggedInUserFromJSON(jsonValue)
    );
  }

  /**
   * Send verification email
   * Verify email
   */
  async verfiyEmail(
    requestParameters: VerfiyEmailRequest
  ): Promise<LoggedInUser> {
    const response = await this.verfiyEmailRaw(requestParameters);
    return await response.value();
  }
}
