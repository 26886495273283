// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  BulkActionResult,
  BulkActionResultFromJSON,
  BulkActionResultToJSON,
  BulkCopyPayload,
  BulkCopyPayloadFromJSON,
  BulkCopyPayloadToJSON,
  BulkDeletePayload,
  BulkDeletePayloadFromJSON,
  BulkDeletePayloadToJSON,
  CopyToOtherProjectPayload,
  CopyToOtherProjectPayloadFromJSON,
  CopyToOtherProjectPayloadToJSON,
  Count,
  CountFromJSON,
  CountToJSON,
  CreateActionResult,
  CreateActionResultFromJSON,
  CreateActionResultToJSON,
  DefaultResponseError,
  DefaultResponseErrorFromJSON,
  DefaultResponseErrorToJSON,
  FetchIdPayload,
  FetchIdPayloadFromJSON,
  FetchIdPayloadToJSON,
  FetchIdResult,
  FetchIdResultFromJSON,
  FetchIdResultToJSON,
  ForbiddenError,
  ForbiddenErrorFromJSON,
  ForbiddenErrorToJSON,
  GenericActionResult,
  GenericActionResultFromJSON,
  GenericActionResultToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON,
  Tag,
  TagFromJSON,
  TagToJSON,
  TestCase,
  TestCaseFromJSON,
  TestCaseToJSON,
  TestCaseAutomationCodePayload,
  TestCaseAutomationCodePayloadFromJSON,
  TestCaseAutomationCodePayloadToJSON,
  TestCaseAutomationStepFailurePayload,
  TestCaseAutomationStepFailurePayloadFromJSON,
  TestCaseAutomationStepFailurePayloadToJSON,
  TestCaseBulkActionPayload,
  TestCaseBulkActionPayloadFromJSON,
  TestCaseBulkActionPayloadToJSON,
  TestCaseCopyPayload,
  TestCaseCopyPayloadFromJSON,
  TestCaseCopyPayloadToJSON,
  TestCasePayload,
  TestCasePayloadFromJSON,
  TestCasePayloadToJSON,
  TestCaseQacTimeoutPayload,
  TestCaseQacTimeoutPayloadFromJSON,
  TestCaseQacTimeoutPayloadToJSON,
  TestCaseQuickUpdatePayload,
  TestCaseQuickUpdatePayloadFromJSON,
  TestCaseQuickUpdatePayloadToJSON,
  TestCaseRevertPayload,
  TestCaseRevertPayloadFromJSON,
  TestCaseRevertPayloadToJSON,
  TestCaseReviewPayload,
  TestCaseReviewPayloadFromJSON,
  TestCaseReviewPayloadToJSON,
  TestCasesSortOrderPayload,
  TestCasesSortOrderPayloadFromJSON,
  TestCasesSortOrderPayloadToJSON,
  TestCasesTagPayload,
  TestCasesTagPayloadFromJSON,
  TestCasesTagPayloadToJSON,
  UpdateAttachmentPayload,
  UpdateAttachmentPayloadFromJSON,
  UpdateAttachmentPayloadToJSON
} from "../models";

export interface AddTagsRequest {
  testCasesTagPayload?: TestCasesTagPayload;
}

export interface BulkCopyTestCaseRequest {
  bulkCopyPayload?: BulkCopyPayload;
}

export interface BulkDeleteTestCaseRequest {
  bulkDeletePayload?: BulkDeletePayload;
}

export interface CopyTestCaseRequest {
  testCaseCopyPayload?: TestCaseCopyPayload;
}

export interface CopyToOtherProjectRequest {
  copyToOtherProjectPayload?: CopyToOtherProjectPayload;
}

export interface CreateTestCaseRequest {
  testCasePayload?: TestCasePayload;
}

export interface DeleteTestCaseRequest {
  id: number;
  project: number;
}

export interface GetTestCaseRequest {
  id: number;
  project: number;
}

export interface GetTestCaseCountRequest {
  project: number;
  filter?: string;
}

export interface GetTestCaseIdsRequest {
  fetchIdPayload?: FetchIdPayload;
}

export interface GetTestCasesRequest {
  project: number;
  limit?: number;
  start?: number;
  sort?: string;
  filter?: string;
}

export interface GetTestCasesTagsRequest {
  project: number;
  limit?: number;
  start?: number;
  sort?: string;
  filter?: string;
}

export interface QuickUpdateTestCasesRequest {
  project: number;
  testCaseQuickUpdatePayload?: TestCaseQuickUpdatePayload;
}

export interface ReportAutomationStepFailureRequest {
  id: number;
  testCaseAutomationStepFailurePayload?: TestCaseAutomationStepFailurePayload;
}

export interface RevertTestCaseRequest {
  id: number;
  testCaseRevertPayload?: TestCaseRevertPayload;
}

export interface ReviewTestCaseRequest {
  id: number;
  testCaseReviewPayload?: TestCaseReviewPayload;
}

export interface SetTestCasesSortOrderRequest {
  testCasesSortOrderPayload?: TestCasesSortOrderPayload;
}

export interface TestCaseBulkActionsRequest {
  testCaseBulkActionPayload?: TestCaseBulkActionPayload;
}

export interface UpdateAttachmentsRequest {
  id: number;
  updateAttachmentPayload?: UpdateAttachmentPayload;
}

export interface UpdateAutomationCodeRequest {
  id: number;
  testCaseAutomationCodePayload?: TestCaseAutomationCodePayload;
}

export interface UpdateQacTimeoutRequest {
  id: number;
  testCaseQacTimeoutPayload?: TestCaseQacTimeoutPayload;
}

export interface UpdateTestCaseRequest {
  id: number;
  testCasePayload?: TestCasePayload;
}

/**
 * no description
 */
export class TestCasesApi extends runtime.BaseAPI {
  /**
   * Add tags for test cases
   * Add tags
   */
  async addTagsRaw(
    requestParameters: AddTagsRequest
  ): Promise<runtime.ApiResponse<Tag>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/tags`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TestCasesTagPayloadToJSON(requestParameters.testCasesTagPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TagFromJSON(jsonValue)
    );
  }

  /**
   * Add tags for test cases
   * Add tags
   */
  async addTags(requestParameters: AddTagsRequest): Promise<Tag> {
    const response = await this.addTagsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Bulk copy of Test Cases
   * Copy test cases
   */
  async bulkCopyTestCaseRaw(
    requestParameters: BulkCopyTestCaseRequest
  ): Promise<runtime.ApiResponse<BulkActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcases/bulkCopy`,
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: BulkCopyPayloadToJSON(requestParameters.bulkCopyPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      BulkActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Bulk copy of Test Cases
   * Copy test cases
   */
  async bulkCopyTestCase(
    requestParameters: BulkCopyTestCaseRequest
  ): Promise<BulkActionResult> {
    const response = await this.bulkCopyTestCaseRaw(requestParameters);
    return await response.value();
  }

  /**
   * Bulk delete of Test Cases
   * Deletes test cases
   */
  async bulkDeleteTestCaseRaw(
    requestParameters: BulkDeleteTestCaseRequest
  ): Promise<runtime.ApiResponse<BulkActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcases/bulkDelete`,
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: BulkDeletePayloadToJSON(requestParameters.bulkDeletePayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      BulkActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Bulk delete of Test Cases
   * Deletes test cases
   */
  async bulkDeleteTestCase(
    requestParameters: BulkDeleteTestCaseRequest
  ): Promise<BulkActionResult> {
    const response = await this.bulkDeleteTestCaseRaw(requestParameters);
    return await response.value();
  }

  /**
   * Duplicates a specific test case
   * Duplicate test case
   */
  async copyTestCaseRaw(
    requestParameters: CopyTestCaseRequest
  ): Promise<runtime.ApiResponse<CreateActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcases/copy`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TestCaseCopyPayloadToJSON(requestParameters.testCaseCopyPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CreateActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Duplicates a specific test case
   * Duplicate test case
   */
  async copyTestCase(
    requestParameters: CopyTestCaseRequest
  ): Promise<CreateActionResult> {
    const response = await this.copyTestCaseRaw(requestParameters);
    return await response.value();
  }

  /**
   * Bulk copy of Test Cases
   * Copy test cases to other project
   */
  async copyToOtherProjectRaw(
    requestParameters: CopyToOtherProjectRequest
  ): Promise<runtime.ApiResponse<BulkActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcases/copyToOtherProject`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CopyToOtherProjectPayloadToJSON(
        requestParameters.copyToOtherProjectPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      BulkActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Bulk copy of Test Cases
   * Copy test cases to other project
   */
  async copyToOtherProject(
    requestParameters: CopyToOtherProjectRequest
  ): Promise<BulkActionResult> {
    const response = await this.copyToOtherProjectRaw(requestParameters);
    return await response.value();
  }

  /**
   * Test Case to add
   * Create a new test case
   */
  async createTestCaseRaw(
    requestParameters: CreateTestCaseRequest
  ): Promise<runtime.ApiResponse<TestCase>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcases`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TestCasePayloadToJSON(requestParameters.testCasePayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestCaseFromJSON(jsonValue)
    );
  }

  /**
   * Test Case to add
   * Create a new test case
   */
  async createTestCase(
    requestParameters: CreateTestCaseRequest
  ): Promise<TestCase> {
    const response = await this.createTestCaseRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes a specific test case
   * Delete test case
   */
  async deleteTestCaseRaw(
    requestParameters: DeleteTestCaseRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteTestCase."
      );
    }

    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling deleteTestCase."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcases/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deletes a specific test case
   * Delete test case
   */
  async deleteTestCase(requestParameters: DeleteTestCaseRequest): Promise<any> {
    const response = await this.deleteTestCaseRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get details of a specific test case
   * Get test case
   */
  async getTestCaseRaw(
    requestParameters: GetTestCaseRequest
  ): Promise<runtime.ApiResponse<TestCase>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getTestCase."
      );
    }

    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestCase."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcases/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestCaseFromJSON(jsonValue)
    );
  }

  /**
   * Get details of a specific test case
   * Get test case
   */
  async getTestCase(requestParameters: GetTestCaseRequest): Promise<TestCase> {
    const response = await this.getTestCaseRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get count of test cases for specific project
   * Get test cases' count
   */
  async getTestCaseCountRaw(
    requestParameters: GetTestCaseCountRequest
  ): Promise<runtime.ApiResponse<Count>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestCaseCount."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcases/count`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CountFromJSON(jsonValue)
    );
  }

  /**
   * Get count of test cases for specific project
   * Get test cases' count
   */
  async getTestCaseCount(
    requestParameters: GetTestCaseCountRequest
  ): Promise<Count> {
    const response = await this.getTestCaseCountRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get id(s) of test case(s) that match the filter criteria
   * Get matching test cases' id
   */
  async getTestCaseIdsRaw(
    requestParameters: GetTestCaseIdsRequest
  ): Promise<runtime.ApiResponse<FetchIdResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcases/fetchIds`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: FetchIdPayloadToJSON(requestParameters.fetchIdPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      FetchIdResultFromJSON(jsonValue)
    );
  }

  /**
   * Get id(s) of test case(s) that match the filter criteria
   * Get matching test cases' id
   */
  async getTestCaseIds(
    requestParameters: GetTestCaseIdsRequest
  ): Promise<FetchIdResult> {
    const response = await this.getTestCaseIdsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get test cases specific to a project
   * Get test cases
   */
  async getTestCasesRaw(
    requestParameters: GetTestCasesRequest
  ): Promise<runtime.ApiResponse<Array<TestCase>>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestCases."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcases`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(TestCaseFromJSON)
    );
  }

  /**
   * Get test cases specific to a project
   * Get test cases
   */
  async getTestCases(
    requestParameters: GetTestCasesRequest
  ): Promise<Array<TestCase>> {
    const response = await this.getTestCasesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get list of tags added for a specific project
   * Get tags
   */
  async getTestCasesTagsRaw(
    requestParameters: GetTestCasesTagsRequest
  ): Promise<runtime.ApiResponse<Array<Tag>>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestCasesTags."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/tags`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(TagFromJSON)
    );
  }

  /**
   * Get list of tags added for a specific project
   * Get tags
   */
  async getTestCasesTags(
    requestParameters: GetTestCasesTagsRequest
  ): Promise<Array<Tag>> {
    const response = await this.getTestCasesTagsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Test Case to update
   * Updates test cases
   */
  async quickUpdateTestCasesRaw(
    requestParameters: QuickUpdateTestCasesRequest
  ): Promise<runtime.ApiResponse<BulkActionResult>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling quickUpdateTestCases."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcases/quickUpdate`,
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: TestCaseQuickUpdatePayloadToJSON(
        requestParameters.testCaseQuickUpdatePayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      BulkActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Test Case to update
   * Updates test cases
   */
  async quickUpdateTestCases(
    requestParameters: QuickUpdateTestCasesRequest
  ): Promise<BulkActionResult> {
    const response = await this.quickUpdateTestCasesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Report test case step automation failure
   */
  async reportAutomationStepFailureRaw(
    requestParameters: ReportAutomationStepFailureRequest
  ): Promise<runtime.ApiResponse<GenericActionResult>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling reportAutomationStepFailure."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcases/{id}/reportAutomationStepFailure`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TestCaseAutomationStepFailurePayloadToJSON(
        requestParameters.testCaseAutomationStepFailurePayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      GenericActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Report test case step automation failure
   */
  async reportAutomationStepFailure(
    requestParameters: ReportAutomationStepFailureRequest
  ): Promise<GenericActionResult> {
    const response = await this.reportAutomationStepFailureRaw(
      requestParameters
    );
    return await response.value();
  }

  /**
   * Test Case to update
   * Revert test case to particular revision
   */
  async revertTestCaseRaw(
    requestParameters: RevertTestCaseRequest
  ): Promise<runtime.ApiResponse<TestCase>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling revertTestCase."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcases/{id}/revert`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: TestCaseRevertPayloadToJSON(requestParameters.testCaseRevertPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestCaseFromJSON(jsonValue)
    );
  }

  /**
   * Test Case to update
   * Revert test case to particular revision
   */
  async revertTestCase(
    requestParameters: RevertTestCaseRequest
  ): Promise<TestCase> {
    const response = await this.revertTestCaseRaw(requestParameters);
    return await response.value();
  }

  /**
   * Review action on test case
   */
  async reviewTestCaseRaw(
    requestParameters: ReviewTestCaseRequest
  ): Promise<runtime.ApiResponse<GenericActionResult>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling reviewTestCase."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcases/{id}/review`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: TestCaseReviewPayloadToJSON(requestParameters.testCaseReviewPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      GenericActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Review action on test case
   */
  async reviewTestCase(
    requestParameters: ReviewTestCaseRequest
  ): Promise<GenericActionResult> {
    const response = await this.reviewTestCaseRaw(requestParameters);
    return await response.value();
  }

  /**
   * Set sort order for test cases
   * Set sort order for test cases
   */
  async setTestCasesSortOrderRaw(
    requestParameters: SetTestCasesSortOrderRequest
  ): Promise<runtime.ApiResponse<CreateActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcases/setSortOrder`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TestCasesSortOrderPayloadToJSON(
        requestParameters.testCasesSortOrderPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CreateActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Set sort order for test cases
   * Set sort order for test cases
   */
  async setTestCasesSortOrder(
    requestParameters: SetTestCasesSortOrderRequest
  ): Promise<CreateActionResult> {
    const response = await this.setTestCasesSortOrderRaw(requestParameters);
    return await response.value();
  }

  /**
   * Bulk actions on test cases
   */
  async testCaseBulkActionsRaw(
    requestParameters: TestCaseBulkActionsRequest
  ): Promise<runtime.ApiResponse<BulkActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcases/bulkAction`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TestCaseBulkActionPayloadToJSON(
        requestParameters.testCaseBulkActionPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      BulkActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Bulk actions on test cases
   */
  async testCaseBulkActions(
    requestParameters: TestCaseBulkActionsRequest
  ): Promise<BulkActionResult> {
    const response = await this.testCaseBulkActionsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Test Case to update
   * Update test case attachments
   */
  async updateAttachmentsRaw(
    requestParameters: UpdateAttachmentsRequest
  ): Promise<runtime.ApiResponse<TestCase>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateAttachments."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcases/{id}/updateAttachments`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: UpdateAttachmentPayloadToJSON(
        requestParameters.updateAttachmentPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestCaseFromJSON(jsonValue)
    );
  }

  /**
   * Test Case to update
   * Update test case attachments
   */
  async updateAttachments(
    requestParameters: UpdateAttachmentsRequest
  ): Promise<TestCase> {
    const response = await this.updateAttachmentsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Test case steps automation
   */
  async updateAutomationCodeRaw(
    requestParameters: UpdateAutomationCodeRequest
  ): Promise<runtime.ApiResponse<GenericActionResult>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateAutomationCode."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcases/{id}/updateAutomationCode`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TestCaseAutomationCodePayloadToJSON(
        requestParameters.testCaseAutomationCodePayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      GenericActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Test case steps automation
   */
  async updateAutomationCode(
    requestParameters: UpdateAutomationCodeRequest
  ): Promise<GenericActionResult> {
    const response = await this.updateAutomationCodeRaw(requestParameters);
    return await response.value();
  }

  /**
   * Timeout for test case run by QA Copilot
   */
  async updateQacTimeoutRaw(
    requestParameters: UpdateQacTimeoutRequest
  ): Promise<runtime.ApiResponse<GenericActionResult>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateQacTimeout."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcases/{id}/updateQacTimeout`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TestCaseQacTimeoutPayloadToJSON(
        requestParameters.testCaseQacTimeoutPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      GenericActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Timeout for test case run by QA Copilot
   */
  async updateQacTimeout(
    requestParameters: UpdateQacTimeoutRequest
  ): Promise<GenericActionResult> {
    const response = await this.updateQacTimeoutRaw(requestParameters);
    return await response.value();
  }

  /**
   * Test Case to update
   * Updates a test case
   */
  async updateTestCaseRaw(
    requestParameters: UpdateTestCaseRequest
  ): Promise<runtime.ApiResponse<TestCase>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateTestCase."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testcases/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: TestCasePayloadToJSON(requestParameters.testCasePayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestCaseFromJSON(jsonValue)
    );
  }

  /**
   * Test Case to update
   * Updates a test case
   */
  async updateTestCase(
    requestParameters: UpdateTestCaseRequest
  ): Promise<TestCase> {
    const response = await this.updateTestCaseRaw(requestParameters);
    return await response.value();
  }
}
