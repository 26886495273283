// @ts-check
import React, { Component, lazy, Suspense } from "react";
import { BrowserRouter, Route } from "react-router-dom";
//import { loadStripe } from "@stripe/stripe-js";
//import { Elements } from "@stripe/react-stripe-js";
//const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY);
const ModalSwitch = lazy(() => import("./ModalSwitch"));

// import ModalSwitch from "./ModalSwitch";
import { LastLocationProvider } from "react-router-last-location";
import CenterElement from "./components/common/layout/CenterElement";
import { Spin } from "antd";

const Router = () => {
  // return null;

  return (
    <div className="App">
      <main>
        {/* <Elements stripe={stripePromise}> */}
        <BrowserRouter>
          <LastLocationProvider>
            <Suspense
              fallback={
                <CenterElement>
                  <Spin />
                </CenterElement>
              }
            >
              <Route component={ModalSwitch} />
            </Suspense>
          </LastLocationProvider>
        </BrowserRouter>
        {/* </Elements> */}
      </main>
    </div>
  );
};

export default Router;
