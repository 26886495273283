import { notification } from "antd";

export const handleError = async (status, messages = {}, message = null) => {
  const defaultMsg = {
    401: ["Unauthorized", "You are not authorized!"],
    400: ["Bad request", "Request data is not correct!"],
    403: [
      "Forbidden",
      "Check with your administrator if you have the right to perform this operation?"
    ],
    422: ["Bad data", "Data provided is invalid."],
    404: ["Not found", "Record not found!"],
    406: ["Not acceptable", "Data not acceptable!"],
    500: [
      "Server error",
      "An error occurred on server, please try this again. If problem persists, please contact us."
    ]
  };
  const _messages = { ...defaultMsg, messages };
  if (_messages[status]) {
    notification["error"]({
      message: _messages[status][0],
      description: message || _messages[status][1],
      duration: 7
    });
  } else {
    console.error("Unknown error occured!");
  }
};
