import { Tooltip, Icon, Avatar } from "antd";
import React, { Component, useContext, useState, useEffect } from "react";
import UserAvatar from "react-user-avatar";
//import './tc_user_avatar.css'
import { AppContext } from "../../AppContext";
import { hot } from "react-hot-loader";
import { User } from "../../services/generated-sdk/models/User";
import { getAvatarURL } from "../../utils/common-ts";
import * as _ from "lodash";
import { formatNmae } from "../../utils/common";

function TCUserAvatar(props: TCAvatarProps) {
  const { userIndex, deletedUserIndex } = useContext(AppContext);
  const allUsers = Object.assign({}, userIndex, deletedUserIndex);
  const id = typeof props.id != "string" ? props.id : parseInt(props.id);

  useEffect(() => {}, [props.name]);
  if (!props.id) {
    return (
      <a style={{ color: "#fff" }} title="Unassigned">
        <Avatar size={28} icon="user" />
        &nbsp;
        {props.withName ? (
          <span style={{ color: "#000" }}>Unassigned</span>
        ) : null}
      </a>
    );
  }

  if (id && !allUsers[id] && !deletedUserIndex[id]) {
    return props.name ? (
      <span>
        <UserAvatar {...props} name={formatNmae(props.name)} size={28} />{" "}
        {props.withName ? props.name : null}
      </span>
    ) : (
      <Avatar icon="user" {...props} />
    );
  }
  let selectedUser: User = allUsers[id];

  props = {
    size: 28,
    ...props
  };

  let newProps: any = { ...props };
  if (selectedUser) {
    const name = formatNmae(
      props.name ? props.name : selectedUser ? selectedUser.name : "Unassigned"
    );
    newProps.name = name;

    // if avatar found
    if (selectedUser.avatar) {
      newProps.src = getAvatarURL(selectedUser.avatar);
    }
  }
  const isDeletedUser =
    deletedUserIndex && deletedUserIndex[id] != undefined ? true : false;

  return (
    <a
      style={{ color: "#fff" }}
      target="_blank"
      href={isDeletedUser ? "/" : `/profile/${selectedUser.username}`}
      title={selectedUser.name}
    >
      <span className="avatar-span">
        <UserAvatar {...newProps} />
        &nbsp;
        {props.withName ? (
          <span style={{ color: "rgba(0, 0, 0, 0.75)" }}>{newProps.name}</span>
        ) : null}
      </span>
    </a>
  );
}
export default hot(module)(TCUserAvatar);

export interface TCAvatarProps {
  id: number | string;
  name?: string;
  src?: string;
  size?: number;
  className?: string;
  textColor?: string;
  withName?: boolean;
}
