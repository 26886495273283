// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  AddSuitePayload,
  AddSuitePayloadFromJSON,
  AddSuitePayloadToJSON,
  BulkActionResult,
  BulkActionResultFromJSON,
  BulkActionResultToJSON,
  CreateActionResult,
  CreateActionResultFromJSON,
  CreateActionResultToJSON,
  DefaultResponseError,
  DefaultResponseErrorFromJSON,
  DefaultResponseErrorToJSON,
  ForbiddenError,
  ForbiddenErrorFromJSON,
  ForbiddenErrorToJSON,
  ProjectSuiteTree,
  ProjectSuiteTreeFromJSON,
  ProjectSuiteTreeToJSON,
  Suite,
  SuiteFromJSON,
  SuiteToJSON,
  SuiteCopyPayload,
  SuiteCopyPayloadFromJSON,
  SuiteCopyPayloadToJSON,
  SuiteLinkPayload,
  SuiteLinkPayloadFromJSON,
  SuiteLinkPayloadToJSON,
  SuiteUnlinkPayload,
  SuiteUnlinkPayloadFromJSON,
  SuiteUnlinkPayloadToJSON,
  SuitesOrderPayload,
  SuitesOrderPayloadFromJSON,
  SuitesOrderPayloadToJSON,
  TCError,
  TCErrorFromJSON,
  TCErrorToJSON
} from "../models";

export interface AddSuiteRequest {
  addSuitePayload?: AddSuitePayload;
}

export interface CopySuiteRequest {
  project: number;
  suiteCopyPayload?: SuiteCopyPayload;
}

export interface DeleteSuiteRequest {
  project: number;
  id: number;
}

export interface GetAllSuitesRequest {
  project: number;
  filter?: string;
}

export interface GetProjectSuitesTreeRequest {
  project: number;
}

export interface GetSuiteRequest {
  project: number;
  id: number;
}

export interface LinkSuiteRequest {
  suiteLinkPayload?: SuiteLinkPayload;
}

export interface SetSuitesOrderRequest {
  suitesOrderPayload?: SuitesOrderPayload;
}

export interface UnlinkSuiteRequest {
  suiteUnlinkPayload?: SuiteUnlinkPayload;
}

export interface UpdateSuiteRequest {
  project: number;
  id: number;
  addSuitePayload?: AddSuitePayload;
}

/**
 * no description
 */
export class SuitesApi extends runtime.BaseAPI {
  /**
   * Add a test suite
   * Add test suite
   */
  async addSuiteRaw(
    requestParameters: AddSuiteRequest
  ): Promise<runtime.ApiResponse<Suite>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/suites`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: AddSuitePayloadToJSON(requestParameters.addSuitePayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      SuiteFromJSON(jsonValue)
    );
  }

  /**
   * Add a test suite
   * Add test suite
   */
  async addSuite(requestParameters: AddSuiteRequest): Promise<Suite> {
    const response = await this.addSuiteRaw(requestParameters);
    return await response.value();
  }

  /**
   * Duplicates a specific suite
   * Duplicate suite
   */
  async copySuiteRaw(
    requestParameters: CopySuiteRequest
  ): Promise<runtime.ApiResponse<BulkActionResult>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling copySuite."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/suites/copy`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: SuiteCopyPayloadToJSON(requestParameters.suiteCopyPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      BulkActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Duplicates a specific suite
   * Duplicate suite
   */
  async copySuite(
    requestParameters: CopySuiteRequest
  ): Promise<BulkActionResult> {
    const response = await this.copySuiteRaw(requestParameters);
    return await response.value();
  }

  /**
   * Deletes a specific suite
   * Delete suite
   */
  async deleteSuiteRaw(
    requestParameters: DeleteSuiteRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling deleteSuite."
      );
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling deleteSuite."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/suites/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Deletes a specific suite
   * Delete suite
   */
  async deleteSuite(requestParameters: DeleteSuiteRequest): Promise<any> {
    const response = await this.deleteSuiteRaw(requestParameters);
    return await response.value();
  }

  /**
   * fetch suite data
   * Get all suites
   */
  async getAllSuitesRaw(
    requestParameters: GetAllSuitesRequest
  ): Promise<runtime.ApiResponse<Array<Suite>>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getAllSuites."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/suites`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(SuiteFromJSON)
    );
  }

  /**
   * fetch suite data
   * Get all suites
   */
  async getAllSuites(
    requestParameters: GetAllSuitesRequest
  ): Promise<Array<Suite>> {
    const response = await this.getAllSuitesRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get suite tree for a given project
   */
  async getProjectSuitesTreeRaw(
    requestParameters: GetProjectSuitesTreeRequest
  ): Promise<runtime.ApiResponse<Array<ProjectSuiteTree>>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getProjectSuitesTree."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/projectsuitetrees`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(ProjectSuiteTreeFromJSON)
    );
  }

  /**
   * Get suite tree for a given project
   */
  async getProjectSuitesTree(
    requestParameters: GetProjectSuitesTreeRequest
  ): Promise<Array<ProjectSuiteTree>> {
    const response = await this.getProjectSuitesTreeRaw(requestParameters);
    return await response.value();
  }

  /**
   * fetch suite data
   * Get specific suite
   */
  async getSuiteRaw(
    requestParameters: GetSuiteRequest
  ): Promise<runtime.ApiResponse<Suite>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getSuite."
      );
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getSuite."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/suites/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      SuiteFromJSON(jsonValue)
    );
  }

  /**
   * fetch suite data
   * Get specific suite
   */
  async getSuite(requestParameters: GetSuiteRequest): Promise<Suite> {
    const response = await this.getSuiteRaw(requestParameters);
    return await response.value();
  }

  /**
   * Creates a suite's link
   * Link suite
   */
  async linkSuiteRaw(
    requestParameters: LinkSuiteRequest
  ): Promise<runtime.ApiResponse<BulkActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/suites/link`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: SuiteLinkPayloadToJSON(requestParameters.suiteLinkPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      BulkActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Creates a suite's link
   * Link suite
   */
  async linkSuite(
    requestParameters: LinkSuiteRequest
  ): Promise<BulkActionResult> {
    const response = await this.linkSuiteRaw(requestParameters);
    return await response.value();
  }

  /**
   * Set sort order for suites
   * Set sort order for suites
   */
  async setSuitesOrderRaw(
    requestParameters: SetSuitesOrderRequest
  ): Promise<runtime.ApiResponse<CreateActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/suites/setOrder`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: SuitesOrderPayloadToJSON(requestParameters.suitesOrderPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      CreateActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Set sort order for suites
   * Set sort order for suites
   */
  async setSuitesOrder(
    requestParameters: SetSuitesOrderRequest
  ): Promise<CreateActionResult> {
    const response = await this.setSuitesOrderRaw(requestParameters);
    return await response.value();
  }

  /**
   * Unlinks a specific suite
   * Unlink suite
   */
  async unlinkSuiteRaw(
    requestParameters: UnlinkSuiteRequest
  ): Promise<runtime.ApiResponse<BulkActionResult>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/suites/unlink`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: SuiteUnlinkPayloadToJSON(requestParameters.suiteUnlinkPayload)
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      BulkActionResultFromJSON(jsonValue)
    );
  }

  /**
   * Unlinks a specific suite
   * Unlink suite
   */
  async unlinkSuite(
    requestParameters: UnlinkSuiteRequest
  ): Promise<BulkActionResult> {
    const response = await this.unlinkSuiteRaw(requestParameters);
    return await response.value();
  }

  /**
   * Suite to update
   * Updates a suite
   */
  async updateSuiteRaw(
    requestParameters: UpdateSuiteRequest
  ): Promise<runtime.ApiResponse<any>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling updateSuite."
      );
    }

    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling updateSuite."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/suites/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: AddSuitePayloadToJSON(requestParameters.addSuitePayload)
    });

    return new runtime.TextApiResponse(response);
  }

  /**
   * Suite to update
   * Updates a suite
   */
  async updateSuite(requestParameters: UpdateSuiteRequest): Promise<any> {
    const response = await this.updateSuiteRaw(requestParameters);
    return await response.value();
  }
}
