// tslint:disable
/**
 * TC API
 * TestCollab is a test management tool that helps development teams carry out testing effectively and avoid failures. # Introduction This is an API documentation for TestCollab 2.   # Authentication TestCollab supports two forms of authentication:   - An <b>API token</b> is required to make API calls. The token can be generated from user's profile; to obtain it login to your TestCollab account and go to ['My Profile Settings'](https://testcollab.io/my_profile) and switch to API Token tab. Click on \"Generate new API token\", Once the token is generated it can be sent as a query parameter for the API calls made - https://api.testcollab.io/projects?token=<generated-API-token>    - <b>Bearer authentication</b> (also called token authentication) is an HTTP authentication scheme that involves security tokens called bearer tokens. You can generate bearer auth token using [Login](#operation/login) API.     Send this token as the Authorization header while making requests to protected resources:     `Authorization: Bearer <token>`    For making API calls, we recommend using the <b>API token</b> generated from user profile page as it won't affect the on-going user session and will be available until it is revoked by the user who generated it.      # Error Handling Error responses   - `Not Found` - Error is sent when requested resource is not available.   - `Unauthorized - invalid token` - Error is sent when API token sent with the request is invalid or revoked. Error contains status code 401        - `Forbidden` - Error is sent when logged in user is not part of the company, project or his role does not allow to complete given action. Response consist of 403 status code and more information is given in error message.
 *
 * OpenAPI spec version: 2.0.20
 * Contact: support@testcollab.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  DefaultResponseError,
  DefaultResponseErrorFromJSON,
  DefaultResponseErrorToJSON,
  ForbiddenError,
  ForbiddenErrorFromJSON,
  ForbiddenErrorToJSON,
  TestPlanConfiguration,
  TestPlanConfigurationFromJSON,
  TestPlanConfigurationToJSON,
  TestPlanConfigurationPayload,
  TestPlanConfigurationPayloadFromJSON,
  TestPlanConfigurationPayloadToJSON,
  TestPlanConfigurationsPayload,
  TestPlanConfigurationsPayloadFromJSON,
  TestPlanConfigurationsPayloadToJSON
} from "../models";

export interface AddTestPlanConfigRequest {
  testPlanConfigurationPayload?: TestPlanConfigurationPayload;
}

export interface EditTestPlanConfigsRequest {
  testPlanConfigurationsPayload?: TestPlanConfigurationsPayload;
}

export interface GetTestPlanConfigRequest {
  id: number;
}

export interface GetTestPlanConfigsRequest {
  project: number;
  testplan: number;
  limit?: number;
  start?: number;
  sort?: string;
  filter?: string;
}

/**
 * no description
 */
export class TestPlansConfigsApi extends runtime.BaseAPI {
  /**
   * Add new configuration(s) to a test plan
   * Add test plan config(s)
   */
  async addTestPlanConfigRaw(
    requestParameters: AddTestPlanConfigRequest
  ): Promise<runtime.ApiResponse<Array<TestPlanConfiguration>>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplanconfigurations`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: TestPlanConfigurationPayloadToJSON(
        requestParameters.testPlanConfigurationPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(TestPlanConfigurationFromJSON)
    );
  }

  /**
   * Add new configuration(s) to a test plan
   * Add test plan config(s)
   */
  async addTestPlanConfig(
    requestParameters: AddTestPlanConfigRequest
  ): Promise<Array<TestPlanConfiguration>> {
    const response = await this.addTestPlanConfigRaw(requestParameters);
    return await response.value();
  }

  /**
   * Edit test plan configurations(s)
   * Edit test plan config(s)
   */
  async editTestPlanConfigsRaw(
    requestParameters: EditTestPlanConfigsRequest
  ): Promise<runtime.ApiResponse<Array<TestPlanConfiguration>>> {
    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplanconfigurations`,
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: TestPlanConfigurationsPayloadToJSON(
        requestParameters.testPlanConfigurationsPayload
      )
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(TestPlanConfigurationFromJSON)
    );
  }

  /**
   * Edit test plan configurations(s)
   * Edit test plan config(s)
   */
  async editTestPlanConfigs(
    requestParameters: EditTestPlanConfigsRequest
  ): Promise<Array<TestPlanConfiguration>> {
    const response = await this.editTestPlanConfigsRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get list of configuration(s) added for a test plan
   * Get test plan configuration(s)
   */
  async getTestPlanConfigRaw(
    requestParameters: GetTestPlanConfigRequest
  ): Promise<runtime.ApiResponse<TestPlanConfiguration>> {
    if (requestParameters.id === null || requestParameters.id === undefined) {
      throw new runtime.RequiredError(
        "id",
        "Required parameter requestParameters.id was null or undefined when calling getTestPlanConfig."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplanconfigurations/{id}`.replace(
        `{${"id"}}`,
        encodeURIComponent(String(requestParameters.id))
      ),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      TestPlanConfigurationFromJSON(jsonValue)
    );
  }

  /**
   * Get list of configuration(s) added for a test plan
   * Get test plan configuration(s)
   */
  async getTestPlanConfig(
    requestParameters: GetTestPlanConfigRequest
  ): Promise<TestPlanConfiguration> {
    const response = await this.getTestPlanConfigRaw(requestParameters);
    return await response.value();
  }

  /**
   * Get configurations added for a specific test plan
   * Get test plan configs
   */
  async getTestPlanConfigsRaw(
    requestParameters: GetTestPlanConfigsRequest
  ): Promise<runtime.ApiResponse<Array<TestPlanConfiguration>>> {
    if (
      requestParameters.project === null ||
      requestParameters.project === undefined
    ) {
      throw new runtime.RequiredError(
        "project",
        "Required parameter requestParameters.project was null or undefined when calling getTestPlanConfigs."
      );
    }

    if (
      requestParameters.testplan === null ||
      requestParameters.testplan === undefined
    ) {
      throw new runtime.RequiredError(
        "testplan",
        "Required parameter requestParameters.testplan was null or undefined when calling getTestPlanConfigs."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.project !== undefined) {
      queryParameters["project"] = requestParameters.project;
    }

    if (requestParameters.testplan !== undefined) {
      queryParameters["testplan"] = requestParameters.testplan;
    }

    if (requestParameters.limit !== undefined) {
      queryParameters["_limit"] = requestParameters.limit;
    }

    if (requestParameters.start !== undefined) {
      queryParameters["_start"] = requestParameters.start;
    }

    if (requestParameters.sort !== undefined) {
      queryParameters["_sort"] = requestParameters.sort;
    }

    if (requestParameters.filter !== undefined) {
      queryParameters["_filter"] = requestParameters.filter;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    if (this.configuration && this.configuration.apiKey) {
      headerParameters["Authorization"] = this.configuration.apiKey(
        "Authorization"
      ); // bearerAuth authentication
    }

    const response = await this.request({
      path: `/testplanconfigurations`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue =>
      jsonValue.map(TestPlanConfigurationFromJSON)
    );
  }

  /**
   * Get configurations added for a specific test plan
   * Get test plan configs
   */
  async getTestPlanConfigs(
    requestParameters: GetTestPlanConfigsRequest
  ): Promise<Array<TestPlanConfiguration>> {
    const response = await this.getTestPlanConfigsRaw(requestParameters);
    return await response.value();
  }
}
